import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import AppFrame from './app/AppFrame';
import ShopifyFrame from './shopify/ShopifyFrame';
import PublicFrame from './public/PublicFrame';
import AdminRoute from './AdminRoute';

const RootRouter = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute path="/app">
        <AppFrame />
      </PrivateRoute>

      <Route path="/shopify">
        <ShopifyFrame />
      </Route>
      <Route path="/privacy">
        <PublicFrame />
      </Route>

      <AdminRoute path="/admin">
        <AppFrame />
      </AdminRoute>

      <Redirect to="/app" />
    </Switch>
  </BrowserRouter>
);

export default RootRouter;
