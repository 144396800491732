import React, { useState, useCallback, useContext } from 'react';
import { TopBar, ActionList } from '@shopify/polaris';
import { ArrowLeftMinor, LogOutMinor } from '@shopify/polaris-icons';

import { AuthContext } from '../../providers/AuthContext';
import APIContext from '../../providers/APIContext';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import StoreContext from '../../providers/StoreContext';

function AdminTopBar() {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    [],
  );

  const { logout } = useContext(AuthContext);

  const { adminFetchStores: fetchStores, adminSelectStore: selectStore } =
    useContext(APIContext);

  const { data: stores } = useQuery('fetchStores', fetchStores);

  const storeContext = useContext(StoreContext);
  const storeName = storeContext?.store?.name;
  const resetStore = storeContext?.resetStore;
  const history = useHistory();

  const mutation = useMutation(({ id }) => selectStore(id), {
    onSuccess: () => {
      history.push(`/app`);
      if (storeContext) resetStore();
    },
  });

  const backToAdminPage = useCallback(() => history.push(`/admin`), [history]);

  const userMenu = (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            {
              content: 'Back to Admin',
              icon: ArrowLeftMinor,
              onAction: backToAdminPage,
            },
          ],
        },
        {
          items: [
            {
              content: 'Logout',
              icon: LogOutMinor,
              onAction: logout,
            },
          ],
        },
      ]}
      name={storeName || 'No store selected'}
      detail={'Admin'}
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
    />
  );

  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleSearchResultsDismiss = useCallback(() => {
    setIsSearchActive(false);
    setSearchValue('');
  }, []);

  const handleSearchChange = useCallback((value) => {
    setSearchValue(value);
    setIsSearchActive(value.length > 0);
  }, []);

  const selectStoreFromSearch = (store) => {
    mutation.mutate(store);
    handleSearchResultsDismiss();
  };

  const storesActionList = stores
    ?.filter((store) => store.name.includes(searchValue))
    .map((store) => ({
      content: store.name,
      onAction: () => selectStoreFromSearch(store),
    }))
    .slice(0, 10);

  const searchResultsMarkup = (
    <ActionList
      items={
        storesActionList?.length > 0
          ? storesActionList
          : [{ content: 'No stores found', disabled: true }]
      }
    />
  );

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchChange}
      onFocus={() => setIsSearchActive(true)}
      value={searchValue}
      placeholder="Select Store"
      showFocusBorder
    />
  );

  return (
    <TopBar
      userMenu={userMenu}
      searchResultsVisible={isSearchActive}
      searchField={searchFieldMarkup}
      searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
    />
  );
}

export default AdminTopBar;
