export const style = {
  variables: {
    '--meja-text-color': '#111111',
    '--meja-color-error': '#d61411',
    '--meja-price-color': '#1c1d1d',
    '--meja-color-primary': '#111111',
    '--meja-button-padding': '11px',
    '--meja-text-font-size': '14px',
    '--meja-color-secondary': '#ffffff',
    '--meja-price-font-size': '14px',
    '--meja-button-font-size': '13px',
    '--meja-header-font-size': '28px',
    '--meja-text-font-family': 'sans-serif',
    '--meja-price-font-family': 'sans-serif',
    '--meja-button-font-family': 'sans-serif',
    '--meja-button-font-weight': '700',
    '--meja-header-font-family': 'serif',
    '--meja-input-text-padding': '11px',
    '--meja-link-subdued-color': '#656565',
    '--meja-button-border-width': '1px',
    '--meja-product-title-color': '#111111',
    '--meja-subheader-font-size': '16px',
    '--meja-color-primary-accent': '#60bfa4',
    '--meja-input-text-font-size': '16px',
    '--meja-button-text-transform': 'uppercase',
    '--meja-color-primary-subdued': '#656565',
    '--meja-subheader-font-family': 'sans-serif',
    '--meja-color-secondary-accent': '#e6e6e6',
    '--meja-input-text-font-family': 'sans-serif',
    '--meja-color-secondary-subdued': '#f6f6f6',
    '--meja-header-font-size-mobile': '32px',
    '--meja-input-text-border-width': '1px',
    '--meja-product-title-font-size': '16px',
    '--meja-link-subdued-hover-color': '#60bfa4',
    '--meja-product-title-font-family': 'serif',
    '--meja-subheader-font-size-mobile': '14px',
    '--meja-product-title-text-transform': 'none',
    '--meja-input-text-focus-border-width': '2px',
  },
};
