export const assets = {
  email: {
    image:
      'https://images.unsplash.com/photo-1584438784894-089d6a62b8fa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80',
  },
  welcome: {
    image:
      'https://images.unsplash.com/photo-1556741533-6e6a62bd8b49?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80',
  },
  calculation: {
    spinner:
      'https://icon-library.com/images/animated-loading-icon/animated-loading-icon-3.jpg',
  },
};
