import React from 'react';
import { Checkbox } from '@shopify/polaris';
import { useFormContext, Controller } from 'react-hook-form';

function ControlledCheckbox({
  name,
  label,
  defaultValue = false,
  disabled = false,
  helpText = null,
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onBlur, onChange, value, ref } }) => (
        <Checkbox
          label={label}
          onChange={onChange}
          checked={value}
          onBlur={onBlur}
          inputRef={ref}
          disabled={disabled}
          helpText={helpText}
        />
      )}
    />
  );
}

export default ControlledCheckbox;
