import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Page, Layout, Toast, Loading } from '@shopify/polaris';
import { useMutation } from 'react-query';
import { FormProvider } from 'react-hook-form';

import { FollowUpEmailMajor } from '@shopify/polaris-icons';
import EmailEditor from './EmailEditor';
import EmailAnnotation from './EmailAnnotation';
import EmailTestModal from './EmailTestModal';

import APIContext from '../../../providers/APIContext';
import useCampaign from '../../../hooks/useCampaign';

function EmailPage() {
  const { campaign, isLoading, form, editMarkup, saveAction } = useCampaign();

  const { postPreviewEmail } = useContext(APIContext);

  const sendTestEmail = useMutation((email) =>
    postPreviewEmail(campaign.id, email),
  );

  const [testEmailModalOpen, setTestEmailModalOpen] = useState(false);

  const openTestEmailModal = () => setTestEmailModalOpen(true);

  const handleTestEmailModalCancel = useCallback(() => {
    sendTestEmail.reset();
    setTestEmailModalOpen(false);
  }, [sendTestEmail]);

  const [toastActive, setToastActive] = useState(false);

  const dismissToast = () => setToastActive(false);

  useEffect(() => {
    if (sendTestEmail.isSuccess) {
      setToastActive(true);
      handleTestEmailModalCancel();
    }
  }, [sendTestEmail, handleTestEmailModalCancel]);

  if (isLoading) return <Loading />;

  return (
    <Page
      title="E-Mail Editor"
      primaryAction={saveAction}
      secondaryActions={[
        {
          content: 'Send Test E-Mail',
          icon: FollowUpEmailMajor,
          onAction: openTestEmailModal,
        },
      ]}
    >
      <FormProvider {...form}>
        <Layout>
          <Layout.AnnotatedSection
            title="Customization"
            description={<EmailAnnotation />}
          >
            <EmailEditor campaign={campaign} />
          </Layout.AnnotatedSection>
        </Layout>
      </FormProvider>
      <EmailTestModal
        active={testEmailModalOpen}
        handleCancel={handleTestEmailModalCancel}
        emailTemplateForm={form}
        sendTestEmail={sendTestEmail}
      />
      {toastActive && (
        <Toast content="Test E-Mail sent" onDismiss={dismissToast} />
      )}
      {editMarkup}
    </Page>
  );
}

export default EmailPage;
