import React from 'react';
import Radium from 'radium';
import { useFormContext } from 'react-hook-form';
import { Subheading } from '@shopify/polaris';

import styles from './RecommendationPreview.module.css';

function RecommendationPreview() {
  const { watch } = useFormContext();
  const val = (name) => watch(`style.variables.${name}`);

  return (
    <>
      <Subheading>Preview</Subheading>
      <img
        className={styles.img}
        src="../../../../../placeholder-images-product-1_large.png"
        alt="Product"
      />
      <div
        className={styles.product}
        style={{
          fontFamily: val('--meja-product-title-font-family'),
          fontSize: val('--meja-product-title-font-size'),
          textTransform: val('--meja-product-title-text-transform'),
          color: val('--meja-color-primary'),
        }}
      >
        Product name
      </div>
      <div
        className={styles.price}
        style={{
          fontFamily: val('--meja-price-font-family'),
          fontSize: val('--meja-price-font-size'),
          color: val('--meja-color-primary-subdued'),
        }}
      >
        From $29.99
      </div>
    </>
  );
}

export default Radium(RecommendationPreview);
