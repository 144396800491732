import { postAuth } from './common';

export async function authorize(shop, timestamp, hmac) {
  const result = await postAuth('api/v2/oauth/authorize', {
    shop,
    timestamp,
    hmac,
  });
  return result.data;
}

export async function callback(payload) {
  const result = await postAuth('api/v2/oauth/return', payload);
  return result.data;
}
