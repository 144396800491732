import React, { useContext } from 'react';
import Radium from 'radium';
import { useFormContext } from 'react-hook-form';

import StoreContext from '../../../../providers/StoreContext';

import styles from './LoadingPreview.module.css';

function LoadingPreview({ campaign }) {
  const { watch } = useFormContext();

  const header =
    watch('text.calculation.header') || campaign.text.calculation.header;
  const subheader =
    watch('text.calculation.subheader') || campaign.text.calculation.subheader;
  const spinner =
    watch('assets.calculation.spinner') || campaign.assets.calculation.spinner;

  const { isLoading, assetURL } = useContext(StoreContext);
  const src = isLoading ? '/placeholder_meja.jpg' : assetURL(spinner);

  return (
    <div className={styles.container}>
      <div
        className={styles.header}
        style={[
          {
            fontFamily: campaign.style.variables['--meja-header-font-family'],
            fontSize: campaign.style.variables['--meja-header-font-size'],
            color: campaign.style.variables['--meja-color-primary'],
            lineHeight: '1.2em',
          },
        ]}
      >
        {header}
      </div>
      <div className={styles.imagecontainer}>
        <img className={styles.image} src={src} alt="" />
      </div>
      <div
        className={styles.subheader}
        style={[
          {
            fontFamily:
              campaign.style.variables['--meja-subheader-font-family'],
            color: campaign.style.variables['--meja-color-primary-subdued'],
          },
        ]}
      >
        {subheader}
      </div>
    </div>
  );
}

export default Radium(LoadingPreview);
