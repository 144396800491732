import React from 'react';
import { Layout, Card, FormLayout, TextContainer } from '@shopify/polaris';

import ControlledCheckbox from '../../common/input/ControlledCheckbox';
import ControlledTextField from '../../common/input/ControlledTextField';

function QuestionSection() {
  return (
    <Layout.Section>
      <Card sectioned>
        <FormLayout>
          <ControlledTextField name="text.question" label="Question" />
          <ControlledCheckbox
            name="config.optional"
            label="Optional"
            helpText={
              <TextContainer>
                <p>
                  If selected, the customer will have the option to skip the
                  question.
                </p>
              </TextContainer>
            }
          />
          <ControlledCheckbox
            name="config.multiple_choice"
            label="Multiple choice"
            helpText={
              <TextContainer>
                <p>
                  If selected, the customer will have the option to select
                  multiple answers.
                </p>
              </TextContainer>
            }
          />
          <ControlledCheckbox
            name="filter_products"
            label="Filtering"
            helpText={
              <TextContainer>
                <p>
                  If selected, answers from this question will restrict the
                  recommended product pool. Use with caution, as this can lead
                  to empty results.
                </p>
              </TextContainer>
            }
          />
          <ControlledTextField
            name="klaviyo_alias"
            label="Alias"
            maxLength={50}
            helpText={
              <TextContainer>
                <p>
                  The Alias will be used as an identifier in external
                  applications and aggregated statistics.
                </p>
              </TextContainer>
            }
          />
        </FormLayout>
      </Card>
    </Layout.Section>
  );
}

export default QuestionSection;
