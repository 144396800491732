import { del, get, patch, post } from './common';

export async function patchQuestion(id, question, token) {
  const { data } = await patch(`api/v2/questions/${id}`, question, token);
  return data;
}

export async function createQuestion(question, token) {
  const { data } = await post(`api/v2/questions/`, question, token);
  return data;
}

export async function deleteQuestion(id, token) {
  const { data } = await del(`api/v2/questions/${id}`, token);
  return data;
}

export async function fetchQuestion(id, token) {
  const { data } = await get(`api/v2/questions/${id}`, token);
  return data;
}

export async function fetchQuestions(token, questionnaire_id = null) {
  const { data } = await get(`api/v2/questions`, token, { questionnaire_id });
  return data;
}
