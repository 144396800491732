import { useContext } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import APIContext from '../providers/APIContext';
import useQuestion from './useQuestion';

export default function useAnswers({ onCreationSuccess = null }) {
  const { fetchAnswers, createAnswer, deleteAnswer, patchQuestion } =
    useContext(APIContext);

  const queryClient = useQueryClient();

  const { id } = useParams();
  const questionId = parseInt(id);

  const { question } = useQuestion(questionId);
  const order = question?.config?.answer_order || [];

  const { isLoading, data: unorderedAnswers = [] } = useQuery(
    ['fetchAnswers', questionId],
    () => fetchAnswers(questionId),
  );

  // Order Answers

  const orderAnswers = (answers, order) => {
    let orderedAnswers = [];
    order.forEach((id) => {
      if (answers.find((q) => q.id === id))
        orderedAnswers.push(answers.find((q) => q.id === id));
    });
    return orderedAnswers;
  };

  const answers = orderAnswers(unorderedAnswers, order);

  // Add new answer

  const {
    mutate: create,
    isLoading: isCreating,
    isSuccess: isCreated,
  } = useMutation(
    (answer) =>
      createAnswer({
        ...answer,
        klaviyo_tag: answer.klaviyo_tag
          ? answer.klaviyo_tag
          : answer.text.answer
              .replace(/[^a-zA-Z0-9]/g, '')
              .toLowerCase()
              .substring(0, 50),
        question_id: questionId,
        style: {},
        config: {},
        products: [],
      }),
    {
      mutationKey: 'createAnswer',
      onSuccess: (newAnswer) => {
        patchQuestion(questionId, {
          config: { answer_order: [...order, newAnswer.id] },
        });
        queryClient.setQueryData(
          ['fetchAnswers', questionId],
          [...answers, newAnswer],
        );
        queryClient.setQueryData(
          ['fetchQuestion', questionId],
          (oldQuestion) => ({
            ...oldQuestion,
            config: { answer_order: [...order, newAnswer.id] },
          }),
        );
        onCreationSuccess();
      },
    },
  );

  // Delete answer

  const {
    mutate: del,
    isLoading: isDeleting,
    isSuccess: isDeleted,
  } = useMutation(
    (question) =>
      patchQuestion(questionId, {
        config: {
          answer_order: order.filter((id) => id !== question.id),
        },
      }),
    {
      mutationKey: 'deleteAnswer',
      onSuccess: ({ config: { answer_order: newOrder } }) => {
        let deletedAnswers = order.filter((q) => !newOrder.includes(q));
        //TODO: Handle deletion of other answers simultainiusly
        let deletedAnswerID = deletedAnswers[0];
        deleteAnswer(deletedAnswerID);
        queryClient.setQueryData(
          ['fetchAnswers', questionId],
          answers.filter((q) => q.id !== deletedAnswerID),
        );
        queryClient.setQueryData(
          ['fetchQuestion', questionId],
          (oldQuestion) => ({
            ...oldQuestion,
            config: {
              answer_order: order.filter((id) => id !== deletedAnswerID),
            },
          }),
        );
      },
    },
  );

  // Move answer (Re-arrange answer order)

  const {
    mutate: reOrder,
    isLoading: isMoving,
    isSuccess: isMoved,
  } = useMutation(
    (order) =>
      patchQuestion(questionId, {
        config: { answer_order: order },
      }),
    {
      mutationKey: 'moveAnswer',
      onSuccess: ({ config: { answer_order: newOrder } }) => {
        queryClient.setQueryData(
          ['fetchQuestion', questionId],
          (oldQuestion) => ({
            ...oldQuestion,
            config: { answer_order: newOrder },
          }),
        );
      },
    },
  );

  const move = (from, to) =>
    reOrder(
      order.map((val, idx) => {
        if (idx === from) return order[to];
        if (idx === to) return order[from];
        return val;
      }),
    );

  return {
    answers,
    isLoading,
    create,
    isCreating,
    isCreated,
    del,
    isDeleting,
    isDeleted,
    move,
    isMoving,
    isMoved,
  };
}
