import React from 'react';
import { Layout } from '@shopify/polaris';

import AnswerSection from './AnswerSection';
import ProductsSection from './ProductsSection';
import CollectionsSection from './CollectionsSection';
import TagsSection from './TagsSection';
import VariantsSection from './VariantsSection';
import RankingSection from './RankingSection';

function AnswerLayout() {
  return (
    <Layout>
      <AnswerSection />
      <RankingSection />
      <CollectionsSection />
      <TagsSection />
      <ProductsSection />
      <VariantsSection />
    </Layout>
  );
}

export default AnswerLayout;
