import React, { useEffect } from 'react';
import {
  Stack,
  TextStyle,
  Badge,
  TextContainer,
  Modal,
} from '@shopify/polaris';
import { DeleteMinor } from '@shopify/polaris-icons';
import useQuestions from '../../../hooks/useQuestions';

function QuestionDeleteModal({ question, open, onClose }) {
  const {
    config: { multiple_choice, optional },
    text: { question: questionText },
  } = question;

  const { del: deleteQuestion, isDeleting, isDeleted } = useQuestions({});
  useEffect(() => {
    if (isDeleted) onClose();
  }, [isDeleted, onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Delete question permanently"
      primaryAction={{
        icon: DeleteMinor,
        content: 'Delete',
        destructive: true,
        onAction: () => deleteQuestion(question),
        loading: isDeleting,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>
            Are you sure you want to delete the following question?
            <br />
            <br />
          </p>
        </TextContainer>
        <TextContainer spacing="tight">
          <TextStyle variation="strong">{questionText}</TextStyle>
          <Stack spacing="tight">
            <Badge>
              {multiple_choice ? 'Multiple choice' : 'Single choice'}
            </Badge>
            <Badge>{optional ? 'Optional' : 'Non-optional'}</Badge>
            {/* <Badge
              status={
                answers.length < 2
                  ? answers.length < 1
                    ? 'critical'
                    : 'warning'
                  : 'default'
              }
            >
              {answers.length} {answers.length === 1 ? 'answer' : 'answers'}
            </Badge> */}
          </Stack>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
}

export default QuestionDeleteModal;
