import React from 'react';
import { Layout } from '@shopify/polaris';

import ButtonPreview from './ButtonPreview';
import ButtonStyling from './ButtonStyling';

function ButtonLayout() {
  return (
    <Layout>
      <Layout.AnnotatedSection
        title="Button"
        description={
          <>
            <div style={{ marginBottom: '16px' }}>
              You'll find buttons used on the landing and e-mail page.
              <br />
              <br />
              Buttons use your primary and secondary color.
            </div>
            <ButtonPreview />
          </>
        }
      >
        <ButtonStyling />
      </Layout.AnnotatedSection>
    </Layout>
  );
}

export default ButtonLayout;
