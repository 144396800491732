import React from 'react';
import { Select } from '@shopify/polaris';
import { useFormContext, Controller } from 'react-hook-form';

function ControlledSelect({
  name,
  label,
  options,
  helpText = <></>,
  defaultValue = '',
  rules = {},
  disabled = false,
  placeholder = null,
  errorMessage = 'Invalid input.',
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const setDefaultValue = defaultValue ? { defaultValue } : {};

  return (
    <Controller
      name={name}
      control={control}
      {...setDefaultValue}
      rules={rules}
      render={({ field: { onBlur, onChange, value, ref } }) => (
        <Select
          options={options}
          label={label}
          helpText={helpText}
          onChange={onChange}
          value={isNaN(value) ? value : parseInt(value)}
          error={errors[name] ? errorMessage : null}
          onBlur={onBlur}
          inputRef={ref}
          disabled={disabled}
          placeholder={placeholder}
        />
      )}
    />
  );
}

export default ControlledSelect;
