import React from 'react';
import { Layout, Card } from '@shopify/polaris';

import CollectionsList from './CollectionsList';

function CollectionsSection() {
  return (
    <Layout.AnnotatedSection
      id="collectionsSection"
      title="Collections"
      description="Select which collections are recommendable for a customer that replies with this answer."
    >
      <Card>
        <CollectionsList />
      </Card>
    </Layout.AnnotatedSection>
  );
}

export default CollectionsSection;
