export const text = {
  email: {
    header: 'Access your recommendation any time',
    subheader:
      "We'll send you your recommendation by e-mail so you can access it any time.",
    name_error: 'Please enter your name',
    email_error: 'Please enter a valid e-mail address',
    name_placeholder: 'Name',
    email_placeholder: 'E-Mail',
    confirm_button_text: 'Confirm',
    continue_without_email_text:
      "I'd rather not receive my recommendation by e-mail",
  },
  results: {
    from: 'from',
    header: 'These products would be perfect for you 🤩',
    restart: 'start over',
    currency: '$',
    subheader: 'This selection was compiled based on your answers',
    show_more: 'show more',
  },
  welcome: {
    button: "Let's go",
    header: "We'll help you find the perfect products for you",
    subheader: 'It only takes a minute or two.',
  },
  question: { next: 'skip', continue: 'continue', previous: 'previous' },
  calculation: {
    header: 'Your personalized recommendation is being computed',
    subheader: 'Please wait and do not close this window',
  },
};
