import React from 'react';
import Radium from 'radium';
import { useFormContext } from 'react-hook-form';

import styles from './ResultsPreview.module.css';

function ResultsPreview({ campaign }) {
  const { watch } = useFormContext();

  const header = watch('text.results.header') || campaign.text.results.header;
  const subheader =
    watch('text.results.subheader') || campaign.text.results.subheader;
  const restart =
    watch('text.results.restart') || campaign.text.results.restart;
  const prefix = watch('text.results.from') || campaign.text.results.from;
  const postfix =
    watch('text.results.currency') || campaign.text.results.currency;

  const results = [0, 1, 2].map((e, i) => (
    <div key={i} className={styles.tile}>
      <div className={styles.imagecontainer}>
        <img
          className={styles.image}
          src="../../../../../placeholder-images-product-1_large.png"
          alt="Product"
        />
      </div>
      <div
        className={styles.title}
        style={[
          {
            fontFamily:
              campaign.style.variables['--meja-product-title-font-family'],
            fontSize:
              campaign.style.variables['--meja-product-title-font-size'],
            color: campaign.style.variables['--meja-product-title-color'],
            textTransform:
              campaign.style.variables['--meja-product-title-text-transform'],
          },
        ]}
      >
        Product
      </div>
      <div
        className={styles.price}
        style={[
          {
            fontFamily: campaign.style.variables['--meja-price-font-family'],
            fontSize: campaign.style.variables['--meja-price-font-size'],
            color: campaign.style.variables['--meja-price-color'],
          },
        ]}
      >
        {prefix ? `${prefix} ` : ''}
        {postfix} {29.99}
      </div>
    </div>
  ));

  return (
    <div className={styles.container}>
      <div
        className={styles.header}
        style={[
          {
            fontFamily: campaign.style.variables['--meja-header-font-family'],
            fontSize: campaign.style.variables['--meja-header-font-size'],
            color: campaign.style.variables['--meja-color-primary'],
            lineHeight: '1.2em',
          },
        ]}
      >
        {header}
      </div>
      <div className={styles.results}>{results}</div>
      <div
        className={`subheader ${styles.subheader}`}
        style={[
          {
            fontFamily:
              campaign.style.variables['--meja-subheader-font-family'],
            fontSize: campaign.style.variables['--meja-subheader-font-size'],
            color: campaign.style.variables['--meja-color-primary'],
          },
        ]}
      >
        {subheader}
      </div>
      <div
        className={styles.subheader}
        style={[
          {
            fontFamily:
              campaign.style.variables['--meja-subheader-font-family'],
            color: campaign.style.variables['--meja-color-primary-subdued'],
          },
        ]}
      >
        <span
          key="continue"
          style={[
            {
              color: campaign.style.variables['--meja-color-primary-subdued'],
              ':hover': {
                color: campaign.style.variables['--meja-color-primary-accent'],
                cursor: 'pointer',
              },
            },
          ]}
        >
          {restart}
        </span>
      </div>
    </div>
  );
}

export default Radium(ResultsPreview);
