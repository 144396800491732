import React from 'react';
import { FormLayout, Card } from '@shopify/polaris';

import ControlledTextField from '../../../common/input/ControlledTextField';
import { fontFamilyHelpText } from '../../../common/helpText';

function ButtonStyling() {
  return (
    <Card sectioned>
      <FormLayout>
        <ControlledTextField
          name="style.variables.--meja-button-font-family"
          label="Font"
          helpText={fontFamilyHelpText}
          rules={{ required: true }}
          errorMessage="Font is required"
        />

        <ControlledTextField
          name="style.variables.--meja-button-font-size"
          label="Font size"
        />

        <ControlledTextField
          name="style.variables.--meja-button-padding"
          label="Padding"
        />

        <ControlledTextField
          name="style.variables.--meja-button-font-weight"
          label="Font weight"
        />

        <ControlledTextField
          name="style.variables.--meja-button-text-transform"
          label="Text tranform"
        />

        <ControlledTextField
          name="style.variables.--meja-button-border-width"
          label="Border (width)"
        />
      </FormLayout>
    </Card>
  );
}

export default ButtonStyling;
