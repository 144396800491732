import React, { useEffect } from 'react';
import {
  Page,
  Layout,
  Modal,
  Stack,
  Spinner,
  DisplayText,
} from '@shopify/polaris';
import { Redirect } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import useBilling from '../../../hooks/useBilling';

function BillingSuccessPage() {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries(['checkBilling']);
  }, [queryClient]);

  const { isLoading, billingStatus, billingRedirectURL } = useBilling();

  if (isLoading)
    return (
      <Page>
        <Layout>
          <Layout.Section>
            <Modal open>
              <Modal.Section>
                <Stack
                  vertical
                  alignment="center"
                  style={{ marginTop: '10vh' }}
                >
                  <Spinner
                    accessibilityLabel="Checking subscription activation"
                    size="large"
                    color="teal"
                  />
                  <DisplayText size="large">
                    Subscription activated successfully
                  </DisplayText>
                </Stack>
              </Modal.Section>
            </Modal>
          </Layout.Section>
        </Layout>
      </Page>
    );

  if (billingStatus === 'active') return <Redirect to="/app" />;

  if (billingStatus === 'pending') window.location.href = billingRedirectURL;

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Modal open>
            <Modal.Section>
              <Stack vertical alignment="center" style={{ marginTop: '10vh' }}>
                <Spinner
                  accessibilityLabel="Something went wrong. Redirecting to Shopify..."
                  size="large"
                  color="teal"
                />
                <DisplayText size="large">
                  Something went wrong. Redirecting to Shopify...
                </DisplayText>
              </Stack>
            </Modal.Section>
          </Modal>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default BillingSuccessPage;
