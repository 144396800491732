import { del, patch, post, get } from './common';

export async function patchAnswer(id, answer, token) {
  const { data } = await patch(`api/v2/answers/${id}`, answer, token);
  return data;
}

export async function createAnswer(answer, token) {
  const { data } = await post(`api/v2/answers/`, answer, token);
  return data;
}

export async function deleteAnswer(id, token) {
  const { data } = await del(`api/v2/answers/${id}`, token);
  return data;
}

export async function fetchAnswer(id, token) {
  const { data } = await get(`api/v2/answers/${id}`, token);
  return data;
}

export async function fetchAnswers(token, question_id = null) {
  const { data } = await get(`api/v2/answers`, token, { question_id });
  return data;
}
