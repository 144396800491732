import React, { useState, useCallback, useEffect } from 'react';
import {
  ResourceList,
  Filters,
  Card,
  ResourceItem,
  TextStyle,
  Thumbnail,
  Stack,
  Pagination,
  SkeletonThumbnail,
} from '@shopify/polaris';

import { resize } from '../../common/utils/shopify/imageResize';
import { useParams } from 'react-router-dom';
import useAnswer from '../../../hooks/useAnswer';

import useCollections from '../../../hooks/useCollections';

function CollectionsList() {
  const { aid: id } = useParams();
  const { selectedCollections, replaceCollections } = useAnswer(id);

  const { isLoading: isLoadingCollections, collections } = useCollections();

  const [queryValue, setQueryValue] = useState(null);

  const filteredProducts = collections.filter((product) =>
    product.title.toLowerCase().includes(queryValue?.toLowerCase() || ''),
  );

  const handleQueryValueChange = useCallback(
    (value) => setQueryValue(value),
    [],
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);

  const [offset, setOffset] = useState(0);
  const limit = 5;
  useEffect(() => setOffset(0), [queryValue]);

  return (
    <>
      <Card>
        <Card.Section>
          <ResourceList
            loading={isLoadingCollections}
            resourceName={{
              singular: 'collection',
              plural: 'collections',
            }}
            items={filteredProducts.slice(offset, offset + limit)}
            totalItemsCount={filteredProducts.length}
            idForItem={(collection) => collection.collection_id}
            renderItem={({ collection_id: id, image, image_alt, title }) => (
              <ResourceItem
                key={id}
                id={id}
                url={false}
                media={
                  image ? (
                    <Thumbnail
                      source={resize(image, 'thumb')}
                      alt={image_alt}
                    />
                  ) : (
                    <SkeletonThumbnail />
                  )
                }
              >
                <h3>
                  <TextStyle variation="strong">{title}</TextStyle>
                </h3>
              </ResourceItem>
            )}
            selectable
            selectedItems={selectedCollections}
            onSelectionChange={replaceCollections}
            filterControl={
              <Filters
                filters={[]}
                queryValue={queryValue}
                onQueryChange={handleQueryValueChange}
                onQueryClear={handleQueryValueRemove}
              />
            }
          />
        </Card.Section>
        {filteredProducts.length > limit && (
          <Card.Section>
            <Stack distribution="center">
              <Pagination
                hasPrevious={offset > 0}
                onPrevious={() => setOffset((prev) => prev - limit)}
                hasNext={filteredProducts.length > offset + limit}
                onNext={() => setOffset((prev) => prev + limit)}
              />
            </Stack>
          </Card.Section>
        )}
      </Card>
    </>
  );
}

export default CollectionsList;
