import React, { useState, useCallback } from 'react';
import { Button, Layout, TextContainer, Modal } from '@shopify/polaris';
import LoadingPreview from './LoadingPreview';
import LoadingEditing from './LoadingEditing';

function LoadingLayout({ campaign }) {
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const activator = (
    <Button fullWidth onClick={toggleActive}>
      Preview Loading Page
    </Button>
  );

  return (
    <Layout>
      <Layout.AnnotatedSection
        title="Loading Page"
        description={
          <>
            <TextContainer>
              <p>
                The loading page is displayed while our servers compute the
                customers recommendation. We recommend using a moving GIF or mp4
                as a spinner, to indicate that something is going on. Depending
                on the customers internet connection this might take anywhere
                from 0.1 to 2 or more seconds, but if you want to make sure that
                the customer can enjoy your GIF, we recommend setting a minimum
                waiting time.
              </p>
              <Modal
                large
                activator={activator}
                open={active}
                onClose={toggleActive}
                title="Loading Page preview"
              >
                <Modal.Section>
                  <LoadingPreview campaign={campaign} />
                </Modal.Section>
              </Modal>
            </TextContainer>
          </>
        }
      >
        <LoadingEditing campaign={campaign} />
      </Layout.AnnotatedSection>
    </Layout>
  );
}

export default LoadingLayout;
