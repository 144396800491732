import React from 'react';
import { Page, Loading } from '@shopify/polaris';

import { FormProvider } from 'react-hook-form';

import { useParams, useLocation } from 'react-router';
import AnswerLayout from './AnswerLayout';

import useAnswer from '../../../hooks/useAnswer';

export default function AnswerPage() {
  const { aid: id } = useParams();
  const { isLoading, form, saveAction, editMarkup } = useAnswer(id);
  const { pathname: location } = useLocation();
  const url = location.substring(0, location.indexOf('/answer/'));

  if (isLoading) return <Loading />;

  return (
    <>
      <FormProvider {...form}>
        <Page
          breadcrumbs={[{ content: 'Answers', url: url }]}
          title="Answer"
          primaryAction={saveAction}
        >
          <AnswerLayout />
        </Page>
      </FormProvider>
      {editMarkup}
    </>
  );
}
