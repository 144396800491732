export const config = {
  email: {
    ask_name: true,
    name_required: false,
    email_required: true,
    continue_without_email: true,
  },
  welcome: { skip: false },
  question: { default_question_order: [] },
  calculation: { min_waiting_time_ms: 2000 },
  results: { results_hard_limit: 6, results_soft_limit: 3 },
};
