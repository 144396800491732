import React from 'react';
import { Redirect, Switch, Route, useRouteMatch } from 'react-router';
import { Frame, TopBar } from '@shopify/polaris';

import AccessPage from './access/AccessPage';
import ReturnPage from './return/ReturnPage';
import LoginPage from './login/LoginPage';

function ShopifyFrame(props) {
  // 2-nd level, path = /app
  const { path } = useRouteMatch();

  return (
    <Frame topBar={<TopBar />}>
      <Switch>
        <Route path={`${path}/access`}>
          <AccessPage />
        </Route>
        <Route path={`${path}/return`}>
          <ReturnPage />
        </Route>
        <Route path={`${path}/login`}>
          <LoginPage />
        </Route>
        <Redirect to={`${path}/login`} />
      </Switch>
    </Frame>
  );
}

export default ShopifyFrame;
