import React, { useState, useCallback } from 'react';
import { DatePicker } from '@shopify/polaris';
import { useFormContext, Controller } from 'react-hook-form';

function ControlledDatePicker({
  name,
  label,
  rules = {},
  disabled = false,
  year: defaultYear,
  month: defaultMonth,
  defaultValue,
  ...rest
}) {
  const {
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext();

  const [{ month, year }, setDate] = useState({
    month: defaultMonth,
    year: defaultYear,
  });
  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    [],
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <DatePicker
          onChange={(newValue) => {
            setValue(name, newValue);
            if (errors[name]) trigger(name);
          }}
          onMonthChange={handleMonthChange}
          selected={value}
          month={month}
          year={year}
          {...rest}
        />
      )}
    />
  );
}

export default ControlledDatePicker;
