import React from 'react';
import { Layout, Link } from '@shopify/polaris';
import ColorsPreview from './ColorsPreview';
import ColorsStyling from './ColorsStyling';

function ColorsLayout() {
  return (
    <Layout>
      <Layout.AnnotatedSection
        title="Colors"
        description={
          <>
            <div style={{ marginBottom: '16px' }}>
              Style the project according to your brand colors. If you need any
              custom support, text us at:{' '}
              <Link external url="mailto:hello@meja.io">
                hello@meja.io
              </Link>
              .
            </div>
            <ColorsPreview />
          </>
        }
      >
        <ColorsStyling />
      </Layout.AnnotatedSection>
    </Layout>
  );
}

export default ColorsLayout;
