import React, { useContext } from 'react';
import {
  Layout,
  FormLayout,
  Card,
  Page,
  TextStyle,
  Loading,
} from '@shopify/polaris';

import { FormProvider } from 'react-hook-form';

import ControlledTextField from '../../common/input/ControlledTextField';
import StoreContext from '../../../providers/StoreContext';
import useCampaign from '../../../hooks/useCampaign';

function CampaignOverviewPage() {
  const {
    store: {
      shopify_store: { domain, path_prefix: prefix },
    },
  } = useContext(StoreContext);

  const { campaign, isLoading, form, editMarkup, saveAction } = useCampaign();

  if (isLoading || !campaign) return <Loading />;

  const campaignURL = (
    <TextStyle variation="code">
      {`https://${domain}${prefix}/`}
      <TextStyle variation="strong">
        {form.watch('slug') || campaign.slug}
      </TextStyle>
    </TextStyle>
  );

  return (
    <Page title={`${campaign.name} Overview`} primaryAction={saveAction}>
      <FormProvider {...form}>
        <Layout>
          <Layout.AnnotatedSection
            title="Project"
            description={
              <p>
                Your projects consits of multiple elements, see and edit all of
                them from the navigation menu on the left.
              </p>
            }
          >
            <Card sectioned>
              <FormLayout>
                <ControlledTextField name="name" label="Name" />

                <ControlledTextField
                  name="slug"
                  label="Slug"
                  helpText={
                    <p>
                      The slug defines under which URL your campaign will be
                      available:
                      <br />
                      {campaignURL}
                    </p>
                  }
                />
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </FormProvider>
      {editMarkup}
    </Page>
  );
}

export default CampaignOverviewPage;
