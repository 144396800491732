import React, { useContext } from 'react';
import {
  Stack,
  Card,
  Heading,
  Button,
  Layout,
  TextContainer,
  DisplayText,
  TextStyle,
  SkeletonDisplayText,
} from '@shopify/polaris';
import { useQuery } from 'react-query';
import APIContext from '../../../../providers/APIContext';

function DashboardSection() {
  const { fetchRecommendationsCounts } = useContext(APIContext);

  const {
    isLoading,
    data: counts,
    isError,
  } = useQuery('fetchRecommendationsCounts', fetchRecommendationsCounts);

  return (
    <Layout.Section>
      <Stack vertical>
        <TextContainer>
          <Heading>Here’s How Your Projects Have Been Doing</Heading>
        </TextContainer>

        <Stack distribution="fillEvenly" spacing="loose">
          <Card sectioned>
            <TextContainer spacing="tight">
              {isLoading || isError ? (
                <SkeletonDisplayText size="large" />
              ) : (
                <DisplayText size="large">{counts[0].total}</DisplayText>
              )}
              <TextStyle variation="subdued">
                Recommendations were made
              </TextStyle>
            </TextContainer>
            <Stack distribution="trailing">
              <Button plain url="/app/customers">
                See recommendations
              </Button>
            </Stack>
          </Card>
          <Card sectioned>
            <TextContainer spacing="tight">
              {isLoading || isError ? (
                <SkeletonDisplayText size="large" />
              ) : (
                <DisplayText size="large">
                  {counts[0].total_distinct_email}
                </DisplayText>
              )}
              <TextStyle variation="subdued">
                Unique e-mail addresses collected
              </TextStyle>
            </TextContainer>
            <Stack distribution="trailing">
              <Button plain url="/app/customers">
                See recommendations
              </Button>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </Layout.Section>
  );
}

export default DashboardSection;
