import React from 'react';
import { Page, Layout, FooterHelp, Link } from '@shopify/polaris';

import DashboardSection from './dashboard/DashboardSection';
import SetupSection from './setup/SetupSection';

function HomePage() {
  return (
    <Page>
      <Layout>
        <SetupSection />
        <DashboardSection />
        <Layout.Section>
          <FooterHelp>
            We are always here to help! Get in touch at{' '}
            <Link external url="mailto:hello@meja.io">
              hello@meja.io
            </Link>
          </FooterHelp>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default HomePage;
