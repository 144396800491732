import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router';
import useBilling from '../hooks/useBilling';

import { AuthContext } from '../providers/AuthContext';
import { CampaignProvider } from '../providers/CampaignContext';
import { StoreProvider } from '../providers/StoreContext';

function PrivateRoute({ children, component, ...rest }) {
  const { auth } = useContext(AuthContext);
  const { isLoading, billingStatus, billingRedirectURL } = useBilling();
  const { token_is_admin: isAdmin } = auth || {};

  if (!auth) return <Redirect to="/shopify/login" />;

  if (!isAdmin && isLoading) return <></>;

  if (!isAdmin && billingStatus === 'pending') {
    window.location.href = billingRedirectURL;
    return 'Meja subscription pending. Redirecting to Shopify...';
  }

  return (
    <Route {...rest}>
      <StoreProvider>
        <CampaignProvider>{children}</CampaignProvider>
      </StoreProvider>
    </Route>
  );
}

export default PrivateRoute;
