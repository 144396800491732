const alex = {
  backend: 'https://api.alex.meja.io/',
  frontend: 'https://alex.meja.io/',
};

const sven = {
  backend: 'https://api.sven.meja.io/',
  frontend: 'https://sven.meja.io/',
};

const elephbo = {
  backend: 'https://api.elephbo.meja.io/',
  frontend: 'https://app.elephbo.meja.io/',
};

const staging = {
  backend: 'https://api.staging.meja.io/',
  frontend: 'https://app.staging.meja.io/',
};

const production = {
  backend: 'https://api.app.meja.io/',
  frontend: 'https://app.meja.io/',
};

const env = process.env.REACT_APP_ENV;
/* eslint-disable */
const config =
  env === 'alex'
    ? alex
    : env === 'sven'
    ? sven
    : env === 'elephbo'
    ? elephbo
    : env === 'staging'
    ? staging
    : env === 'production'
    ? production
    : null;
/* eslint-enable */
if (!config) {
  throw new Error(
    'Invalid configuration, please specify environment in REACT_APP_ENV',
  );
}
if (env === 'dev') {
  throw new Error('The dev env is currently not nsupported');
}

const common = {
  env,
};

export default {
  ...common,
  ...config,
};
