import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Switch, Route, useParams } from 'react-router';
import { useQuery } from 'react-query';

import {
  Page,
  Loading,
  Layout,
  ResourceList,
  Card,
  EmptyState,
  Link,
} from '@shopify/polaris';

import CampaignItem from './CampaignItem';

import CampaignPage from './CampaignPage';
import CampaignCreateModal from './CampaignCreateModal';
import APIContext from '../../../providers/APIContext';
import CampaignContext from '../../../providers/CampaignContext';

function CampaignsPage() {
  const { campaignId, setCampaignId } = useContext(CampaignContext);

  const { fetchCampaigns } = useContext(APIContext);

  const { slug } = useParams();

  const {
    isLoading,
    isFetching,
    error,
    data: campaigns,
  } = useQuery('fetchCampaigns', fetchCampaigns);

  useEffect(() => {
    if (campaigns && slug) {
      setCampaignId(campaigns.find((campaign) => campaign.slug === slug).id);
    } else if (!slug) {
      setCampaignId(null);
    }
  });

  const [createOpen, setCreateOpen] = useState(false);

  const onCreate = useCallback(() => setCreateOpen(true), []);

  if (isLoading || isFetching) return <Loading />;

  if (error) return 'An error has occurred: ' + error.message;

  if (campaignId) return <CampaignPage />;

  const primaryAction = isLoading
    ? { content: 'Create Project', disabled: true }
    : { content: 'Create Project', onClick: onCreate };

  return (
    <Switch>
      <Route>
        <Page title="Projects" primaryAction={primaryAction}>
          <Layout>
            <Layout.Section>
              <Card>
                <ResourceList
                  resourceName={{ singular: 'project', plural: 'projects' }}
                  items={campaigns}
                  renderItem={(campaign, index) => (
                    <CampaignItem {...{ campaign, index }} />
                  )}
                  emptyState={
                    <EmptyState
                      heading="Create your first project"
                      action={{
                        content: 'Create project',
                        onAction: () => setCreateOpen(true),
                      }}
                    >
                      <div
                        style={{
                          position: 'relative',
                          paddingBottom: '54.13533834586466%',
                          height: 0,
                        }}
                      >
                        <iframe
                          title="How to create a project video"
                          src="https://www.loom.com/embed/04e27670ae1145a1836837446631cc7b?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                          frameBorder="0"
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          allowFullScreen
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                          }}
                        ></iframe>
                      </div>
                      <p>
                        Watch this video to see how you can create your first
                        project. If you need further support, let us know:{' '}
                        <Link external url="mailto:hello@meja.io">
                          hello@meja.io
                        </Link>{' '}
                        - We're happy to help.
                      </p>
                    </EmptyState>
                  }
                />
              </Card>
            </Layout.Section>
          </Layout>
          <CampaignCreateModal
            open={createOpen}
            onClose={() => setCreateOpen(false)}
            campaigns={campaigns}
          />
        </Page>
      </Route>
    </Switch>
  );
}

export default CampaignsPage;
