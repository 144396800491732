import { Toast } from '@shopify/polaris';
import React, { useContext, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import APIContext from '../providers/APIContext';

export default function useProducts() {
  const { fetchProducts, refreshProducts } = useContext(APIContext);

  const queryClient = useQueryClient();

  const { data: products = [], isLoading } = useQuery(
    'fetchProducts',
    fetchProducts,
  );

  const {
    mutate: refresh,
    isLoading: isRefreshing,
    isSuccess: isRefreshed,
  } = useMutation(() => refreshProducts(), {
    mutationKey: 'refreshProducts',
    onSuccess: () => {
      activateToast();
      setTimeout(() => {
        queryClient.invalidateQueries('fetchProducts');
      }, 1000 * 60 * 3);
    },
  });

  const [toastActive, setToastActive] = useState(true);

  const dismissToast = () => setToastActive(false);

  const activateToast = () => setToastActive(true);

  const refreshToast = (
    <>
      {isRefreshed && toastActive && (
        <Toast
          content="Product library refresh requested. This might take a few minutes."
          onDismiss={dismissToast}
        />
      )}
    </>
  );

  return {
    products,
    isLoading,
    refresh,
    isRefreshing,
    refreshToast,
  };
}
