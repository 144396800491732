import { get, post, patch } from './common';

export async function fetchStore(token) {
  const { data } = await get('api/v2/store/', token);
  return data;
}

export async function patchStore(store, token) {
  const { data } = await patch('api/v2/store/', store, token);
  return data;
}

export async function fetchStoreEmailSettings(token) {
  const { data } = await get('api/v2/store/email-settings/', token);
  return data;
}

export async function patchStoreEmailSettings(settings, token) {
  const { data } = await patch('api/v2/store/email-settings/', settings, token);
  return data;
}

export async function createStoreEmailSettings(settings, token) {
  const { data } = await post(`api/v2/store/email-settings/`, settings, token);
  return data;
}

export async function fetchKlaviyoLists(token) {
  const { data } = await get(
    'api/v2/store/email-settings/klaviyo-lists/',
    token,
  );
  return data;
}

export async function validateDNS(token) {
  const { data } = await post(
    'api/v2/store/email-settings/validate',
    null,
    token,
  );
  return data;
}

export async function refreshProducts(token) {
  const { data } = await post('api/v2/store/refresh-products', null, token);
  return data;
}

export async function checkBilling(token) {
  const { data } = await post('api/v2/store/check-billing', null, token);
  return data;
}
