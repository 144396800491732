import React, { useContext, useState, useCallback, useEffect } from 'react';
import {
  Page,
  Layout,
  Card,
  ResourceList,
  Stack,
  Pagination,
  EmptyState,
} from '@shopify/polaris';
import { ExportMinor } from '@shopify/polaris-icons';

import { useQuery } from 'react-query';

import Recommendation from './Recommendation';
import ExportModal from './ExportModal';
import APIContext from '../../../providers/APIContext';

function CustomersPage() {
  const [offset, setOffset] = useState(0);
  const limit = 10;

  const [enrichedRecommendations, setEnrichedRecommendations] = useState([]);

  const { fetchRecommendations, fetchCampaigns, fetchRecommendationsCounts } =
    useContext(APIContext);

  const { isLoading, data: recommendations = [] } = useQuery(
    ['fetchRecommendations', offset, limit],
    () => fetchRecommendations(null, offset, limit),
  );

  const { data: campaigns = [] } = useQuery('fetchCampaigns', fetchCampaigns);

  const { data: count = [] } = useQuery(
    'fetchRecommendationsCounts',
    fetchRecommendationsCounts,
  );

  useEffect(() => {
    setEnrichedRecommendations(
      recommendations.map((recommendation) => ({
        ...recommendation,
        campaign: campaigns.find(
          (campaign) => campaign.id === recommendation.questionnaire_id,
        )?.name,
      })),
    );
  }, [recommendations, campaigns]);

  const [exportModalOpen, setExportlModalOpen] = useState(false);

  const openExportModal = () => setExportlModalOpen(true);

  const handleExportModalCancel = useCallback(
    () => setExportlModalOpen(false),
    [],
  );

  return (
    <Page
      title="Customers"
      primaryAction={{
        content: 'Export CSV',
        icon: ExportMinor,
        onAction: openExportModal,
      }}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <Card.Section fullWidth>
              <ResourceList
                items={enrichedRecommendations}
                loading={isLoading}
                resourceName={{
                  singular: 'customer',
                  plural: 'customers',
                }}
                showHeader
                totalItemsCount={count[0]?.total}
                renderItem={(recommendation) =>
                  Recommendation({ recommendation })
                }
                emptyState={
                  <EmptyState heading="No recommendations yet">
                    <p>
                      It seems like there have no recommendations been made yet.
                      <br />
                      Create a campaign, test it and your recommendation should
                      appear here.
                    </p>
                  </EmptyState>
                }
              />
            </Card.Section>
            <Card.Section fullWidth>
              <Stack distribution="center">
                <Pagination
                  hasPrevious={offset > 0}
                  onPrevious={() => setOffset((prev) => prev - limit)}
                  hasNext={count[0]?.total > offset + limit}
                  onNext={() => setOffset((prev) => prev + limit)}
                />
              </Stack>
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
      <ExportModal open={exportModalOpen} onClose={handleExportModalCancel} />
    </Page>
  );
}

export default CustomersPage;
