import { postAuth, get, put } from './common';

export async function login(user, password, store = null) {
  const result = await postAuth('api/v2/auth/admin_login', {
    user,
    password,
    store,
  });
  return result;
}

export async function logout() {
  const result = await postAuth('api/v2/auth/logout');
  return result.data;
}

export async function fetchStores(token) {
  const { data } = await get('api/v2/auth/admin_stores', token);
  return data;
}

export async function selectStore(store, token) {
  const { data } = await put(
    'api/v2/auth/admin_stores/selected',
    { store },
    token,
  );
  return data;
}
