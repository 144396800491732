import React from 'react';
import { FormLayout, Card } from '@shopify/polaris';

import ControlledTextField from '../../../common/input/ControlledTextField';

function RecommendationStyling() {
  return (
    <Card sectioned>
      <FormLayout>
        <ControlledTextField
          name="style.variables.--meja-product-title-font-family"
          label="Title Font"
        />

        <ControlledTextField
          name="style.variables.--meja-product-title-font-size"
          label="Title Font (size)"
        />

        <ControlledTextField
          name="style.variables.--meja-product-title-text-transform"
          label="Title Font (Text Transformation)"
        />

        <ControlledTextField
          name="style.variables.--meja-price-font-family"
          label="Subtitle (price in case of products) Font"
        />

        <ControlledTextField
          name="style.variables.--meja-price-font-size"
          label="Subtitle Font Size"
        />
      </FormLayout>
    </Card>
  );
}

export default RecommendationStyling;
