import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './index.css';

//Supress certain warnings
const backup = console.warn;
console.warn = function filterWarnings(msg) {
  const supressedWarnings = [
    '<ResourceList /> renderItem function should return a <ResourceItem />.',
    '<Sheet /> is deprecated.', //Sheet is still used in the react-polaris Filter component
  ];
  if (!supressedWarnings.some((entry) => msg.includes(entry))) {
    backup.apply(console, arguments);
  }
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
