import React, { useCallback } from 'react';
import { Form, FormLayout, Modal, TextContainer } from '@shopify/polaris';
import { FormProvider, useForm } from 'react-hook-form';
import ControlledTextField from '../../common/input/ControlledTextField';
import { imageHelpText } from '../../common/helpText';
import useAnswers from '../../../hooks/useAnswers';

function QuestionCreateModal({ open, onClose }) {
  const form = useForm();

  const close = useCallback(() => {
    onClose();
    form.reset();
  }, [form, onClose]);

  const { create, isCreating } = useAnswers({ onCreationSuccess: close });

  const handleCreate = (question) => create(question);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Add new answer"
      primaryAction={{
        content: 'Add',
        onAction: form.handleSubmit(handleCreate),
        loading: isCreating,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onClose,
        },
      ]}
    >
      <FormProvider {...form}>
        <Modal.Section>
          <Form onSubmit={form.handleSubmit(handleCreate)}>
            <FormLayout>
              <ControlledTextField name="text.answer" label="Answer" />
              <ControlledTextField
                name="assets.image"
                label="Image"
                helpText={imageHelpText}
              />
              <ControlledTextField
                name="klaviyo_tag"
                label="Alias"
                maxLength={50}
                helpText={
                  <TextContainer>
                    <p>
                      The Alias will be used as an identifier in external
                      applications and aggregated statistics.
                    </p>
                  </TextContainer>
                }
              />
            </FormLayout>
          </Form>
        </Modal.Section>
      </FormProvider>
    </Modal>
  );
}

export default QuestionCreateModal;
