import React from 'react';
import { Page, Loading } from '@shopify/polaris';

import { FormProvider } from 'react-hook-form';

import { useParams, useLocation } from 'react-router';
import QuestionLayout from './QuestionLayout';

import useQuestion from '../../../hooks/useQuestion';

export default function QuestionPage() {
  const { id } = useParams();
  const { isLoading, form, saveAction, editMarkup } = useQuestion(id);
  const { pathname: location } = useLocation();
  const url = location.substring(0, location.indexOf('/question/'));

  if (isLoading) return <Loading />;

  return (
    <>
      <FormProvider {...form}>
        <Page
          breadcrumbs={[{ content: 'Questions', url: url }]}
          title="Question"
          primaryAction={saveAction}
        >
          <QuestionLayout />
        </Page>
      </FormProvider>
      {editMarkup}
    </>
  );
}
