import React from 'react';
import Radium from 'radium';
import { useFormContext } from 'react-hook-form';

import { Subheading } from '@shopify/polaris';

import styles from './TextPreview.module.css';

function TextPreview() {
  const { watch } = useFormContext();
  const val = (name) => watch(`style.variables.${name}`);

  return (
    <>
      <Subheading>Desktop</Subheading>
      <div
        className={styles.header}
        style={[
          {
            fontFamily: val('--meja-header-font-family'),
            fontSize: val('--meja-header-font-size'),
            color: val('--meja-color-primary'),
          },
        ]}
      >
        Header
      </div>
      <div
        className={styles.subheader}
        style={[
          {
            fontFamily: val('--meja-subheader-font-family'),
            fontSize: val('--meja-subheader-font-size'),
            color: val('--meja-color-primary'),
          },
        ]}
      >
        Subheader
      </div>
      <div
        className={styles.text}
        style={[
          {
            fontFamily: val('--meja-text-font-family'),
            fontSize: val('--meja-text-font-size'),
            color: val('--meja-color-primary'),
          },
        ]}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </div>

      <Subheading className={styles.mobileSection}>Mobile</Subheading>
      <div
        className={styles.header}
        style={[
          {
            fontFamily: val('--meja-header-font-family'),
            fontSize: val('--meja-header-font-size-mobile'),
            color: val('--meja-color-primary'),
          },
        ]}
      >
        Header
      </div>
      <div
        className={styles.subheader}
        style={[
          {
            fontFamily: val('--meja-subheader-font-family'),
            fontSize: val('--meja-subheader-font-size-mobile'),
            color: val('--meja-color-primary'),
          },
        ]}
      >
        Subheader
      </div>
      <div
        className={styles.text}
        style={[
          {
            fontFamily: val('--meja-text-font-family'),
            fontSize: val('--meja-text-font-size'),
            color: val('--meja-color-primary'),
          },
        ]}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </div>
    </>
  );
}

export default Radium(TextPreview);
