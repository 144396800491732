import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router';

import BrandingPage from '../branding/BrandingPage';
import EmailPage from '../email/EmailPage';
import PagesPage from '../pages/PagesPage';
import IntegrationsPage from '../integrations/IntegrationsPage';
import CampaignOverviewPage from './CampaignOverviewPage';
import QuestionnaireRouter from '../questionnaire/QuestionnaireRouter';

function CampaignPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/branding/:tab?`}>
        <BrandingPage />
      </Route>
      <Route path={`${path}/pages/:tab?`}>
        <PagesPage />
      </Route>
      <Route path={`${path}/integrations/:tab?`}>
        <IntegrationsPage />
      </Route>
      <Route path={`${path}/email`}>
        <EmailPage />
      </Route>
      <Route path={`${path}/questionnaire`}>
        <QuestionnaireRouter />
      </Route>
      <Route>
        <CampaignOverviewPage />
      </Route>
    </Switch>
  );
}

export default CampaignPage;
