import React from 'react';
import Radium from 'radium';
import { useFormContext } from 'react-hook-form';

import { TextStyle, Subheading } from '@shopify/polaris';

import styles from './InputPreview.module.css';

function InputPreview() {
  const { watch } = useFormContext();
  const val = (name) => watch(`style.variables.${name}`);

  return (
    <>
      <Subheading>Text Input</Subheading>
      <TextStyle variation="subdued">
        Click the field to see how it behaves.
      </TextStyle>
      <input
        key="normal"
        className={styles.input}
        type="text"
        placeholder="Placeholder"
        style={[
          {
            fontFamily: val('--meja-input-text-font-family'),
            fontSize: val('--meja-input-text-font-size'),
            borderColor: val('--meja-color-primary'),
            borderWidth: val('--meja-input-text-border-width'),
            padding: val('--meja-input-text-padding'),
            ':focus': {
              borderWidth: val('--meja-input-text-focus-border-width'),
              borderColor: val('--meja-color-primary-accent'),
              padding:
                val('--meja-input-text-padding') -
                val('--meja-input-text-focus-border-width') +
                val('--meja-input-text-border-width'),
            },
          },
        ]}
      />
      <Subheading>Error</Subheading>
      <TextStyle variation="subdued">
        In case of a error such as invalid E-Mail or missing required field.
      </TextStyle>
      <input
        key="error"
        className={styles.input}
        type="text"
        placeholder="Placeholder"
        style={[
          {
            fontFamily: val('--meja-input-text-font-family'),
            fontSize: val('--meja-input-text-font-size'),
            borderColor: val('--meja-color-error'),
            borderWidth: val('--meja-input-text-border-width'),
            padding: val('--meja-input-text-padding'),
            ':focus': {
              borderWidth: val('--meja-input-text-focus-border-width'),
              borderColor: val('--meja-color-primary-accent'),
              padding:
                val('--meja-input-text-padding') -
                val('--meja-input-text-focus-border-width') +
                val('--meja-input-text-border-width'),
            },
          },
        ]}
      />
    </>
  );
}

export default Radium(InputPreview);
