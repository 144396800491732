import React from 'react';
import { TextContainer, Link } from '@shopify/polaris';

const image = (
  <TextContainer>
    Specify a full URL to an image. To upload images, see{' '}
    <Link url="/app/faq#upload-images">FAQ</Link>.
  </TextContainer>
);

export default image;
