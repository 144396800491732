import React from 'react';
import { Layout } from '@shopify/polaris';

import QuestionSection from './QuestionSection';
import AnswersSection from './AnswersSection';

function QuestionLayout() {
  return (
    <Layout>
      <QuestionSection />
      <AnswersSection />
    </Layout>
  );
}

export default QuestionLayout;
