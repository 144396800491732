import React, { useState, useContext } from 'react';
import {
  ResourceItem,
  TextStyle,
  TextContainer,
  Thumbnail,
  Stack,
  Tooltip,
  Icon,
} from '@shopify/polaris';
import { DragHandleMinor, DeleteMinor } from '@shopify/polaris-icons';

import { useRouteMatch } from 'react-router';

import StoreContext from '../../../providers/StoreContext';

import AnswerDeleteModal from './AnswerDeleteModal';
import { Draggable } from 'react-beautiful-dnd';

function AnswersItem({ index, answer, campaign }) {
  const {
    id,
    assets: { image },
    text: { answer: answerText },
  } = answer;
  const { url } = useRouteMatch();
  const [deleteOpen, setDeleteOpen] = useState(false);

  const { isLoading, assetURL } = useContext(StoreContext);

  return (
    <>
      <Draggable draggableId={id.toString()} index={index}>
        {(provided, snapshot) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={
                snapshot.isDragging
                  ? { background: 'white', ...provided.draggableProps.style }
                  : provided.draggableProps.style
              }
            >
              <ResourceItem
                id={id}
                url={`${url}/answer/${id}`}
                media={
                  image ? (
                    <Thumbnail
                      source={
                        isLoading ? '/placeholder_meja.jpg' : assetURL(image)
                      }
                      alt={answerText}
                    />
                  ) : (
                    <Thumbnail
                      source="/placeholder_meja.jpg"
                      alt={answerText}
                    />
                  )
                }
                shortcutActions={[
                  {
                    icon: DeleteMinor,
                    content: 'Delete',
                    destructive: true,
                    onAction: () => setDeleteOpen(true),
                  },
                ]}
              >
                <Stack alignment="center" wrap={false}>
                  <div {...provided.dragHandleProps}>
                    <Tooltip content="Drag and drop to change the default question order">
                      <Icon source={DragHandleMinor} color="subdued" />
                    </Tooltip>
                  </div>
                  <TextContainer>
                    <TextStyle variation="strong">{answerText}</TextStyle>
                  </TextContainer>
                </Stack>
              </ResourceItem>
            </div>
          );
        }}
      </Draggable>

      <AnswerDeleteModal
        answer={answer}
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        campaign={campaign}
      />
    </>
  );
}

export default AnswersItem;
