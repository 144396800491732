import { get, patch, post, del } from './common';

export async function fetchCampaigns(token) {
  const { data } = await get('api/v2/questionnaires/', token);
  return data;
}

export async function fetchCampaign(id, token) {
  const { data } = await get(`api/v2/questionnaires/${id}`, token);
  return data;
}

export async function patchCampaign(id, campaign, token) {
  const { data } = await patch(`api/v2/questionnaires/${id}`, campaign, token);
  return data;
}

export async function createCampaign(campaign, token) {
  const { data } = await post(`api/v2/questionnaires/`, campaign, token);
  return data;
}

export async function deleteCampaign(id, token) {
  const { data } = await del(`api/v2/questionnaires/${id}`, token);
  return data;
}
