import React, { useState, useCallback } from 'react';
import { Button, Layout, TextContainer, Modal } from '@shopify/polaris';
import EmailPreview from './EmailPreview';
import EmailEditing from './EmailEditing';

function EmailLayout({ campaign }) {
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const activator = (
    <Button fullWidth onClick={toggleActive}>
      Preview Email Page
    </Button>
  );

  return (
    <Layout>
      <Layout.AnnotatedSection
        title="Email Page"
        description={
          <>
            <TextContainer>
              <p>
                On the Collect email page, your customer has the option of
                registering with their email address to receive their
                recommendation by email.
              </p>
              <Modal
                large
                activator={activator}
                open={active}
                onClose={toggleActive}
                title="Email Page preview"
              >
                <Modal.Section>
                  <EmailPreview campaign={campaign} />
                </Modal.Section>
              </Modal>
            </TextContainer>
          </>
        }
      >
        <EmailEditing campaign={campaign} />
      </Layout.AnnotatedSection>
    </Layout>
  );
}

export default EmailLayout;
