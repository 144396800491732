import React, { useState, useCallback } from 'react';
import { Button, Layout, TextContainer, Modal } from '@shopify/polaris';
import WelcomePreview from './WelcomePreview';
import WelcomeEditing from './WelcomeEditing';

function WelcomeLayout({ campaign }) {
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const activator = (
    <Button fullWidth onClick={toggleActive}>
      Preview Welcome Page
    </Button>
  );

  return (
    <Layout>
      <Layout.AnnotatedSection
        title="Welcome Page"
        description={
          <>
            <TextContainer>
              <p>
                The welcome page is the first page of your questionnaire. It is
                an opportunity to tell your customer what the questionnaire is
                about. Optionally it can also be skipped i.e. if you prefer
                completely designing your own landing page for the qestionnaire.
              </p>
              <Modal
                large
                activator={activator}
                open={active}
                onClose={toggleActive}
                title="Welcome Page preview"
              >
                <Modal.Section>
                  <WelcomePreview campaign={campaign} />
                </Modal.Section>
              </Modal>
            </TextContainer>
          </>
        }
      >
        <WelcomeEditing campaign={campaign} />
      </Layout.AnnotatedSection>
    </Layout>
  );
}

export default WelcomeLayout;
