import React from 'react';
import { FormLayout, Card, TextContainer } from '@shopify/polaris';

import ControlledTextField from '../../../common/input/ControlledTextField';

export default function QuestionnaireEditing({ campaign }) {
  return (
    <Card sectioned>
      <FormLayout>
        <ControlledTextField
          name="text.question.previous"
          label="Previous button text"
          helpText={
            <TextContainer>
              Text of button for user to navigate back to the previous question.
            </TextContainer>
          }
        />
        <ControlledTextField
          name="text.question.continue"
          label="Next button text"
          helpText={
            <TextContainer>
              Text of button for to go to next question, for example on a
              multiple choice question, given an answer is provided.
            </TextContainer>
          }
        />
        <ControlledTextField
          name="text.question.next"
          label="Skip button text"
          helpText={
            <TextContainer>
              Text of button for user to skip the question, given the question
              is optional.
            </TextContainer>
          }
        />
      </FormLayout>
    </Card>
  );
}
