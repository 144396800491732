import React from 'react';
import { Page, Loading } from '@shopify/polaris';

import useCampaign from '../../../hooks/useCampaign';
import QuestionsLayout from './QuestionsLayout';

export default function QuestionsPage() {
  const { isLoading, campaign } = useCampaign();

  if (isLoading) return <Loading />;

  return (
    <Page title="Questions">
      <QuestionsLayout campaign={campaign} />
    </Page>
  );
}
