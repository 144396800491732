import React, { useContext, useCallback } from 'react';
import { Page, Tabs, Loading } from '@shopify/polaris';
import { useQuery, useMutation } from 'react-query';

import { useForm, FormProvider } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';

import KlaviyoLayout from './klaviyo/KlaviyoLayout';
import GeneralLayout from './general/GeneralLayout';
import SendgridLayout from './sendgrid/SendgridLayout';
import APIContext from '../../../providers/APIContext';

function SettingsPage() {
  const form = useForm();
  const history = useHistory();
  const {
    fetchStore,
    patchStore,
    patchStoreEmailSettings,
    createStoreEmailSettings,
  } = useContext(APIContext);

  const { isLoading, data: store } = useQuery('fetchStore', fetchStore);

  const mutation = useMutation((storeMutation) => {
    patchStore(storeMutation);
    store?.email_settings
      ? patchStoreEmailSettings(storeMutation.email_settings)
      : createStoreEmailSettings(storeMutation.email_settings);
  });
  const saveAndPublish = (store) => mutation.mutate(store);

  const tabs = [
    {
      id: 'general',
      content: 'General',
      layout: <GeneralLayout store={store} />,
    },
    {
      id: 'email',
      content: 'Email',
      layout: <SendgridLayout store={store} />,
    },
    {
      id: 'klaviyo',
      content: 'Klaviyo',
      layout: <KlaviyoLayout store={store} />,
    },
  ];

  const handleTabChange = useCallback(
    (id) => {
      history.push(`/app/settings/${tabs[id].id}`);
    },
    [history, tabs],
  );
  const { tab = 'general' } = useParams();
  const tabID = tabs.findIndex((t) => t.id === tab);

  if (isLoading) return <Loading />;

  return (
    <Page
      title="Settings"
      primaryAction={{
        content: 'Save and Publish',
        onAction: form.handleSubmit(saveAndPublish),
      }}
    >
      <FormProvider {...form}>
        <Tabs tabs={tabs} selected={tabID} onSelect={handleTabChange}>
          {tabs[tabID].layout}
        </Tabs>
      </FormProvider>
    </Page>
  );
}

export default SettingsPage;
