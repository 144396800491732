import React, { useCallback, useContext, useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router';
import { Frame } from '@shopify/polaris';

import AppTopBar from './AppTopBar';
import AppNavigation from './AppNavigation';
import HomePage from './home/HomePage';
import SettingsPage from './settings/SettingsPage';
import CampaignsPage from './campaigns/CampaignsPage';
import CustomersPage from './customers/CustomersPage';
import FAQPage from './faq/FAQPage';
import StoreContext from '../../providers/StoreContext';
import AdminFrame from '../admin/AdminFrame';
import BillingSuccessPage from '../shopify/billing/BillingSuccessPage';

// TODO: Using children instead of component prop is recommended

function AppFrame() {
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive,
      ),
    [],
  );

  // 2-nd level, path = /app
  const { path } = useRouteMatch();

  const { store, isLoading } = useContext(StoreContext) || {};

  return isLoading ? (
    <></>
  ) : store ? (
    <Frame
      topBar={<AppTopBar onNavigationToggle={toggleMobileNavigationActive} />}
      navigation={store && <AppNavigation />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
    >
      <Switch>
        {/* TODO: Replace HomePage with appropriate component (except in $path/) */}
        <Route path={`${path}/projects/:slug?`} component={CampaignsPage} />
        <Route path={`${path}/customers`} component={CustomersPage} />
        <Route path={`${path}/settings/:tab?`} component={SettingsPage} />
        <Route path={`${path}/faq`} component={FAQPage} />
        <Route path={`${path}/billingsuccess`}>
          <BillingSuccessPage />
        </Route>
        <Route path={`${path}/`} component={HomePage} />
        <Route component={HomePage} /> {/* 404 handler */}
      </Switch>
    </Frame>
  ) : (
    <AdminFrame />
  );
}

export default AppFrame;
