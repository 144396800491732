import { useContext } from 'react';
import { useQuery } from 'react-query';

import APIContext from '../providers/APIContext';

export default function useCollections() {
  const { fetchCollections } = useContext(APIContext);

  const { data: collections = [], isLoading } = useQuery(
    'fetchCollections',
    fetchCollections,
  );

  return {
    collections,
    isLoading,
  };
}
