import React from 'react';
import { Layout, Card } from '@shopify/polaris';

import AnswersList from './AnswersList';

function AnswersSection() {
  return (
    <Layout.Section>
      <Card>
        <AnswersList />
      </Card>
    </Layout.Section>
  );
}

export default AnswersSection;
