import { useContext } from 'react';
import { useQuery } from 'react-query';

import APIContext from '../providers/APIContext';

export default function useBilling() {
  const { checkBilling } = useContext(APIContext);
  const { isLoading, data } = useQuery('checkBilling', checkBilling);

  return {
    isLoading,
    billingStatus: data?.billing_status,
    billingRedirectURL: data?.billing_redirect_url,
  };
}
