import React from 'react';
import {
  Card,
  Layout,
  Page,
  Link,
  FooterHelp,
  List,
  TextStyle,
  TextContainer,
} from '@shopify/polaris';

import Question from './Question';

const QUESTIONS = [
  {
    id: 'getting-started',
    question: 'How do I get started?',
    answer: <p></p>,
    video: 'fb9731195c7c4b61861a99cad95aec1f',
  },
  {
    id: 'upload-images',
    question: 'How do I upload images to Shopify?',
    answer: (
      <p>
        You can follow{' '}
        <Link
          external
          url="https://help.shopify.com/en/manual/shopify-admin/productivity-tools/file-uploads"
        >
          this Shopify guide
        </Link>{' '}
        on how to upload any files to Shopify. You can then simply copy the URL
        from your image in{' '}
        <Link external url="https://www.shopify.com/admin/settings/files">
          Files
        </Link>{' '}
        and paste it to the corresponding field in the meja app. We recommend
        compressing the images before uploading them to Shopify.
      </p>
    ),
    video: 'e84c1a88e76b4710a6d1336f2577bbd0',
  },
  {
    id: 'what-is-a-project',
    question: 'What’s a project and how do I create one?',
    answer: <p></p>,
    video: '04e27670ae1145a1836837446631cc7b',
  },
  {
    id: 'what-are-pages',
    question: 'What’s the purpose of the pages and how do I adjust them?',
    answer: <p></p>,
    video: 'b57b4591013348dea5e2ac8301c35d1f',
  },
  {
    id: 'how-to-create-questions',
    question: 'How can I create a question an match the products?',
    answer: <p></p>,
    video: '45645d3c5a75461993644904404ff850',
  },
  {
    id: 'product-selection-options',
    question: 'Can I select entire Collections instead of single Products?',
    answer: <p></p>,
    video: null,
  },
  {
    id: 'look-and-feel',
    question: 'Can I change the look & feel of my projects?',
    answer: <p></p>,
    video: 'c8129003bc5e47c5bb6008518ef74734',
  },
  {
    id: 'recommendations',
    question: 'Where are the collected e-mail addresses displayed?',
    answer: <p></p>,
    video: 'd9ce393ed05d40238b5650c3ff10dd96',
  },
  {
    id: 'klaviyo',
    question: 'How do I connect meja with my Klavyio account?',
    answer: <p></p>,
    video: null,
  },
  {
    id: 'cancel-subscription',
    question: 'How do I cancel my subscription?',
    answer: (
      <TextContainer>
        <p>
          You can cancel your subscription by uninstalling the app from your{' '}
          <Link external url="https://shopify.com/admin/settings/apps">
            Shopify admin app settings
          </Link>
          . To do so, follow the following steps:
        </p>
        <List type="number">
          <List.Item>
            From your Shopify admin, click{' '}
            <TextStyle variation="strong">
              Settings {'>'} Apps and sales channels
            </TextStyle>
            .
          </List.Item>

          <List.Item>
            In the row of the app that you want to uninstall, click{' '}
            <TextStyle variation="strong">Delete</TextStyle>.
          </List.Item>

          <List.Item>
            In the message that appears, click{' '}
            <TextStyle variation="strong">Delete</TextStyle>.
          </List.Item>
        </List>
        <p>
          or check the corresponding{' '}
          <Link
            external
            url="https://help.shopify.com/en/manual/apps/working-with-apps"
          >
            Shopify help article
          </Link>
        </p>
      </TextContainer>
    ),
    video: null,
  },
];

function AnalyticsPage() {
  return (
    <Page title="Frequently Asked Questions">
      <Layout>
        <Layout.Section>
          <Card>
            {QUESTIONS.map((q, k) => (
              <Question {...q} key={k} />
            ))}
          </Card>
        </Layout.Section>
        <Layout.Section>
          <FooterHelp>
            Any other questions? Get in touch at{' '}
            <Link external url="mailto:hello@meja.io">
              hello@meja.io
            </Link>
          </FooterHelp>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default AnalyticsPage;
