import React, { useCallback } from 'react';
import { Form, FormLayout, Modal, TextContainer } from '@shopify/polaris';
import { FormProvider, useForm } from 'react-hook-form';
import ControlledTextField from '../../common/input/ControlledTextField';
import ControlledCheckbox from '../../common/input/ControlledCheckbox';
import useQuestions from '../../../hooks/useQuestions';

function QuestionCreateModal({ open, onClose }) {
  const form = useForm();

  const close = useCallback(() => {
    onClose();
    form.reset();
  }, [form, onClose]);

  const { create, isCreating } = useQuestions({ onCreationSuccess: close });

  const handleCreate = (question) => create(question);

  return (
    <Modal
      open={open}
      onClose={close}
      title="Add new question"
      primaryAction={{
        content: 'Add',
        onAction: form.handleSubmit(handleCreate),
        loading: isCreating,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: close,
          disabled: isCreating,
        },
      ]}
    >
      <FormProvider {...form}>
        <Modal.Section>
          <Form onSubmit={form.handleSubmit(handleCreate)}>
            <FormLayout>
              <ControlledTextField name="text.question" label="Question" />
              <ControlledCheckbox
                name="config.optional"
                label="Optional"
                helpText={
                  <TextContainer>
                    <p>
                      If selected, the customer will have the option to skip the
                      question.
                    </p>
                  </TextContainer>
                }
              />
              <ControlledCheckbox
                name="config.multiple_choice"
                label="Multiple choice"
                helpText={
                  <TextContainer>
                    <p>
                      If selected, the customer will have the option to select
                      multiple answers.
                    </p>
                  </TextContainer>
                }
              />
              <ControlledCheckbox
                name="filter_products"
                label="Filtering"
                helpText={
                  <TextContainer>
                    <p>
                      If selected, answers from this question will restrict the
                      recommended product pool. Use with caution, as this can
                      lead to empty results.
                    </p>
                  </TextContainer>
                }
              />
              <ControlledTextField
                name="klaviyo_alias"
                label="Alias"
                maxLength={50}
                helpText={
                  <TextContainer>
                    <p>
                      The Alias will be used as an identifier in external
                      applications and aggregated statistics.
                    </p>
                  </TextContainer>
                }
              />
            </FormLayout>
          </Form>
        </Modal.Section>
      </FormProvider>
    </Modal>
  );
}

export default QuestionCreateModal;
