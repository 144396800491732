import React from 'react';
import { FormLayout, Card, TextContainer } from '@shopify/polaris';

import { useFormContext } from 'react-hook-form';

import ControlledTextField from '../../../common/input/ControlledTextField';
import ControlledCheckbox from '../../../common/input/ControlledCheckbox';
import { imageHelpText } from '../../../common/helpText';

function WelcomeEditing({ campaign }) {
  const { watch } = useFormContext();
  const watchSkipWelcome = watch('config.welcome.skip');
  let skipWelcome =
    watchSkipWelcome === undefined
      ? campaign.config.welcome.skip
      : watchSkipWelcome;

  return (
    <Card sectioned>
      <FormLayout>
        <ControlledCheckbox
          name="config.welcome.skip"
          label="Skip Welcome page"
          defaultValue={campaign.config.welcome.skip}
        />
        <ControlledTextField
          name="text.welcome.header"
          label="Header"
          defaultValue={campaign.text.welcome.header}
          disabled={skipWelcome}
        />
        <ControlledTextField
          name="text.welcome.subheader"
          label="Subheader"
          defaultValue={campaign.text.welcome.subheader}
          disabled={skipWelcome}
        />
        <ControlledTextField
          name="text.welcome.button"
          label="Button"
          defaultValue={campaign.text.welcome.button}
          disabled={skipWelcome}
        />
        <ControlledTextField
          name="assets.welcome.image"
          label="Banner"
          defaultValue={campaign.assets.welcome.image}
          disabled={skipWelcome}
          helpText={
            <>
              <TextContainer>
                Ideally in a 3:2 ratio. 600 x 400 pixels is sufficient for most
                cases.
              </TextContainer>
              {imageHelpText}
            </>
          }
        />
      </FormLayout>
    </Card>
  );
}

export default WelcomeEditing;
