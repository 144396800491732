import React, { useState, useCallback, useEffect } from 'react';
import {
  ResourceList,
  Filters,
  Card,
  ResourceItem,
  Stack,
  Pagination,
} from '@shopify/polaris';

import { useParams } from 'react-router-dom';
import useAnswer from '../../../hooks/useAnswer';

import useTags from '../../../hooks/useTags';

function TagsList() {
  const { aid: id } = useParams();
  const { selectedTags, replaceTags } = useAnswer(id);

  const { isLoading: isLoadingTags, tags } = useTags();

  const [queryValue, setQueryValue] = useState(null);

  const filteredProducts = tags.filter((tag) =>
    tag.name.toLowerCase().includes(queryValue?.toLowerCase() || ''),
  );

  const handleQueryValueChange = useCallback(
    (value) => setQueryValue(value),
    [],
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);

  const [offset, setOffset] = useState(0);
  const limit = 5;
  useEffect(() => setOffset(0), [queryValue]);

  return (
    <>
      <Card>
        <Card.Section>
          <ResourceList
            loading={isLoadingTags}
            resourceName={{
              singular: 'tag',
              plural: 'tags',
            }}
            items={filteredProducts.slice(offset, offset + limit)}
            totalItemsCount={filteredProducts.length}
            idForItem={(tag) => tag.name}
            renderItem={({ name }) => (
              <ResourceItem key={name} id={name} url={false}>
                <h3>{name}</h3>
              </ResourceItem>
            )}
            selectable
            selectedItems={selectedTags}
            onSelectionChange={replaceTags}
            filterControl={
              <Filters
                filters={[]}
                queryValue={queryValue}
                onQueryChange={handleQueryValueChange}
                onQueryClear={handleQueryValueRemove}
              />
            }
          />
        </Card.Section>
        {filteredProducts.length > limit && (
          <Card.Section>
            <Stack distribution="center">
              <Pagination
                hasPrevious={offset > 0}
                onPrevious={() => setOffset((prev) => prev - limit)}
                hasNext={filteredProducts.length > offset + limit}
                onNext={() => setOffset((prev) => prev + limit)}
              />
            </Stack>
          </Card.Section>
        )}
      </Card>
    </>
  );
}

export default TagsList;
