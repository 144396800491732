import React, { useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import APIContext from './APIContext';

export const StoreContext = React.createContext(null);

export const StoreProvider = ({ children }) => {
  const { fetchStore } = useContext(APIContext);
  const { isLoading, data: store } = useQuery('fetchStore', fetchStore);

  const queryClient = useQueryClient();
  const resetStore = () => queryClient.invalidateQueries('fetchStore');

  const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;
  const assetURL = (asset) =>
    IS_EXTERNAL_LINK_REGEX.test(asset)
      ? asset
      : `${store?.store_assets_url}${asset}`;

  return (
    <StoreContext.Provider value={{ isLoading, store, assetURL, resetStore }}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;
