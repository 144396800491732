import { useContext } from 'react';
import { useQuery } from 'react-query';

import APIContext from '../providers/APIContext';

export default function useTags() {
  const { fetchTags } = useContext(APIContext);

  const { data: tags = [], isLoading } = useQuery('fetchTags', fetchTags);

  return {
    tags,
    isLoading,
  };
}
