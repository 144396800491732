import React from 'react';
import { Navigation, Loading } from '@shopify/polaris';
import {
  IllustrationMajor,
  EmailNewsletterMajor,
  ImageWithTextMajor,
  TextBlockMajor,
  QuestionMarkMajor,
  ChannelsMajor,
} from '@shopify/polaris-icons';

import useCampaign from '../../hooks/useCampaign';

export default function CampaignNavigation() {
  const { campaign, isLoading } = useCampaign();

  if (isLoading) return <Loading />;

  return (
    <Navigation.Section
      items={[
        {
          disabled: true,
          label: campaign.name || 'Project',
        },
        {
          url: `/app/projects/${campaign.slug}`,
          label: 'Overview',
          icon: ImageWithTextMajor,
          exactMatch: true,
        },
        {
          url: `/app/projects/${campaign.slug}/pages`,
          label: 'Pages',
          icon: TextBlockMajor,
        },
        {
          url: `/app/projects/${campaign.slug}/questionnaire`,
          label: 'Questionnaire',
          icon: QuestionMarkMajor,
        },
        {
          url: `/app/projects/${campaign.slug}/branding`,
          label: 'Branding',
          icon: IllustrationMajor,
        },
        {
          url: `/app/projects/${campaign.slug}/email`,
          label: 'E-Mail',
          icon: EmailNewsletterMajor,
        },
        {
          url: `/app/projects/${campaign.slug}/integrations`,
          label: 'Integrations',
          icon: ChannelsMajor,
        },
      ]}
    />
  );
}
