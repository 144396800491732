import React from 'react';
import { Layout, TextContainer, Card, FormLayout } from '@shopify/polaris';

import { useFormContext } from 'react-hook-form';

import ControlledCheckbox from '../../../common/input/ControlledCheckbox';
import ControlledTextField from '../../../common/input/ControlledTextField';

function KlaviyoLayout({ store }) {
  const { watch } = useFormContext();
  const watchEnableKlaviyo = watch('enable_klaviyo');
  const klaviyoDisabled =
    watchEnableKlaviyo === undefined
      ? !store.enable_klaviyo
      : !watchEnableKlaviyo;

  return (
    <Layout>
      <Layout.AnnotatedSection
        title="Klaviyo"
        description={
          <TextContainer>
            Setup Klaviyo integration for your store.
          </TextContainer>
        }
      >
        <Card sectioned>
          <FormLayout>
            <ControlledCheckbox
              name="enable_klaviyo"
              label="Enable Klavyio Integration"
              defaultValue={store.enable_klaviyo}
            />
            <ControlledTextField
              name="klaviyo_public_token"
              label="Klaviyo Public Key"
              defaultValue={store.klaviyo_public_token}
              disabled={klaviyoDisabled}
            />
            <ControlledTextField
              name="klaviyo_private_token"
              label="Klaviyo Private Key"
              defaultValue={store.klaviyo_private_token}
              disabled={klaviyoDisabled}
            />
          </FormLayout>
        </Card>
      </Layout.AnnotatedSection>
    </Layout>
  );
}

export default KlaviyoLayout;
