import { post } from './common';

export async function postPreviewEmail(id, emailPreview, token) {
  const { data } = await post(
    `api/v2/questionnaires/${id}/preview-email`,
    emailPreview,
    token,
  );
  return data;
}
