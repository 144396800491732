import React from 'react';
import { FormLayout, Card, TextContainer } from '@shopify/polaris';

import ControlledColorSelection from '../../../common/input/ControlledColorSelection';

function ColorsStyling() {
  return (
    <Card sectioned>
      <FormLayout>
        <ControlledColorSelection
          name="style.variables.--meja-color-primary"
          label="Primary color"
          helpText={
            <TextContainer>
              Used for text and button background color
            </TextContainer>
          }
        />
        <ControlledColorSelection
          name="style.variables.--meja-color-secondary"
          label="Secondary color"
          helpText={
            <TextContainer>
              Used for buttons border color and text on buttons
            </TextContainer>
          }
        />
        <ControlledColorSelection
          name="style.variables.--meja-color-primary-accent"
          label="Primary accent color"
          helpText={
            <TextContainer>
              Used for highlighted borders on selected input fields
            </TextContainer>
          }
        />
        <ControlledColorSelection
          name="style.variables.--meja-color-secondary-accent"
          label="Secondary accent color"
          helpText={
            <TextContainer>
              Used for highlighted borders on answer tiles
            </TextContainer>
          }
        />
        <ControlledColorSelection
          name="style.variables.--meja-color-primary-subdued"
          label="Primary subdued color"
          helpText={<TextContainer>Used for subdued text</TextContainer>}
        />
        <ControlledColorSelection
          name="style.variables.--meja-color-secondary-subdued"
          label="Secondary subdued color"
          helpText={
            <TextContainer>
              Used as background color for certain hover effects
            </TextContainer>
          }
        />
        <ControlledColorSelection
          name="style.variables.--meja-color-error"
          label="Error color"
          helpText={<p>Will be used in form validation i.e. as text color</p>}
        />
      </FormLayout>
    </Card>
  );
}

export default ColorsStyling;
