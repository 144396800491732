import React, { useCallback, useState } from 'react';
import { ResourceList, Button, EmptyState, Link } from '@shopify/polaris';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import QuestionsItem from './QuestionsItem';
import QuestionCreateModal from './QuestionCreateModal';
import useQuestions from '../../../hooks/useQuestions';

export default function QuestionsList() {
  const { questions, isLoading, move, isMoving } = useQuestions({});

  const [createOpen, setCreateOpen] = useState(false);

  const onCreate = useCallback(() => setCreateOpen(true), []);

  return (
    <>
      <DragDropContext
        onDragEnd={({ source, destination }) =>
          move(source.index, destination.index)
        }
      >
        <Droppable droppableId="questions">
          {(provided) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <ResourceList
                  loading={isLoading || isMoving}
                  resourceName={{ singular: 'question', plural: 'questions' }}
                  items={questions}
                  renderItem={(question, id, index) => (
                    <QuestionsItem key={id} index={index} question={question} />
                  )}
                  alternateTool={
                    <Button onClick={onCreate}>Add new question</Button>
                  }
                  emptyState={
                    <EmptyState
                      heading="Add a first question to your questionnaire"
                      action={{
                        content: 'Add new question',
                        onAction: () => setCreateOpen(true),
                      }}
                    >
                      <div
                        style={{
                          position: 'relative',
                          paddingBottom: '54.13533834586466%',
                          height: 0,
                        }}
                      >
                        <iframe
                          title="How to create questions video"
                          src="https://www.loom.com/embed/45645d3c5a75461993644904404ff850?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                          frameBorder="0"
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          allowFullScreen
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                          }}
                        ></iframe>
                      </div>
                      <p>
                        Watch the video to see our tipps on how to formulate
                        questions for the best results. If you need further
                        support, drop us a line:{' '}
                        <Link external url="mailto:hello@meja.io">
                          hello@meja.io
                        </Link>
                      </p>
                    </EmptyState>
                  }
                />

                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      <QuestionCreateModal
        open={createOpen}
        onClose={() => setCreateOpen(false)}
      />
    </>
  );
}
