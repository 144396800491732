import React, { useCallback } from 'react';
import { Loading, Page, Tabs } from '@shopify/polaris';

import { FormProvider } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';

import ColorsLayout from './colors/ColorsLayout';
import TextLayout from './text/TextLayout';
import ButtonLayout from './button/ButtonLayout';
import InputLayout from './input/InputLayout';
import RecommendationLayout from './recommendation/RecommendationLayout';

import useCampaign from '../../../hooks/useCampaign';

function BrandingPage() {
  const history = useHistory();
  const { campaign, isLoading, form, editMarkup, saveAction } = useCampaign();

  const tabs = [
    {
      id: 'colors',
      content: 'Colors',
      layout: <ColorsLayout />,
    },
    {
      id: 'text',
      content: 'Text',
      layout: <TextLayout />,
    },
    {
      id: 'input',
      content: 'Input',
      layout: <InputLayout />,
    },
    {
      id: 'button',
      content: 'Button',
      layout: <ButtonLayout />,
    },
    {
      id: 'recommendation',
      content: 'Recommendation',
      layout: <RecommendationLayout />,
    },
  ];

  const handleTabChange = useCallback(
    (id) =>
      history.push(`/app/projects/${campaign?.slug}/branding/${tabs[id].id}`),
    [campaign, history, tabs],
  );
  const { tab = 'colors' } = useParams();
  const tabID = tabs.findIndex((t) => t.id === tab);

  if (isLoading) return <Loading />;

  return (
    <Page title="Branding Studio" primaryAction={saveAction}>
      <FormProvider {...form}>
        <Tabs tabs={tabs} selected={tabID} onSelect={handleTabChange}>
          {tabs[tabID].layout}
        </Tabs>
      </FormProvider>
      {editMarkup}
    </Page>
  );
}

export default BrandingPage;
