import React from 'react';
import Radium from 'radium';
import { useFormContext } from 'react-hook-form';

import { TextStyle, Subheading } from '@shopify/polaris';

import styles from './ButtonPreview.module.css';

function ButtonPreview() {
  const { watch } = useFormContext();
  const val = (name) => watch(`style.variables.${name}`);
  return (
    <>
      <Subheading>Preview</Subheading>
      <TextStyle variation="subdued">
        Hover over the button to check the :hover style.
      </TextStyle>
      <input
        className={styles['preview-button']}
        type="button"
        style={[
          {
            fontFamily: val('--meja-button-font-family'),
            fontSize: val('--meja-button-font-size'),
            padding: val('--meja-button-padding'),
            fontWeight: val('--meja-button-font-weight'),
            textTransform: val('--meja-button-text-transform'),
            borderWidth: val('--meja-button-border-width'),
            backgroundColor: val('--meja-color-primary'),
            borderColor: val('--meja-color-primary'),
            color: val('--meja-color-secondary'),
            ':hover': {
              backgroundColor: val('--meja-color-secondary'),
              borderColor: val('--meja-color-primary'),
              color: val('--meja-color-primary'),
            },
          },
        ]}
        value="Let's Go"
      />
    </>
  );
}

export default Radium(ButtonPreview);
