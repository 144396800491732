import React, { useContext } from 'react';
import {
  Layout,
  CalloutCard,
  List,
  Link,
  TextStyle,
  Stack,
} from '@shopify/polaris';

import { useMutation, useQuery, useQueryClient } from 'react-query';

import APIContext from '../../../../providers/APIContext';
import StoreContext from '../../../../providers/StoreContext';

function SetupSection() {
  const { fetchCampaigns, patchStore } = useContext(APIContext);

  const { store, isLoading } = useContext(StoreContext);

  const { data: campaigns = [] } = useQuery('fetchCampaigns', fetchCampaigns);
  const queryClient = useQueryClient();
  const storeMutation = useMutation(
    () => patchStore({ onboarding_complete: true }),
    {
      onSuccess: () => queryClient.invalidateQueries(['fetchStore']),
    },
  );
  const setOnboardingComplete = () => storeMutation.mutate();

  if (isLoading || store.onboarding_complete) return <></>;

  const campaign = campaigns[0];

  const content =
    campaigns.length === 0 ? (
      <List type="number">
        <List.Item>
          Create a <Link url="/app/projects">Project</Link>
        </List.Item>
        <List.Item>
          <TextStyle variation="subdued">
            Add a question to your project's questionnaire
          </TextStyle>
        </List.Item>
        <List.Item>
          <TextStyle variation="subdued">
            Add answers to your questions
          </TextStyle>
        </List.Item>
        <List.Item>
          <TextStyle variation="subdued">
            Select which products match the answers
          </TextStyle>
        </List.Item>
        <List.Item>
          <TextStyle variation="subdued">Edit the Pages</TextStyle>
        </List.Item>
        <List.Item>
          <TextStyle variation="subdued">
            Customize your project's Branding
          </TextStyle>
        </List.Item>
        <List.Item>
          <TextStyle variation="subdued">
            Customize the recommendation E-Mail
          </TextStyle>
        </List.Item>
        <List.Item>
          Setup custom <Link url="/app/settings/email">E-Mail</Link> address
        </List.Item>
        <List.Item>
          Setup <Link url="/app/settings/klaviyo">Klaviyo</Link> integration
        </List.Item>
        <List.Item>
          Check out your <Link url="/app/customers">Customers</Link>
        </List.Item>
      </List>
    ) : (
      <List type="number">
        <List.Item>
          <TextStyle variation="subdued">
            Create a <Link url="/app/projects">Project</Link>
          </TextStyle>
        </List.Item>
        <List.Item>
          Add a question to your project's{' '}
          <Link url={`/app/projects/${campaign.slug}/questionnaire`}>
            questionnaire
          </Link>
        </List.Item>
        <List.Item>
          Add answers to your{' '}
          <Link url={`/app/projects/${campaign.slug}/questionnaire`}>
            questions
          </Link>
        </List.Item>
        <List.Item>
          Select which products match the{' '}
          <Link url={`/app/projects/${campaign.slug}/questionnaire`}>
            answers
          </Link>
        </List.Item>
        <List.Item>
          Edit the{' '}
          <Link url={`/app/projects/${campaign.slug}/pages`}>Pages</Link>
        </List.Item>
        <List.Item>
          Customize your project's{' '}
          <Link url={`/app/projects/${campaign.slug}/branding`}>Branding</Link>
        </List.Item>
        <List.Item>
          Customize the recommendation{' '}
          <Link url={`/app/projects/${campaign.slug}/email`}>E-Mail</Link>
        </List.Item>
        <List.Item>
          Setup custom <Link url="/app/settings/email">E-Mail</Link> address
        </List.Item>
        <List.Item>
          Setup <Link url="/app/settings/klaviyo">Klaviyo</Link> integration
        </List.Item>
        <List.Item>
          Check out your <Link url="/app/customers">Customers</Link>
        </List.Item>
      </List>
    );

  return (
    <Layout.Section>
      <CalloutCard
        title="Get Started With Your First Meja Project"
        primaryAction={{
          content: "Don't show again",
          onAction: setOnboardingComplete,
        }}
      >
        <Stack>
          <Stack.Item fill>{content}</Stack.Item>

          <Stack.Item>
            <div
              style={{
                position: 'relative',
                paddingBottom: '54.13533834586466%',
                height: 0,
                width: '400px',
              }}
            >
              <iframe
                title={`Getting started video`}
                src={`https://www.loom.com/embed/fb9731195c7c4b61861a99cad95aec1f?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              ></iframe>
            </div>
          </Stack.Item>
        </Stack>
      </CalloutCard>
    </Layout.Section>
  );
}

export default SetupSection;
