import React from 'react';
import { FormLayout, Card, Checkbox, TextContainer } from '@shopify/polaris';

import { useFormContext } from 'react-hook-form';

import ControlledTextField from '../../../common/input/ControlledTextField';
import ControlledCheckbox from '../../../common/input/ControlledCheckbox';
import { imageHelpText } from '../../../common/helpText';

function EmailEditing({ campaign }) {
  const { watch } = useFormContext();
  const watchAskForName = watch('config.email.ask_name');
  let askForName =
    watchAskForName === undefined
      ? campaign.config.email.ask_name
      : watchAskForName;
  const watchNameRequired = watch('config.email.name_required');
  let nameRequired =
    watchNameRequired === undefined
      ? campaign.config.email.name_required
      : watchNameRequired;
  const watchEmailRequired = watch('config.email.email_required');
  let emailRequired =
    watchEmailRequired === undefined
      ? campaign.config.email.email_required
      : watchEmailRequired;
  const watchContinueWithoutEmail = watch(
    'config.email.continue_without_email',
  );
  let continueWithoutEmail =
    watchContinueWithoutEmail === undefined
      ? campaign.config.email.continue_without_email
      : watchContinueWithoutEmail;

  return (
    <Card sectioned>
      <FormLayout>
        <Checkbox label="Skip Email Page" checked={false} disabled={true} />
        <ControlledTextField
          name="text.email.header"
          label="Header"
          defaultValue={campaign.text.email.header}
        />
        <ControlledTextField
          name="text.email.subheader"
          label="Subheader"
          defaultValue={campaign.text.email.subheader}
          multiline={4}
        />
        <ControlledTextField
          name="assets.email.image"
          label="Banner"
          defaultValue={campaign.assets.email.image}
          helpText={
            <>
              <TextContainer>
                Ideally in a 3:2 ratio. 600 x 400 pixels is sufficient for most
                cases.
              </TextContainer>
              {imageHelpText}
            </>
          }
        />
        <FormLayout.Group>
          <ControlledCheckbox
            name="config.email.ask_name"
            label="Ask for name"
            defaultValue={campaign.config.email.ask_name}
          />
          <ControlledCheckbox
            name="config.email.name_required"
            label="Require name"
            defaultValue={campaign.config.email.name_required}
            disabled={!askForName}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <ControlledTextField
            name="text.email.name_placeholder"
            label="Name placeholder"
            defaultValue={campaign.text.email.name_placeholder}
            disabled={!askForName}
          />
          <ControlledTextField
            name="text.email.name_error"
            label="Name error message"
            defaultValue={campaign.text.email.name_error}
            disabled={!askForName || !nameRequired}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <Checkbox label="Ask for email" checked={true} disabled={true} />
          <ControlledCheckbox
            name="config.email.email_required"
            label="Require valid email"
            defaultValue={campaign.config.email.email_required}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <ControlledTextField
            name="text.email.email_placeholder"
            label="Email placeholder"
            defaultValue={campaign.text.email.email_placeholder}
          />
          <ControlledTextField
            name="text.email.email_error"
            label="Email error message"
            defaultValue={campaign.text.email.email_error}
            disabled={!emailRequired}
          />
        </FormLayout.Group>
        <ControlledTextField
          name="text.email.confirm_button_text"
          label="Confirmation button text"
          defaultValue={campaign.text.email.confirm_button_text}
        />
        <ControlledCheckbox
          name="config.email.continue_without_email"
          label="Show a 'continue without e-mail' link"
          defaultValue={campaign.config.email.continue_without_email}
        />
        <ControlledTextField
          name="text.email.continue_without_email_text"
          label="'continue without e-mail' link text"
          defaultValue={campaign.text.email.continue_without_email_text}
          disabled={!continueWithoutEmail}
        />
      </FormLayout>
    </Card>
  );
}

export default EmailEditing;
