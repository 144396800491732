import React from 'react';
import {
  Page,
  Layout,
  Card,
  Heading,
  FooterHelp,
  Link,
  TextContainer,
  List,
  TextStyle,
} from '@shopify/polaris';

function PrivacyPage() {
  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Card title="Privacy policy" sectioned>
            <Card.Section>
              <TextContainer>
                <Heading element="h2">I. Processor Details</Heading>
                <p>
                  Meja is the responsible party for the processing of personal
                  data collected through your use of our website
                  https://meja.io/ as per applicable data protection laws, in
                  particular the Swiss Data Protection Act (DPA) and – if
                  applicable to your personal data – the European General Data
                  Protection Regulation (GDPR).
                </p>
                <p>
                  We have our registered domicile at Friedbergstrasse 3a, 9240
                  Uzwil, Switzerland. For any queries regarding your personal
                  data, contact us at the following address: hello@meja.io
                </p>
              </TextContainer>
            </Card.Section>

            <Card.Section>
              <TextContainer>
                <Heading element="h2">
                  II. How we process your personal data
                </Heading>
                <Heading element="h3">1. Scope</Heading>
                <p>
                  We only process your personal data if this is necessary to
                  provide a functional website or to provide you with our
                  contents and services. The processing of personal data only
                  takes place based on the appropriate legal basis and as
                  permitted by law.
                </p>
                <Heading element="h3">
                  2. Legal Basis for Processing under GDPR
                </Heading>
                <p>
                  Insofar as we are required to obtain the consent of the data
                  subject for the processing of personal data, we will obtain
                  your prior consent and Art. 6 para. 1 lit. a GDPR serves as
                  the legal basis for the processing of the corresponding data.
                </p>
                <p>
                  If the processing of personal data required for the
                  performance of a contract to which you are a party, Art. 6
                  para. 1 lit. b GDPR serves as the legal basis. This also
                  applies to processing operations that are necessary to carry
                  out pre-contractual services or measures.
                </p>
                <p>
                  Insofar as the processing of personal data is required to
                  fulfil a legal obligation to which our company is subject,
                  Art. 6 para. 1 lit. c GDPR serves as the legal basis.
                </p>
                <p>
                  In the event that the vital interests of the data subject or
                  another natural person require the processing of personal
                  data, Article 6 para 1lit. d GDPR serves as the legal basis.
                </p>
                <p>
                  If processing is necessary to safeguard a legitimate interest
                  of our company or a third party and if the interests,
                  fundamental rights and freedoms of the data subject do not
                  outweigh the first-mentioned interest, Art. 6 para. 1 lit. f
                  GDRP serves as the legal basis.
                </p>
                <Heading element="h3">3. Duration of Processing</Heading>
                <p>
                  We only store your personal data for as long as necessary to
                  serve the purpose of the processing and we delete personal
                  data or block access to it as soon as such purpose ceases to
                  apply.
                </p>
                <p>
                  Furthermore, personal data may be stored if this has been
                  provided for by the applicable law (for example for book
                  keeping or mandatory archiving purposes). The data will also
                  be blocked or deleted if a storage period prescribed by the
                  applicable law expires, unless there is a need for further
                  storage of the data for the conclusion or fulfilment of a
                  contract.
                </p>
              </TextContainer>
            </Card.Section>

            <Card.Section>
              <TextContainer>
                <Heading element="h2">III. Website Access and Logfiles</Heading>
                <Heading element="h3">1. Automated Data Processing</Heading>
                <p>
                  Every time you visit our website, our system automatically
                  collects data and information about the computer system you
                  used to access our website.
                </p>
                <p>The following data is collected:</p>
                <List type="bullet">
                  <List.Item>Browser information (type and version)</List.Item>
                  <List.Item>Operating system</List.Item>
                  <List.Item>Your internet service provider</List.Item>
                  <List.Item>Your IP address</List.Item>
                  <List.Item>Date and time of access</List.Item>
                  <List.Item>
                    Websites from which your system reaches our website
                  </List.Item>
                  <List.Item>
                    Websites accessed by the user’s system via our website
                  </List.Item>
                </List>
                <p>
                  The data is also stored in the log files of our system. This
                  data is not stored together with your other personal data.
                </p>
                <p>
                  For data processing subject to GDPR, the legal basis for the
                  temporary storage of data and log files is Art. 6 para. 1 lit.
                  f GDPR.
                </p>
                <Heading element="h3">
                  2. Cookies, tracking and technologies relating to the use of
                  our website
                </Heading>
                <p>
                  We use cookies on our site. Cookies are small files that your
                  browser automatically creates and that are stored on your
                  device (laptop, tablet, smartphone, etc.) when you visit our
                  site. Information is stored in the cookie that results in each
                  case in connection with the specifically used terminal device.
                  However, this does not mean that we immediately become aware
                  of your identity. The use of cookies primarily serves the
                  purpose of making your use of our websites more pleasant for
                  you. For example, we use so-called session cookies to
                  recognize that you have already visited individual pages of
                  our website.
                </p>
                <p>
                  In addition, we also use temporary cookies that are stored on
                  your end device for a specified period of time to optimize
                  user-friendliness. If you visit our site again to use our
                  services, it will automatically recognize that you have
                  already been with us and what entries and settings you have
                  made so that you do not have to enter them again.
                </p>
                <p>
                  Furthermore, we use cookies to statistically record the use of
                  our website and to evaluate it for you for optimising our
                  websites. These cookies enable us to automatically recognize
                  when you return to our site that you have already been with
                  us. These cookies are automatically deleted after a defined
                  period of time.
                </p>
                <p>
                  Within the scope of the GDPR, the data processed by cookies
                  for the aforementioned purposes is justified in order to
                  protect our legitimate interests and those of third parties
                  pursuant to Art. 6 para. 1 sentence 1 letter f GDPR.
                </p>
                <p>
                  Most browsers automatically accept cookies. However, you can
                  configure your browser so that no cookies are stored on your
                  computer or a message always appears before a new cookie is
                  created. However, the complete deactivation of cookies can
                  lead to the fact that you cannot use all functions of our
                  website.
                </p>
                <p>
                  We use Google Analytics on our website. These are services
                  services provided by third parties, which may be located in
                  any country (in the case of Google Analytics, Google LLC is
                  based in the US but Google Ireland Ltd. is the data controller
                  for customers based in the EU and Switzerland, for more
                  information visit https://policies.google.com/?hl=en) and
                  allow us to measure and evaluate the use of our website
                  (without identifying individuals). Permanent cookies, which
                  are placed by the service provider, are also used for this
                  purpose. Although such service providers do not receive
                  personal data from us (and do not retain any IP addresses),
                  they may track your use of the website, combine this
                  information with data from other websites you have visited,
                  which are also tracked by service providers, and use this
                  information for their own purposes (e.g. to manage
                  advertising). If you have registered with the service provider
                  concerned, the service provider will also know your identity.
                  The service provider concerned will then be responsible for
                  processing your personal data in accordance with the
                  applicable data protection provisions. Service providers only
                  provide information on how a particular website is used (but
                  not any personal details).
                </p>
                <p>
                  We also use plugins from social networks such as Facebook,
                  Twitter, YouTube, Google+, LinkedIn, Xing or Instagram on our
                  websites. This will be evident to you, as the relevant symbol
                  will typically be displayed. We have configured these elements
                  to be disabled by default. If you enable these (by clicking on
                  them), the social network operators may register that you are
                  on our website and where you are on our website, and may use
                  this information for their own purposes. The operator
                  concerned will then be responsible for processing your
                  personal data in accordance with the applicable data
                  protection provisions. We will not receive any information
                  regarding you from the operator concerned.
                </p>
                <Heading element="h3">3. Purpose of Processing</Heading>
                <p>
                  The data is stored in log files to ensure the functionality of
                  the website. In addition, the data serves us to optimize the
                  website and to ensure the security of our information
                  technology systems. An evaluation of the data for marketing
                  purposes does not take place in this context.
                </p>
                <p>
                  For data processing subject to GDPR, these purposes are our
                  legitimate interest pursuant to Art. 6 para. 1 lit. f GDPR.
                </p>
                <Heading element="h3">4. Duration of Processing</Heading>
                <p>
                  The data will be deleted as soon as they are no longer
                  necessary to achieve the purpose for which they were
                  collected. In the case of the collection of data for the
                  provision of the website, this is the case when the respective
                  session has ended. If the data is stored in log files,
                  deletion occurs after seven days at the latest. Further
                  storage is possible. In this case, the IP addresses of the
                  users are deleted or anonymized.
                </p>
                <Heading element="h3">5. No Objection</Heading>
                <p>
                  The collection of data for the provision of the website and
                  the storage of data in log files is absolutely necessary for
                  the operation of the website. Consequently, there is no
                  possibility of objection on your part.
                </p>
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <TextContainer>
                <Heading element="h2">IV. Your Rights</Heading>

                <p>
                  You have the following rights under the applicable data
                  protection laws:
                </p>
                <Heading element="h3">1. Right of Information</Heading>
                <p>
                  You can request us to confirm whether personal data concerning
                  you is being processed by us
                </p>
                <p>
                  If such processing has taken place, you can request the
                  following information from us:
                </p>
                <List type="bullet">
                  <List.Item>
                    The purposes for which the personal data are processed;
                  </List.Item>
                  <List.Item>
                    the categories of personal data being processed;
                  </List.Item>
                  <List.Item>
                    the recipients or categories of recipients to whom the
                    personal data concerning you have been or are still being
                    disclosed;
                  </List.Item>
                  <List.Item>
                    the planned duration of the storage of the personal data
                    concerning you or, if specific information on this is not
                    possible, criteria for determining the storage period;
                  </List.Item>
                  <List.Item>
                    the existence of a right to rectification or deletion of
                    personal data concerning you, a right to limitation of
                    processing by the controller or a right to object to such
                    processing;
                  </List.Item>
                  <List.Item>
                    the existence of a right of appeal to a supervisory
                    authority;
                  </List.Item>
                  <List.Item>
                    any available information on the origin of the data if the
                    personal data are not collected from the data subject;
                  </List.Item>
                  <List.Item>
                    if the processing is subject to GDPR, the existence of
                    automated decision-making including profiling in accordance
                    with Art. 22 para. 1 and 4 GDPR and – at least in these
                    cases – meaningful information on the logic involved and the
                    scope and intended effects of such processing for the data
                    subject.
                  </List.Item>
                </List>
                <p>
                  You have the right to request information as to whether the
                  personal data concerning you is transferred to a third country
                  or to an international organisation. In this context, you may
                  request to be informed of the appropriate guarantees in
                  connection with the transmission.
                </p>
                <Heading element="h3">2. Right to Rectification</Heading>
                <p>
                  You have a right of rectification and/or completion if the
                  personal data processed concerning you are incorrect or
                  incomplete. We shall make the correction without delay.
                </p>
                <Heading element="h3">3. Right of Restriction</Heading>
                <p>
                  Under the following conditions, you may request that the
                  processing of your personal data be restricted:
                </p>
                <List type="bullet">
                  <List.Item>
                    if you dispute the accuracy of the personal data concerning
                    you for a period that enables us to verify the accuracy of
                    the personal data;
                  </List.Item>
                  <List.Item>
                    the processing is unlawful and you refuse to delete the
                    personal data and instead request that the use of the
                    personal data be restricted;
                  </List.Item>
                  <List.Item>
                    we no longer need the personal data for the purposes of the
                    processing, but you do need them to assert, exercise or
                    defend legal claims; or
                  </List.Item>
                  <List.Item>
                    if you have filed an objection to the processing pursuant to
                    Art. 21 para. 1 GDPR and it has not yet been determined
                    whether the legitimate reasons of the person responsible
                    outweigh your reasons.
                  </List.Item>
                </List>
                <p>
                  If the processing of personal data concerning you has been
                  restricted, such data may only be processed – apart from being
                  stored – with your consent or for the purpose of asserting,
                  exercising or defending rights or protecting the rights of
                  another natural or legal person or on grounds of an important
                  public interest.
                </p>
                <Heading element="h3">4. Right to Deletion</Heading>
                <Heading element="h4">
                  a) Obligation to Delete Personal Data
                </Heading>
                <p>
                  You may request us to delete the personal data relating to you
                  without delay and we are obliged to delete this data without
                  delay if one of the following reasons applies:
                </p>
                <List type="bullet">
                  <List.Item>
                    The personal data concerning you are no longer necessary for
                    the purposes for which they were collected or otherwise
                    processed.
                  </List.Item>
                  <List.Item>
                    You revoke your consent, on which the processing was based
                    pursuant to Art. 6 para. 1 lit. a or Art. 9 para. 2 lit. a
                    GDPR, and there is no other legal basis for the processing.
                  </List.Item>
                  <List.Item>
                    You file an objection against the processing pursuant to
                    Art. 21 para. 1 GDPR and there are no overriding legitimate
                    reasons for the processing, or you file an objection against
                    the processing pursuant to Art. 21 para. 2 GDPR.
                  </List.Item>
                  <List.Item>
                    The personal data concerning you have been processed
                    unlawfully.
                  </List.Item>
                  <List.Item>
                    The deletion of personal data relating to you is necessary
                    to fulfil a legal obligation to which we are subject.
                  </List.Item>
                  <List.Item>
                    The personal data concerning you were collected in relation
                    to information society services offered pursuant to Art. 8
                    para. 1 GDPR.
                  </List.Item>
                </List>
                <p>
                  If we have made your personal data public and are obliged to
                  delete it pursuant to Art. 17 para. 1 GDPR, we shall take
                  appropriate measures, including technical measures, taking
                  into account the available technology and the implementation
                  costs, to inform those responsible for data processing who
                  process the personal data that you as the data subject have
                  requested the deletion of all links to this personal data or
                  of copies or replications of this personal data.
                </p>
                <Heading element="h4">b) Exceptions</Heading>
                <p>
                  The right to deletion does not exist insofar as the processing
                  is necessary
                </p>
                <List type="bullet">
                  <List.Item>
                    to exercise freedom of expression and information;
                  </List.Item>
                  <List.Item>
                    for the performance of a legal obligation required for
                    processing under the applicable law or for the performance
                    of a task in the public interest or in the exercise of
                    official authority conferred on the controller;
                  </List.Item>
                  <List.Item>
                    for reasons of public interest in the field of public health
                    pursuant to Art. 9 para. 2 lit. h and i and Art. 9 para. 3
                    GDPR;
                  </List.Item>
                  <List.Item>
                    for archiving purposes in the public interest, scientific or
                    historical research purposes or for statistical purposes
                    pursuant to Art. 89 para. 1 GDPR, insofar as the right
                    mentioned under a) is likely to make it impossible or
                    seriously impair the attainment of the objectives of such
                    processing, or
                  </List.Item>
                  <List.Item>
                    to assert, exercise or defend legal claims.
                  </List.Item>
                </List>
                <Heading element="h3">5. Right to Notification</Heading>
                <p>
                  If you have exercised your right to have us correct, delete or
                  limit the processing, we are obliged to inform all recipients
                  to whom the personal data concerning you have been disclosed
                  of this correction or deletion of the data or restriction on
                  processing, unless this proves impossible or involves a
                  disproportionate effort.
                </p>
                <Heading element="h3">6. Data Portability</Heading>
                <p>
                  You have the right to receive the personal data concerning you
                  that you have provided to us in a structured, common and
                  machine-readable format. In addition, you have the right to
                  pass this data on to another person in charge without
                  obstruction by us, provided that
                </p>
                <List type="bullet">
                  <List.Item>
                    processing is based on consent pursuant to Art. 6 para. 1
                    lit a GDPR or Art. 9 para. 2 lit. GDPR or on a contract
                    pursuant to Art. 6 para. 1 lit. b GDPR and
                  </List.Item>
                  <List.Item>
                    processing is carried out by means of automated methods.
                  </List.Item>
                </List>
                <p>
                  In exercising this right, you also have the right to request
                  that the personal data concerning you be transferred directly
                  from us to another data controller, insofar as this is
                  technically feasible. The freedoms and rights of other persons
                  must not be affected by this.
                </p>
                <Heading element="h3">7. Right to Objection</Heading>
                <p>
                  You have the right to object at any time, for reasons arising
                  from your particular situation, to the processing of your
                  personal data based on Article 6 para 1 lit. e or f GDPR; this
                  also applies to profiling based on these provisions.
                </p>
                <p>
                  In such case we shall no longer process the personal data
                  concerning you, unless we can prove compelling reasons worthy
                  of protection for the processing, which outweigh your
                  interests, rights and freedoms, or the processing serves to
                  assert, exercise or defend legal claims.
                </p>
                <p>
                  If the personal data concerning you are processed for direct
                  marketing purposes, you have the right to object at any time
                  to the processing of the personal data concerning you for the
                  purpose of such advertising; this also applies to profiling,
                  insofar as it is associated with such direct marketing. If you
                  object to the processing for direct marketing purposes, the
                  personal data concerning you will no longer be processed for
                  these purposes.
                </p>
                <Heading element="h3">8. Right to Withdraw Consent</Heading>
                <p>
                  You have the right to revoke your declaration of consent at
                  any time. The revocation of consent shall not affect the
                  legality of the processing carried out on the basis of the
                  consent until revocation.
                </p>
                <Heading element="h3">
                  9. Right of Appeal to Supervisory Authority
                </Heading>
                <p>
                  Without prejudice to any other administrative or judicial
                  remedy, you have the right of appeal to a supervisory
                  authority, if you believe that the processing of personal data
                  concerning you is contrary to the applicable law.
                </p>
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <TextContainer>
                <p>
                  <TextStyle variation="subdued">
                    Privacy Notice - November 2021
                  </TextStyle>
                </p>
              </TextContainer>
            </Card.Section>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <FooterHelp>
            We are always here to help! Get in touch at{' '}
            <Link external url="mailto:hello@meja.io">
              hello@meja.io
            </Link>
          </FooterHelp>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default PrivacyPage;
