import React from 'react';
import { Layout, Card } from '@shopify/polaris';

import ProductsList from './ProductsList';

function ProductsSection() {
  return (
    <Layout.AnnotatedSection
      id="productSection"
      title="Products"
      description="Select which products are recommendable for a customer that replies with this answer."
    >
      <Card>
        <ProductsList />
      </Card>
    </Layout.AnnotatedSection>
  );
}

export default ProductsSection;
