import React, { useContext } from 'react';
import Radium from 'radium';
import { useFormContext } from 'react-hook-form';

import styles from './WelcomePreview.module.css';
import StoreContext from '../../../../providers/StoreContext';

function WelcomePreview({ campaign }) {
  const { watch } = useFormContext();

  const header = watch('text.welcome.header') || campaign.text.welcome.header;
  const subheader =
    watch('text.welcome.subheader') || campaign.text.welcome.subheader;
  const button = watch('text.welcome.button') || campaign.text.welcome.button;
  const image = watch('assets.welcome.image') || campaign.assets.welcome.image;

  const { isLoading, assetURL } = useContext(StoreContext);
  const src = isLoading ? '/placeholder_meja.jpg' : assetURL(image);

  return (
    <div className={styles.container}>
      <div className={styles.imagecontainer}>
        <img className={styles.image} src={src} alt="" />
      </div>
      <div className={styles.content}>
        <div
          className={`header ${styles.header}`}
          style={[
            {
              fontFamily: campaign.style.variables['--meja-header-font-family'],
              fontSize: campaign.style.variables['--meja-header-font-size'],
              color: campaign.style.variables['--meja-color-primary'],
              lineHeight: '1.2em',
            },
          ]}
        >
          {header}
        </div>
        <div
          className={`subheader ${styles.subheader}`}
          style={[
            {
              fontFamily:
                campaign.style.variables['--meja-subheader-font-family'],
              fontSize: campaign.style.variables['--meja-subheader-font-size'],
              color: campaign.style.variables['--meja-color-primary'],
            },
          ]}
        >
          {subheader}
        </div>
        <button
          type="button"
          className={`${styles.button}`}
          style={[
            {
              fontFamily: campaign.style.variables['--meja-button-font-family'],
              fontSize: campaign.style.variables['--meja-button-font-size'],
              padding: campaign.style.variables['--meja-button-padding'],
              fontWeight: campaign.style.variables['--meja-button-font-weight'],
              textTransform:
                campaign.style.variables['--meja-button-text-transform'],
              borderWidth:
                campaign.style.variables['--meja-button-border-width'],
              backgroundColor: campaign.style.variables['--meja-color-primary'],
              borderColor: campaign.style.variables['--meja-color-primary'],
              color: campaign.style.variables['--meja-color-secondary'],
              ':hover': {
                backgroundColor:
                  campaign.style.variables['--meja-color-secondary'],
                borderColor: campaign.style.variables['--meja-color-primary'],
                color: campaign.style.variables['--meja-color-primary'],
                cursor: 'pointer',
              },
            },
          ]}
        >
          {button}
        </button>
      </div>
    </div>
  );
}

export default Radium(WelcomePreview);
