import React from 'react';
import { Layout } from '@shopify/polaris';

import RecommendationPreview from './RecommendationPreview';
import RecommendationStyling from './RecommendationStyling';

function RecommendationLayout() {
  return (
    <Layout>
      <Layout.AnnotatedSection
        title="Recommendation"
        description={
          <>
            <div style={{ marginBottom: '16px' }}>
              Recommendations are displayed after the customer finishes the
              questionnaire.
              <br />
              Might be product or blog posts.
              <br />
              <br />
              The title uses the primary color and the subtitle the primary
              subdued
            </div>
            <RecommendationPreview />
          </>
        }
      >
        <RecommendationStyling />
      </Layout.AnnotatedSection>
    </Layout>
  );
}

export default RecommendationLayout;
