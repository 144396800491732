import { useContext } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIContext from '../providers/APIContext';
import CampaignContext from '../providers/CampaignContext';
import useCampaign from './useCampaign';

export default function useQuestions({ onCreationSuccess = null }) {
  const { fetchQuestions, createQuestion, deleteQuestion, patchCampaign } =
    useContext(APIContext);

  const queryClient = useQueryClient();

  const { campaignId } = useContext(CampaignContext);

  const {
    campaign: {
      config: {
        question: { default_question_order: order },
      },
    },
  } = useCampaign();

  const { isLoading, data: unorderedQuestions = [] } = useQuery(
    ['fetchQuestions', campaignId],
    () => fetchQuestions(campaignId),
  );

  // Order questions

  const orderQuestions = (questions, order) => {
    let orderedQuestions = [];
    order.forEach((id) => {
      if (questions.find((q) => q.id === id))
        orderedQuestions.push(questions.find((q) => q.id === id));
    });
    return orderedQuestions;
  };

  const questions = orderQuestions(unorderedQuestions, order);

  // Add new question

  const {
    mutate: create,
    isLoading: isCreating,
    isSuccess: isCreated,
  } = useMutation(
    (question) =>
      createQuestion({
        ...question,
        klaviyo_alias: question.klaviyo_alias
          ? question.klaviyo_alias
          : question.text.question
              .replace(/[^a-zA-Z0-9]/g, '')
              .toLowerCase()
              .substring(0, 50),
        questionnaire_id: campaignId,
        config: { ...question.config, answer_order: [] },
        style: {},
        assets: {},
      }),
    {
      mutationKey: 'createQuestion',
      onSuccess: (newQuestion) => {
        patchCampaign(campaignId, {
          config: {
            question: { default_question_order: [...order, newQuestion.id] },
          },
        });
        queryClient.setQueryData(
          ['fetchQuestions', campaignId],
          [...questions, newQuestion],
        );
        queryClient.setQueryData(
          ['fetchCampaign', campaignId],
          (oldCampaign) => ({
            ...oldCampaign,
            config: {
              question: { default_question_order: [...order, newQuestion.id] },
            },
          }),
        );
        onCreationSuccess();
      },
    },
  );

  // Delete question

  const {
    mutate: del,
    isLoading: isDeleting,
    isSuccess: isDeleted,
  } = useMutation(
    (question) =>
      patchCampaign(campaignId, {
        config: {
          question: {
            default_question_order: order.filter((id) => id !== question.id),
          },
        },
      }),
    {
      mutationKey: 'deleteQuestion',
      onSuccess: ({
        config: {
          question: { default_question_order: newOrder },
        },
      }) => {
        let deletedQuestions = order.filter((q) => !newOrder.includes(q));
        //TODO: Handle deletion of other questions simultainiusly
        let deletedQuestionID = deletedQuestions[0];
        deleteQuestion(deletedQuestionID);
        queryClient.setQueryData(
          ['fetchQuestions', campaignId],
          questions.filter((q) => q.id !== deletedQuestionID),
        );
        queryClient.setQueryData(
          ['fetchCampaign', campaignId],
          (oldCampaign) => ({
            ...oldCampaign,
            config: {
              question: {
                default_question_order: order.filter(
                  (id) => id !== deletedQuestionID,
                ),
              },
            },
          }),
        );
      },
    },
  );

  // Move question (Re-arrange question order)

  const {
    mutate: reOrder,
    isLoading: isMoving,
    isSuccess: isMoved,
  } = useMutation(
    (order) =>
      patchCampaign(campaignId, {
        config: { question: { default_question_order: order } },
      }),
    {
      mutationKey: 'moveQuestion',
      onSuccess: ({
        config: {
          question: { default_question_order: newOrder },
        },
      }) => {
        queryClient.setQueryData(
          ['fetchCampaign', campaignId],
          (oldCampaign) => ({
            ...oldCampaign,
            config: { question: { default_question_order: newOrder } },
          }),
        );
      },
    },
  );

  const move = (from, to) =>
    reOrder(
      order.map((val, idx) => {
        if (idx === from) return order[to];
        if (idx === to) return order[from];
        return val;
      }),
    );

  return {
    questions,
    isLoading,
    create,
    isCreating,
    isCreated,
    del,
    isDeleting,
    isDeleted,
    move,
    isMoving,
    isMoved,
  };
}
