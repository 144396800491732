import React from 'react';
import { FormLayout, ColorPicker, TextField } from '@shopify/polaris';
import { useFormContext, Controller } from 'react-hook-form';
import {
  hsbToHex,
  rgbToHsb,
} from '@shopify/polaris/dist/esm/utilities/color-transformers';

function ControlledColorSelection({
  name,
  label,
  defaultValue = '#000000',
  helpText = <></>,
  rules = { pattern: /^#(?:[0-9a-f]{3}){1,2}$/i },
  errorMessage = 'Must be a valid HEX color code (#fff or #ffffff)',
  __type__ = FormLayout.Group,
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const hexToRgb = (color) => {
    if (color.length === 4) {
      const repeatHex = (hex1, hex2) => color.slice(hex1, hex2).repeat(2);
      const red = parseInt(repeatHex(1, 2), 16);
      const green = parseInt(repeatHex(2, 3), 16);
      const blue = parseInt(repeatHex(3, 4), 16);

      return { red, green, blue };
    }

    const red = parseInt(color.slice(1, 3), 16);
    const green = parseInt(color.slice(3, 5), 16);
    const blue = parseInt(color.slice(5, 7), 16);

    return { red, green, blue };
  };

  const hexToRgbSafe = (color) =>
    color.length > 0 ? hexToRgb(color) : hexToRgb('#000000');

  return (
    <FormLayout.Group>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field: { onBlur, onChange, value, ref } }) => (
          <TextField
            label={label}
            helpText={helpText}
            onChange={onChange}
            value={value}
            error={errors[name] ? errorMessage : null}
            onBlur={onBlur}
            inputRef={ref}
          />
        )}
      />
      <Controller
        name={name}
        control={control}
        defaultValue={hexToRgbSafe(defaultValue)}
        render={({ field: { onBlur, onChange, value, ref } }) => (
          <ColorPicker
            fullWidth
            color={rgbToHsb(hexToRgbSafe(value))}
            onChange={(color) => onChange(hsbToHex(color))}
            onBlur={onBlur}
            inputRef={ref}
          />
        )}
      />
    </FormLayout.Group>
  );
}

ControlledColorSelection.defaultProps = {
  __type__: FormLayout.Group,
};

export default ControlledColorSelection;
