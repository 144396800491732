import React from 'react';
import { Card, Frame, Layout, Page, TextContainer } from '@shopify/polaris';

import AdminTopBar from './AdminTopBar';

function AdminFrame() {
  return (
    <Frame topBar={<AdminTopBar />}>
      <Page title="Admin functionalities">
        <Layout>
          <Layout.Section>
            <Card sectioned title="Store login">
              <TextContainer>
                To Login to any store use the search bar in the top bar to
                select the store.
              </TextContainer>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </Frame>
  );
}

export default AdminFrame;
