import React from 'react';
import { Layout } from '@shopify/polaris';

import TextStyling from './TextStyling';
import TextPreview from './TextPreview';

function ColorsLayout() {
  return (
    <Layout>
      <Layout.AnnotatedSection title="Text" description={<TextPreview />}>
        <TextStyling />
      </Layout.AnnotatedSection>
    </Layout>
  );
}

export default ColorsLayout;
