import React from 'react';
import { FormLayout, Card } from '@shopify/polaris';

import ControlledTextField from '../../../common/input/ControlledTextField';

function ResultsEditing({ campaign }) {
  return (
    <Card sectioned>
      <FormLayout>
        <ControlledTextField
          name="text.results.header"
          label="Header"
          defaultValue={campaign.text.results.header}
        />
        <ControlledTextField
          name="text.results.subheader"
          label="Subheader"
          defaultValue={campaign.text.results.subheader}
        />
        <FormLayout.Group>
          <ControlledTextField
            name="text.results.from"
            label="Price prefix (i.e. 'from')"
            defaultValue={campaign.text.results.from}
          />
          <ControlledTextField
            name="text.results.currency"
            label="Currency"
            defaultValue={campaign.text.results.currency}
          />
        </FormLayout.Group>
        <ControlledTextField
          name="text.results.restart"
          label="Restart link text"
          defaultValue={campaign.text.results.restart}
        />
        <ControlledTextField
          name="text.results.show_more"
          label="Show more button text"
          defaultValue={campaign.text.results.show_more}
        />
        <FormLayout.Group>
          <ControlledTextField
            name="config.results.results_soft_limit"
            label="Results soft limit"
            defaultValue={campaign.config.results.results_soft_limit}
            type="number"
            suffix="products"
          />
          <ControlledTextField
            name="config.results.results_hard_limit"
            label="Results hard limit"
            defaultValue={campaign.config.results.results_hard_limit}
            type="number"
            suffix="products"
          />
        </FormLayout.Group>
      </FormLayout>
    </Card>
  );
}

export default ResultsEditing;
