import React from 'react';
import { Layout, Card, TextContainer } from '@shopify/polaris';

import { useParams } from 'react-router-dom';
import useQuestion from '../../../hooks/useQuestion';
import useAnswer from '../../../hooks/useAnswer';

function RankingSection() {
  const { qid, aid } = useParams();
  const question = useQuestion(qid);

  const isFiltering = question.question?.filter_products;

  const title = isFiltering ? 'Filtering' : 'Ranking';
  const description = isFiltering
    ? 'Use the following categories to filter recommendable products. You can choose collections, tags, products as well as variants.'
    : 'Use the following categories to rank recommendable products. You can choose collections, tags, products as well as variants.';

  const { hasFilters, removeAllProducts } = useAnswer(aid);

  const card = hasFilters
    ? 'This question has an active selection (see below).'
    : 'This question currently has no active selection and thus does not impact the recommendable products.';

  const action = hasFilters
    ? {
        content: 'Remove all selections',
        destructive: true,
        onAction: removeAllProducts,
      }
    : false;

  return (
    <Layout.AnnotatedSection
      id="rankingSection"
      title={title}
      description={description}
    >
      <Card sectioned primaryFooterAction={action}>
        <TextContainer>
          <p> {card}</p>
        </TextContainer>
      </Card>
    </Layout.AnnotatedSection>
  );
}

export default RankingSection;
