import React, { useContext } from 'react';
import {
  fetchCampaign,
  fetchCampaigns,
  patchCampaign,
  createCampaign,
  deleteCampaign,
} from '../api/campaigns';
import { postPreviewEmail } from '../api/email';
import {
  createQuestion,
  deleteQuestion,
  fetchQuestion,
  fetchQuestions,
  patchQuestion,
} from '../api/questions';
import {
  createAnswer,
  deleteAnswer,
  patchAnswer,
  fetchAnswer,
  fetchAnswers,
} from '../api/answers';
import {
  fetchKlaviyoLists,
  fetchStore,
  patchStore,
  patchStoreEmailSettings,
  fetchStoreEmailSettings,
  createStoreEmailSettings,
  validateDNS,
  refreshProducts,
  checkBilling,
} from '../api/store';
import {
  exportRecommendations,
  fetchRecommendations,
  fetchRecommendationsCounts,
} from '../api/recommendations';
import { fetchCollections, fetchProducts, fetchTags } from '../api/shopify';

import AuthContext from './AuthContext';
import { login, logout, fetchStores, selectStore } from '../api/auth';

export const APIContext = React.createContext(null);

const api = (token) => ({
  fetchCampaigns: () => fetchCampaigns(token),
  fetchCampaign: (id) => fetchCampaign(id, token),
  patchCampaign: (id, campaign) => patchCampaign(id, campaign, token),
  createCampaign: (campaign) => createCampaign(campaign, token),
  deleteCampaign: (id) => deleteCampaign(id, token),
  postPreviewEmail: (id, email) => postPreviewEmail(id, email, token),
  deleteQuestion: (id) => deleteQuestion(id, token),
  createQuestion: (question) => createQuestion(question, token),
  patchQuestion: (id, question) => patchQuestion(id, question, token),
  deleteAnswer: (id) => deleteAnswer(id, token),
  createAnswer: (answer) => createAnswer(answer, token),
  patchAnswer: (id, answer) => patchAnswer(id, answer, token),
  fetchStore: () => fetchStore(token),
  patchStore: (store) => patchStore(store, token),
  patchStoreEmailSettings: (settings) =>
    patchStoreEmailSettings(settings, token),
  fetchKlaviyoLists: () => fetchKlaviyoLists(token),
  validateDNS: () => validateDNS(token),
  fetchRecommendations: (campaign = null, offset = null, limit = null) =>
    fetchRecommendations(token, campaign, offset, limit),
  exportRecommendations: (params) => exportRecommendations(params, token),
  fetchProducts: () => fetchProducts(token),
  fetchCollections: () => fetchCollections(token),
  fetchTags: () => fetchTags(token),
  fetchRecommendationsCounts: () => fetchRecommendationsCounts(token),
  fetchStoreEmailSettings: () => fetchStoreEmailSettings(token),
  createStoreEmailSettings: (settings) =>
    createStoreEmailSettings(settings, token),
  adminLogin: (username, password, store = null) =>
    login(username, password, store),
  adminLogout: () => logout(),
  adminFetchStores: () => fetchStores(token),
  adminSelectStore: (id) => selectStore(id, token),
  fetchQuestions: (campaign_id = null) => fetchQuestions(token, campaign_id),
  fetchQuestion: (id) => fetchQuestion(id, token),
  fetchAnswers: (question_id = null) => fetchAnswers(token, question_id),
  fetchAnswer: (id) => fetchAnswer(id, token),
  refreshProducts: () => refreshProducts(token),
  checkBilling: () => checkBilling(token),
});

export const APIProvider = ({ children }) => {
  const { auth } = useContext(AuthContext);
  const token = auth?.token;

  return (
    <APIContext.Provider value={api(token)}>{children}</APIContext.Provider>
  );
};

export default APIContext;
