export function resize(image_url, size = 'original') {
  // pico	    16 x 16 pixels
  // icon	    32 x 32 pixels
  // thumb	50 x 50 pixels
  // small	100 x 100 pixels
  // compact	160 x 160 pixels
  // medium	240 x 240 pixels
  // large	480 x 480 pixels
  // grande	600 x 600 pixels
  // original	1024 x 1024 pixels
  const valid_sizes = [
    'pico',
    'icon',
    'thumb',
    'small',
    'compact',
    'medium',
    'large',
    'grande',
    'original',
  ];

  if (!valid_sizes.includes(size))
    throw new Error(`'${size}' isn't a valid Shopify size parameter.`);

  return image_url?.replace(/.([^.]*)$/, `_${size}$&`);
}
