import React, { useCallback, useState } from 'react';
import { ResourceList, Button, EmptyState } from '@shopify/polaris';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import AnswersItem from './AnswersItem';
import AnswerCreateModal from './AnswerCreateModal';
import useAnswers from '../../../hooks/useAnswers';

export default function AnswersList() {
  const { answers, isLoading, move, isMoving } = useAnswers({});

  const [createOpen, setCreateOpen] = useState(false);

  const onCreate = useCallback(() => setCreateOpen(true), []);

  return (
    <>
      <DragDropContext
        onDragEnd={({ source, destination }) =>
          move(source.index, destination.index)
        }
      >
        <Droppable droppableId="answers">
          {(provided) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <ResourceList
                  loading={isLoading || isMoving}
                  resourceName={{ singular: 'answer', plural: 'answers' }}
                  items={answers}
                  renderItem={(answer, id, index) => (
                    <AnswersItem key={id} index={index} answer={answer} />
                  )}
                  alternateTool={
                    <Button onClick={onCreate}>Add new answer</Button>
                  }
                  emptyState={
                    <EmptyState
                      heading="Add a first answer to your question"
                      action={{
                        content: 'Add new answer',
                        onAction: () => setCreateOpen(true),
                      }}
                    />
                  }
                />
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      <AnswerCreateModal
        open={createOpen}
        onClose={() => setCreateOpen(false)}
      />
    </>
  );
}
