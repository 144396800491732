import React from 'react';
import { Layout, Card } from '@shopify/polaris';

import VariantsList from './VariantsList';

function VariantsSection() {
  return (
    <Layout.AnnotatedSection
      id="variantsSection"
      title="Variants"
      description="Select which product variants are recommendable for a customer that replies with this answer."
    >
      <Card>
        <VariantsList />
      </Card>
    </Layout.AnnotatedSection>
  );
}

export default VariantsSection;
