import React, { useState } from 'react';

export const CampaignContext = React.createContext(null);

export const CampaignProvider = ({ children }) => {
  const [campaignId, setCampaignId] = useState(null);

  return (
    <CampaignContext.Provider value={{ campaignId, setCampaignId }}>
      {children}
    </CampaignContext.Provider>
  );
};

export default CampaignContext;
