import React from 'react';
import { TextContainer } from '@shopify/polaris';

const fontFamily = (
  <TextContainer>
    Currently you are only able to use fonts that are already available in your
    theme. These will no preview correctly here, but will be visible in your
    store.
  </TextContainer>
);

export default fontFamily;
