import React from 'react';
import { Modal, Stack } from '@shopify/polaris';

import { useForm, FormProvider } from 'react-hook-form';

import ControlledTextField from '../../common/input/ControlledTextField';

function EmailTestModal({
  active,
  handleCancel,
  emailTemplateForm,
  sendTestEmail,
}) {
  //TODO: Error handling
  const sendDisabled = sendTestEmail.isLoading || sendTestEmail.isSuccess;

  const form = useForm();

  const sendEmail = (emailPreview) => {
    const emailTemplate = emailTemplateForm.getValues();
    const email = {
      subject: '[meja.io Preview] ' + emailTemplate.email_subject,
      template: emailTemplate.email_template,
      use_template: emailTemplate.custom_email_template,
      ...emailPreview,
    };
    sendTestEmail.mutate(email);
  };

  return (
    <Modal
      open={active}
      onClose={handleCancel}
      loading={sendTestEmail.isLoading}
      title="Send Test E-Mail"
      primaryAction={{
        content: 'Send E-Mail',
        onAction: form.handleSubmit(sendEmail),
        disabled: sendDisabled,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: handleCancel,
          disabled: sendTestEmail.isLoading,
        },
      ]}
    >
      <FormProvider {...form}>
        <Modal.Section>
          <Stack vertical>
            <Stack.Item>
              <ControlledTextField name="name" label="Name" />
            </Stack.Item>
            <Stack.Item>
              <ControlledTextField
                name="email"
                label="E-Mail address"
                type="email"
              />
            </Stack.Item>
          </Stack>
        </Modal.Section>
      </FormProvider>
    </Modal>
  );
}

export default EmailTestModal;
