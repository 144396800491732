import React from 'react';
import { FormLayout, Card } from '@shopify/polaris';

import ControlledTextField from '../../../common/input/ControlledTextField';
import { fontFamilyHelpText } from '../../../common/helpText';

function InputStyling() {
  return (
    <Card sectioned>
      <FormLayout>
        <ControlledTextField
          name="style.variables.--meja-input-text-font-family"
          label="Font"
          helpText={fontFamilyHelpText}
        />

        <ControlledTextField
          name="style.variables.--meja-input-text-font-size"
          label="Font (size)"
        />

        <ControlledTextField
          name="style.variables.--meja-input-text-border-width"
          label="Boder width"
        />

        <ControlledTextField
          name="style.variables.--meja-input-text-focus-border-width"
          label="Border width (focused)"
        />

        <ControlledTextField
          name="style.variables.--meja-input-text-padding"
          label="Padding"
        />
      </FormLayout>
    </Card>
  );
}

export default InputStyling;
