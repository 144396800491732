import React from 'react';
import { TopBar } from '@shopify/polaris';

// TODO: Should contain a login button and a register call to action button

function AppTopBar(props) {
  return <TopBar />;
}

export default AppTopBar;
