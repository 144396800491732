import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  Collapsible,
  Button,
  Heading,
  TextContainer,
  Stack,
} from '@shopify/polaris';
import { ChevronUpMinor, ChevronDownMinor } from '@shopify/polaris-icons';
import { useHistory } from 'react-router';

function Question({ id, question, answer, video }) {
  const {
    location: { hash },
  } = useHistory();

  const [open, setOpen] = useState(false);
  const handleToggle = useCallback(() => setOpen((open) => !open), []);

  useEffect(() => {
    if (hash === `#${id}`) setOpen(true);
  }, [id, hash]);

  return (
    <Card.Section id={id}>
      <TextContainer>
        <Button
          plain
          icon={open ? ChevronUpMinor : ChevronDownMinor}
          onClick={handleToggle}
        >
          <Heading>{question}</Heading>
        </Button>
        <Collapsible
          open={open}
          id="basic-collapsible"
          transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
          expandOnPrint
        >
          <Stack>
            {video && (
              <Stack.Item>
                <div
                  style={{
                    position: 'relative',
                    paddingBottom: '54.13533834586466%',
                    height: 0,
                    width: '300px',
                  }}
                >
                  <iframe
                    title={`${question} video`}
                    src={`https://www.loom.com/embed/${video}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                  ></iframe>
                </div>
              </Stack.Item>
            )}
            <Stack.Item fill>{answer}</Stack.Item>
          </Stack>
        </Collapsible>
      </TextContainer>
    </Card.Section>
  );
}

export default Question;
