import React from 'react';
import { TextField } from '@shopify/polaris';
import { useFormContext, Controller } from 'react-hook-form';

function ControlledTextField({
  name,
  label,
  helpText = <></>,
  defaultValue = '',
  rules = {},
  errorMessage = 'Incorrect input',
  multiline = false,
  monospaced = false,
  disabled = false,
  type = 'text',
  suffix = '',
  maxLength = false,
  showCharacterCount = false,
  autoComplete = 'off',
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  defaultValue = defaultValue || '';

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onBlur, onChange, value, ref } }) => (
        <TextField
          label={label}
          helpText={helpText}
          onChange={onChange}
          value={value.toString()}
          error={errors[name] ? errorMessage : null}
          onBlur={onBlur}
          multiline={multiline}
          monospaced={monospaced}
          disabled={disabled}
          type={type}
          suffix={suffix}
          inputRef={ref}
          showCharacterCount={showCharacterCount}
          autoComplete={autoComplete}
          {...(maxLength ? { maxLength: maxLength } : {})}
        />
      )}
    />
  );
}

export default ControlledTextField;
