import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Page } from '@shopify/polaris';

import { useQuery } from 'react-query';

import { authorize } from '../../../api/oauth';

import AccessLayout from './AccessLayout';

import config from '../../../config';

function AccessPage() {
  const history = useHistory();

  const params = new URLSearchParams(history.location.search);
  const hmac = params.get('hmac');
  const shop = params.get('shop');
  const timestamp = params.get('timestamp');
  const { isSuccess, data } = useQuery('authorize', () =>
    authorize(shop, timestamp, hmac),
  );

  useEffect(() => {
    if (isSuccess) {
      const auth_str = JSON.stringify({ token: data.token });
      localStorage.setItem(config.env + '_auth', auth_str);
      window.location.href = data.authorize_url;
    }
  }, [isSuccess, data]);

  return (
    <Page>
      <AccessLayout />
    </Page>
  );
}

export default AccessPage;
