import React from 'react';
import { Layout, Card } from '@shopify/polaris';

import TagsList from './TagsList';

function TagsSection() {
  return (
    <Layout.AnnotatedSection
      id="tagsSection"
      title="Tags"
      description="Select which tags are recommendable for a customer that replies with this answer."
    >
      <Card>
        <TagsList />
      </Card>
    </Layout.AnnotatedSection>
  );
}

export default TagsSection;
