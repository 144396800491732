import React, { useState, useCallback, useEffect } from 'react';
import {
  ResourceList,
  Filters,
  Card,
  ResourceItem,
  TextStyle,
  Thumbnail,
  ChoiceList,
  Button,
  Stack,
  Pagination,
  SkeletonThumbnail,
} from '@shopify/polaris';

import { RefreshMinor } from '@shopify/polaris-icons';

import { resize } from '../../common/utils/shopify/imageResize';
import { useParams } from 'react-router-dom';
import useAnswer from '../../../hooks/useAnswer';
import useProducts from '../../../hooks/useProducts';
import useTags from '../../../hooks/useTags';
import useCollections from '../../../hooks/useCollections';

function ProductsList() {
  const { aid: id } = useParams();
  const { selectedProducts, replaceProducts } = useAnswer(id);

  const {
    isLoading: isLoadingProducts,
    products,
    refresh,
    isRefreshing,
    refreshToast,
  } = useProducts();
  const { isLoading: isLoadingTags, tags } = useTags();
  const { isLoading: isLoadingCollections, collections } = useCollections();

  const [queryValue, setQueryValue] = useState(null);
  const [collectionSelection, setCollectionSelection] = useState([]);
  const [tagSelection, setTagSelection] = useState([]);
  const handleCollectionSelectionChange = useCallback(
    (value) => setCollectionSelection(value),
    [],
  );
  const handleTagSelectionChange = useCallback(
    (value) => setTagSelection(value),
    [],
  );

  const filteredProducts = products
    .filter((product) =>
      product.title.toLowerCase().includes(queryValue?.toLowerCase() || ''),
    )
    .filter((product) =>
      product.tags
        .map((tag) => tag.name)
        .filter((productTag) => tagSelection.includes(productTag)).length > 0 ||
      tagSelection.length === 0
        ? true
        : false,
    )
    .filter((product) =>
      product.collections
        .map((collection) => collection.handle)
        .filter((colelctionHandle) =>
          collectionSelection.includes(colelctionHandle),
        ).length > 0 || collectionSelection.length === 0
        ? true
        : false,
    );

  const handleQueryValueChange = useCallback(
    (value) => setQueryValue(value),
    [],
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);

  const filters = [
    {
      key: 'collection',
      label: 'Collection',
      filter: (
        <ChoiceList
          title="Collection"
          titleHidden
          disabled={isLoadingCollections}
          choices={collections.map((collection) => ({
            label: collection.title,
            value: collection.handle,
          }))}
          selected={collectionSelection || []}
          onChange={handleCollectionSelectionChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'tag',
      label: 'Tag',
      filter: (
        <ChoiceList
          title="Tag"
          titleHidden
          disabled={isLoadingTags}
          choices={tags.map((tag) => ({
            label: `${tag.name} (${tag.product_count})`,
            value: tag.name,
          }))}
          selected={tagSelection || []}
          onChange={handleTagSelectionChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const [offset, setOffset] = useState(0);
  const limit = 5;
  useEffect(
    () => setOffset(0),
    [queryValue, collectionSelection, tagSelection],
  );

  return (
    <>
      <Card>
        <Card.Section>
          <ResourceList
            loading={isLoadingProducts}
            resourceName={{
              singular: 'product',
              plural: 'products',
            }}
            items={filteredProducts.slice(offset, offset + limit)}
            totalItemsCount={filteredProducts.length}
            idForItem={(product) => product.data.id}
            renderItem={({ data: { id }, image, image_alt, title }) => (
              <ResourceItem
                key={id}
                id={id}
                url={false}
                media={
                  image ? (
                    <Thumbnail
                      source={resize(image, 'thumb')}
                      alt={image_alt}
                    />
                  ) : (
                    <SkeletonThumbnail />
                  )
                }
              >
                <h3>
                  <TextStyle variation="strong">{title}</TextStyle>
                </h3>
              </ResourceItem>
            )}
            selectable
            selectedItems={selectedProducts}
            onSelectionChange={replaceProducts}
            filterControl={
              <Filters
                queryValue={queryValue}
                filters={filters}
                onQueryChange={handleQueryValueChange}
                onQueryClear={handleQueryValueRemove}
              >
                <div style={{ paddingLeft: '8px' }}>
                  <Button
                    icon={RefreshMinor}
                    onClick={refresh}
                    loading={isRefreshing}
                    disabled={isLoadingProducts}
                  >
                    Refresh
                  </Button>
                </div>
              </Filters>
            }
          />
        </Card.Section>
        {filteredProducts.length > limit && (
          <Card.Section>
            <Stack distribution="center">
              <Pagination
                hasPrevious={offset > 0}
                onPrevious={() => setOffset((prev) => prev - limit)}
                hasNext={filteredProducts.length > offset + limit}
                onNext={() => setOffset((prev) => prev + limit)}
              />
            </Stack>
          </Card.Section>
        )}
      </Card>
      {refreshToast}
    </>
  );
}

export default ProductsList;
