import React from 'react';
import Radium from 'radium';
import { useFormContext } from 'react-hook-form';

import { Subheading } from '@shopify/polaris';

import styles from './ColorsPreview.module.css';

function ColorsPreview() {
  const { watch } = useFormContext();
  const val = (name) => watch(`style.variables.${name}`);
  return (
    <>
      <Subheading>Your palette</Subheading>
      <div className={styles.container}>
        <div className={styles.primary}>
          <div
            className={styles.color}
            style={[{ backgroundColor: val('--meja-color-primary') }]}
          ></div>
          <div
            className={styles.color}
            style={[{ backgroundColor: val('--meja-color-primary-accent') }]}
          ></div>
          <div
            className={styles.color}
            style={[{ backgroundColor: val('--meja-color-primary-subdued') }]}
          ></div>
          <div
            className={styles.color}
            style={[{ backgroundColor: val('--meja-color-error') }]}
          ></div>
        </div>
        <div className={styles.secondary}>
          <div
            className={styles.color}
            style={[{ backgroundColor: val('--meja-color-secondary') }]}
          ></div>
          <div
            className={styles.color}
            style={[{ backgroundColor: val('--meja-color-secondary-accent') }]}
          ></div>
          <div
            className={styles.color}
            style={[{ backgroundColor: val('--meja-color-secondary-subdued') }]}
          ></div>
        </div>
      </div>
    </>
  );
}

export default Radium(ColorsPreview);
