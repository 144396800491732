import { Toast } from '@shopify/polaris';
import React, { useContext, useState } from 'react';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Prompt } from 'react-router-dom';
import APIContext from '../providers/APIContext';

const PRODUCT = 'shopify:product:'; // shopify:product:<id> Shopify product, cheapest variant
const VARIANT = 'shopify:variant:'; // shopify:variant:<product id>:<variant id> Shopify product variant
const COLLECTION = 'shopify:collection:'; //shopify:collection:<id> All Shopify products in a collection
const TAG = 'shopify:tag:'; // shopify:tag:<tag> All Shopify products with a tag
// const BLOG = 'blog:'; // blog:<id> Blog resource with id

export default function useAnswer(id) {
  const { fetchAnswer, patchAnswer } = useContext(APIContext);

  const queryClient = useQueryClient();

  const { data: answer, isLoading } = useQuery(
    ['fetchAnswer', id],
    () => fetchAnswer(id),
    {
      onSuccess: (answer) => form.reset(answer),
    },
  );

  const { question_id: questionId } = answer || { question_id: null };

  const {
    mutate: save,
    isLoading: isSaving,
    isSuccess: isSaved,
  } = useMutation((answer) => patchAnswer(id, answer), {
    mutationKey: ['patchAnswer', id],
    onSuccess: (newAnswer) => {
      queryClient.setQueryData(['fetchAnswer', id], newAnswer);
      queryClient.setQueryData(['fetchAnswers', questionId], (answers) =>
        answers?.map((answer) => (answer.id === id ? newAnswer : answer)),
      );
      form.reset(newAnswer);
      activateToast();
    },
  });

  const formContext = useFormContext();
  const newForm = useForm();
  const form = formContext || newForm;

  const {
    formState: { isDirty },
    control,
  } = form;

  const selectedProducts = form.getValues().products
    ? form
        .getValues()
        .products.filter((product) => product.startsWith(PRODUCT))
        .map((product) => parseInt(product.replace(PRODUCT, '')))
    : form.getValues().products;

  const selectedCollections = form.getValues().products
    ? form
        .getValues()
        .products.filter((product) => product.startsWith(COLLECTION))
        .map((product) => parseInt(product.replace(COLLECTION, '')))
    : form.getValues().products;

  const selectedTags = form.getValues().products
    ? form
        .getValues()
        .products.filter((product) => product.startsWith(TAG))
        .map((product) => product.replace(TAG, ''))
    : form.getValues().products;

  const selectedVariants = form.getValues().products
    ? form
        .getValues()
        .products.filter((product) => product.startsWith(VARIANT))
        .map((product) => product.replace(VARIANT, ''))
    : form.getValues().products;

  const { replace } = useFieldArray({
    control,
    name: 'products',
  });

  const replaceProducts = (newProducts) =>
    replace(
      form
        .getValues()
        .products.filter((p) => !p.startsWith(PRODUCT))
        .concat(newProducts.map((p) => `${PRODUCT}${p}`)),
    );

  const replaceVariants = (newProducts) =>
    replace(
      form
        .getValues()
        .products.filter((p) => !p.startsWith(VARIANT))
        .concat(newProducts.map((v) => `${VARIANT}${v}`)),
    );

  const replaceCollections = (newCollections) =>
    replace(
      form
        .getValues()
        .products.filter((p) => !p.startsWith(COLLECTION))
        .concat(newCollections.map((c) => `${COLLECTION}${c}`)),
    );

  const replaceTags = (newTags) => {
    replace(
      form
        .getValues()
        .products.filter((p) => !p.startsWith(TAG))
        .concat(newTags.map((t) => `${TAG}${t}`)),
    );
  };

  const [toastActive, setToastActive] = useState(true);

  const dismissToast = () => setToastActive(false);

  const activateToast = () => setToastActive(true);

  const editMarkup = (
    <>
      {isSaved && toastActive && (
        <Toast content="Answer saved successfully" onDismiss={dismissToast} />
      )}
      <Prompt
        message="You have some unsaved changes. Are you sure you want to leave? Your changes will be lost."
        when={isDirty}
      />
    </>
  );

  const saveAction = {
    content: 'Save and Publish',
    loading: isLoading,
    disabled: !isDirty,
    onAction: form.handleSubmit(save),
  };

  const hasFilters = form.getValues().products?.length > 0;

  const removeAllProducts = () => {
    replace([]);
  };

  return {
    answer,
    isLoading,
    save,
    isSaving,
    isSaved,
    form,
    saveAction,
    editMarkup,
    selectedProducts,
    replaceProducts,
    selectedCollections,
    replaceCollections,
    selectedTags,
    replaceTags,
    selectedVariants,
    replaceVariants,
    hasFilters,
    removeAllProducts,
  };
}
