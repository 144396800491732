import React, { useContext } from 'react';
import { Form, FormLayout, Modal } from '@shopify/polaris';
import { useMutation, useQueryClient } from 'react-query';
import { FormProvider, useForm } from 'react-hook-form';
import ControlledTextField from '../../common/input/ControlledTextField';

import {
  defaultConfig,
  defaultText,
  defaultAssets,
  defaultStyle,
} from '../../../defaults';
import APIContext from '../../../providers/APIContext';

function QuestionCreateModal({ campaigns, ...rest }) {
  const form = useForm();

  const { createCampaign } = useContext(APIContext);

  const queryClient = useQueryClient();

  const creation = useMutation((campaign) => createCampaign(campaign), {
    onSuccess: () => {
      queryClient.invalidateQueries(['fetchCampaigns']);
      rest.onClose();
    },
  });

  const handleCreate = (campaign) => {
    campaign.text = defaultText;
    campaign.style = defaultStyle;
    campaign.config = defaultConfig;
    campaign.assets = defaultAssets;
    if (
      !campaigns
        .map((campaign) => campaign.slug)
        .includes(campaign.name.replace(/\s+/g, '').toLowerCase())
    )
      campaign.slug = campaign.name.replace(/\s+/g, '').toLowerCase();
    else
      campaign.slug = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 5);
    campaign.email_template = '<html></html>';
    campaign.custom_email_template = false;
    campaign.email_subject = `${campaign.name} recommendations`;
    campaign.klaviyo_list = '';
    campaign.klaviyo_event = false;
    creation.mutate(campaign);
  };

  return (
    <Modal
      {...rest}
      title="Create new project"
      primaryAction={{
        content: 'Create',
        onAction: form.handleSubmit(handleCreate),
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: rest.onClose,
        },
      ]}
    >
      <FormProvider {...form}>
        <Modal.Section>
          <Form onSubmit={form.handleSubmit(handleCreate)}>
            <FormLayout>
              <ControlledTextField name="name" label="Name" />
            </FormLayout>
          </Form>
        </Modal.Section>
      </FormProvider>
    </Modal>
  );
}

export default QuestionCreateModal;
