import React, { useContext } from 'react';
import {
  Layout,
  TextContainer,
  Card,
  FormLayout,
  TextStyle,
  Banner,
  Stack,
} from '@shopify/polaris';

import { useQuery } from 'react-query';

import ControlledCheckbox from '../../../common/input/ControlledCheckbox';
import ControlledSelect from '../../../common/input/ControlledSelect';
import APIContext from '../../../../providers/APIContext';

function KlaviyoLayout({ campaign }) {
  const { fetchKlaviyoLists } = useContext(APIContext);

  const {
    isSuccess,
    isLoading,
    error,
    data: lists = [],
  } = useQuery('fetchKlaviyoLists', fetchKlaviyoLists, { retry: false });

  const klaviyoLists = isSuccess
    ? lists.map((list) => ({
        label: list.list_name,
        value: list.list_id,
      }))
    : [];

  const selectPlaceholder = isLoading
    ? 'Loading Klaviyo lists'
    : 'Select a Klaviyo list';

  return (
    <Layout>
      <Layout.AnnotatedSection
        title="Klaviyo"
        description={
          <Stack>
            {error && (
              <Banner
                title="Before you can configure Klaviyo events and lists for this campaign, you need to setup Klaviyo for your store."
                action={{
                  content: 'Setup Klaviyo',
                  url: '/app/settings/klaviyo',
                }}
                status="warning"
              />
            )}
            <TextContainer>
              Setup Klaviyo per project event and list.
            </TextContainer>
          </Stack>
        }
      >
        <Card sectioned>
          <FormLayout>
            <ControlledCheckbox
              name="klaviyo_event"
              label="Trigger Klaviyo event"
              defaultValue={campaign.klaviyo_event}
              disabled={isLoading || error}
              helpText={
                <TextContainer>
                  <p>
                    When set, each time a customer with an email address
                    receives a recommendation meja will trigger a Klaviyo event
                    named{' '}
                    <TextStyle variation="code">meja recommendation</TextStyle>{' '}
                    that also contains which{' '}
                    <TextStyle variation="code">questionnaire</TextStyle> was
                    filled out and what{' '}
                    <TextStyle variation="code">products</TextStyle> were
                    recommended.
                  </p>
                </TextContainer>
              }
            />
            <ControlledSelect
              name="klaviyo_list"
              label="Klaviyo list"
              placeholder={selectPlaceholder}
              disabled={isLoading || error}
              options={klaviyoLists}
              defaultValue={campaign.klaviyo_list}
              helpText={
                <TextContainer>
                  <p>
                    When set, each time a customer with an email address
                    receives a recommendation meja will trigger a Klaviyo event
                    named{' '}
                    <TextStyle variation="code">meja recommendation</TextStyle>{' '}
                    that also contains which{' '}
                    <TextStyle variation="code">questionnaire</TextStyle> was
                    filled out and what{' '}
                    <TextStyle variation="code">products</TextStyle> were
                    recommended.
                  </p>
                </TextContainer>
              }
            />
          </FormLayout>
        </Card>
      </Layout.AnnotatedSection>
    </Layout>
  );
}

export default KlaviyoLayout;
