import React, { useContext, useState } from 'react';
import {
  ResourceItem,
  Thumbnail,
  TextStyle,
  TextContainer,
} from '@shopify/polaris';
import { DateTime } from 'luxon';

import StoreContext from '../../../providers/StoreContext';

import CampaignDeleteModal from './CampaignDeleteModal';

function CampaignItem({ campaign, index }) {
  const {
    id,
    name,
    slug,
    assets: {
      welcome: { image: thumbnail },
    },
    updated_at,
  } = campaign;
  const [deleteOpen, setDeleteOpen] = useState(false);

  const storeContext = useContext(StoreContext);
  const previewAction = {
    content: 'Preview Live',
    disabled: storeContext.isLoading ? true : false,
    url: storeContext.isLoading
      ? null
      : `https://${storeContext.store.shopify_store.domain}${storeContext.store.shopify_store.path_prefix}/${slug}`,
  };

  const shortcutActions = [
    previewAction,
    {
      content: 'Delete',
      destructive: true,
      onAction: () => setDeleteOpen(true),
    },
  ];
  const lastEdit = DateTime.fromISO(updated_at).toRelative();

  return (
    <>
      <ResourceItem
        key={id}
        index={index}
        media={
          <Thumbnail
            source={
              storeContext.isLoading
                ? '/placeholder_meja.jpg'
                : storeContext.assetURL(thumbnail)
            }
            alt={name}
          />
        }
        shortcutActions={shortcutActions}
        accessibilityLabel={`Manage project ${name}`}
        name={name}
        url={`projects/${slug}`}
      >
        <h3>
          <TextStyle variation="strong">{name}</TextStyle>
        </h3>
        <TextContainer>
          <TextStyle variation="subdued">Last edited {lastEdit}</TextStyle>
        </TextContainer>
      </ResourceItem>
      <CampaignDeleteModal
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        campaign={campaign}
      />
    </>
  );
}

export default CampaignItem;
