import React, { useCallback, useState } from 'react';
import { Frame } from '@shopify/polaris';
import { Switch, Route } from 'react-router';

import PublicTopBar from './PublicTopBar';
import PrivacyPage from './privacy/PrivacyPage';

function PublicFrame() {
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive,
      ),
    [],
  );

  return (
    <Frame
      topBar={
        <PublicTopBar onNavigationToggle={toggleMobileNavigationActive} />
      }
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
    >
      <Switch>
        <Route path="/privacy">
          <PrivacyPage />
        </Route>
      </Switch>
    </Frame>
  );
}

export default PublicFrame;
