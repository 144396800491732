import React, { useCallback } from 'react';
import { Page, Tabs, Loading } from '@shopify/polaris';

import { FormProvider } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';

import EmailLayout from './email/EmailLayout';
import WelcomeLayout from './welcome/WelcomeLayout';
import LoadingLayout from './loading/LoadingLayout';
import ResultsLayout from './results/ResultsLayout';

import useCampaign from '../../../hooks/useCampaign';
import QuestionnaireLayout from './questionnaire/QuestionnaireLayout';

function Pages() {
  const { campaign, isLoading, form, editMarkup, saveAction } = useCampaign();
  const history = useHistory();

  const tabs = [
    {
      id: 'welcome',
      content: '1. Welcome',
      layout: <WelcomeLayout campaign={campaign} />,
    },
    {
      id: 'questionnaire',
      content: '2. Questionnaire',
      layout: <QuestionnaireLayout campaign={campaign} />,
    },
    {
      id: 'email',
      content: '3. Collect Email',
      layout: <EmailLayout campaign={campaign} />,
    },
    {
      id: 'loading',
      content: '4. Loading',
      layout: <LoadingLayout campaign={campaign} />,
    },
    {
      id: 'results',
      content: '5. Results',
      layout: <ResultsLayout campaign={campaign} />,
    },
  ];

  const handleTabChange = useCallback(
    (id) =>
      history.push(`/app/projects/${campaign?.slug}/pages/${tabs[id].id}`),
    [campaign, history, tabs],
  );
  const { tab = 'welcome' } = useParams();
  const tabID = tabs.findIndex((t) => t.id === tab);

  if (isLoading) return <Loading />;

  return (
    <Page title="Pages" primaryAction={saveAction}>
      <FormProvider {...form}>
        <Tabs tabs={tabs} selected={tabID} onSelect={handleTabChange}>
          {tabs[tabID].layout}
        </Tabs>
      </FormProvider>
      {editMarkup}
    </Page>
  );
}

export default Pages;
