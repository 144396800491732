import React from 'react';
import { Card, FormLayout } from '@shopify/polaris';

import { useFormContext } from 'react-hook-form';

import ControlledTextField from '../../common/input/ControlledTextField';
import ControlledCheckbox from '../../common/input/ControlledCheckbox';

function EmailEditor({
  campaign: {
    email_subject: emailSubjectInitial,
    custom_email_template: useCustomTemplateInitial,
    email_template: emailTemplateInitial,
  },
}) {
  const { watch } = useFormContext();

  const watchCustomEmailTemplate = watch('custom_email_template');

  let useCustomTemplate =
    watchCustomEmailTemplate === undefined
      ? useCustomTemplateInitial
      : watchCustomEmailTemplate;

  return (
    <Card sectioned>
      <FormLayout>
        <ControlledTextField
          name="email_subject"
          label="E-Mail Subject"
          defaultValue={emailSubjectInitial}
        />
        <ControlledCheckbox
          name="custom_email_template"
          label="Use custom template"
          defaultValue={useCustomTemplateInitial}
        />
        <ControlledTextField
          name="email_template"
          label="HTML Template"
          defaultValue={emailTemplateInitial}
          multiline={20}
          monospaced
          disabled={!useCustomTemplate}
        />
      </FormLayout>
    </Card>
  );
}

export default EmailEditor;
