import React, { useState, useCallback, useContext, useEffect } from 'react';
import {
  Layout,
  TextContainer,
  Card,
  FormLayout,
  TextStyle,
  ResourceList,
  ResourceItem,
  Icon,
  Stack,
  Collapsible,
  Button,
} from '@shopify/polaris';
import {
  CircleTickOutlineMinor,
  CircleMinusOutlineMinor,
} from '@shopify/polaris-icons';

import { useQuery } from 'react-query';

import { Checkbox, TextField } from '../../../common/input';

import APIContext from '../../../../providers/APIContext';

function SendgridLayout({ store }) {
  const [refetchInterval, setRefetchInterval] = useState(10000);

  const { validateDNS } = useContext(APIContext);

  const {
    isLoading,
    refetch: reValidateDNS,
    data: validation,
  } = useQuery('validateDNS', validateDNS, { refetchInterval });

  const handleToggle = useCallback(() => setOpen((open) => !open), []);

  const { email_settings } = store;
  const [open, setOpen] = useState(
    email_settings ? !email_settings.sendgrid_domain_valid : false,
  );

  //TODO: avoid hardcoding of the three entries
  if (validation && email_settings) {
    email_settings.sendgrid_dns.mail_cname = {
      ...email_settings.sendgrid_dns.mail_cname,
      ...validation.validation_result.mail_cname,
    };
    email_settings.sendgrid_dns.dkim1 = {
      ...email_settings.sendgrid_dns.dkim1,
      ...validation.validation_result.dkim1,
    };
    email_settings.sendgrid_dns.dkim2 = {
      ...email_settings.sendgrid_dns.dkim2,
      ...validation.validation_result.dkim2,
    };
  }

  // Stop refetching automatically when validate has returned and all entries are valid
  useEffect(() => {
    if (
      validation &&
      validation.validation_result.mail_cname.valid &&
      validation.validation_result.dkim1.valid &&
      validation.validation_result.dkim2.valid
    ) {
      setRefetchInterval(false);
    }
  }, [email_settings, validation]);

  const dnsStatus = email_settings
    ? Object.values(email_settings.sendgrid_dns).map((entry) => ({
        type: entry.type.toUpperCase(),
        host: entry.host,
        data: entry.data,
        icon: entry.valid ? CircleTickOutlineMinor : CircleMinusOutlineMinor,
        status: entry.valid ? 'success' : 'critical',
        reason: entry.reason,
      }))
    : [];

  const renderDnsStatus = ({ type, host, data, icon, status }) => (
    <ResourceItem>
      <Stack>
        <Icon source={icon} color={status} />
        <TextContainer>
          <TextStyle variation="strong">{type}</TextStyle>
          <br />
          <TextStyle variation="strong">Host</TextStyle>{' '}
          <TextStyle variation="code">{host}</TextStyle>
          <br />
          <TextStyle variation="strong">Data</TextStyle>{' '}
          <TextStyle variation="code">{data}</TextStyle>
        </TextContainer>
      </Stack>
    </ResourceItem>
  );

  return (
    <Layout>
      <Layout.AnnotatedSection
        title="E-Mail"
        description={<TextContainer>Sendgrid setup</TextContainer>}
      >
        <Card sectioned>
          <FormLayout>
            <Checkbox
              name="enable_email"
              label="Enable sending of recommendation e-mails to customers"
              defaultValue={store.enable_email}
            />
            <TextField
              name="email_settings.domain"
              label="Domain"
              defaultValue={email_settings?.domain}
            />
            {email_settings && (
              <Button
                onClick={handleToggle}
                ariaExpanded={open}
                ariaControls="basic-collapsible"
              >
                Show DNS entries
              </Button>
            )}
            <Collapsible
              open={open}
              id="dns-collapsible"
              transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
              expandOnPrint
            >
              <Button onClick={reValidateDNS}>Refresh</Button>
              <ResourceList
                items={dnsStatus}
                renderItem={renderDnsStatus}
                loading={isLoading}
              />
            </Collapsible>

            <TextField
              name="email_settings.from_email"
              label="From E-Mail"
              defaultValue={email_settings?.from_email}
            />
            <TextField
              name="email_from_name"
              label="From Name"
              defaultValue={store.email_from_name}
            />
            <TextField
              name="email_bcc"
              label="BCC E-Mail"
              defaultValue={store.email_bcc}
              helpText={
                <TextContainer>
                  <p>
                    Enter your E-mail address here if you'd like to receive a
                    copy of all E-Mails we send out in your name.
                  </p>
                </TextContainer>
              }
            />
          </FormLayout>
        </Card>
      </Layout.AnnotatedSection>
    </Layout>
  );
}

export default SendgridLayout;
