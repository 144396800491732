import React, { useEffect } from 'react';
import { TextStyle, TextContainer, Modal } from '@shopify/polaris';
import { DeleteMinor } from '@shopify/polaris-icons';
import useAnswers from '../../../hooks/useAnswers';

function AnswerDeleteModal({ open, onClose, answer }) {
  const { del: deleteAnswer, isDeleting, isDeleted } = useAnswers({});
  useEffect(() => {
    if (isDeleted) onClose();
  }, [isDeleted, onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Delete answer permanently"
      primaryAction={{
        icon: DeleteMinor,
        content: 'Delete',
        destructive: true,
        onAction: () => deleteAnswer(answer),
        loading: isDeleting,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>
            Are you sure you want to delete the following answer?
            <br />
            <br />
          </p>
        </TextContainer>
        <TextContainer>
          <TextStyle variation="strong">{answer.text.answer}</TextStyle>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
}

export default AnswerDeleteModal;
