import React, { useState } from 'react';
import {
  ResourceItem,
  Stack,
  TextStyle,
  Tooltip,
  Icon,
  Badge,
  TextContainer,
} from '@shopify/polaris';
import { DragHandleMinor, DeleteMinor } from '@shopify/polaris-icons';

import { Draggable } from 'react-beautiful-dnd';
import { useRouteMatch } from 'react-router';
import QuestionDeleteModal from './QuestionDeleteModal';

function QuestionsItem({ index, question, campaign }) {
  const {
    id,
    config: { multiple_choice, optional },
    text: { question: questionText },
    filter_products,
  } = question;
  const { url } = useRouteMatch();
  const [deleteOpen, setDeleteOpen] = useState(false);

  return (
    <>
      <Draggable draggableId={id.toString()} index={index}>
        {(provided, snapshot) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={
                snapshot.isDragging
                  ? { background: 'white', ...provided.draggableProps.style }
                  : provided.draggableProps.style
              }
            >
              <ResourceItem
                id={id}
                url={`${url}/question/${id}`}
                shortcutActions={[
                  {
                    icon: DeleteMinor,
                    content: 'Delete',
                    destructive: true,
                    onAction: () => setDeleteOpen(true),
                  },
                ]}
              >
                <Stack alignment="center" wrap={false}>
                  <div {...provided.dragHandleProps}>
                    <Tooltip content="Drag and drop to change the default question order">
                      <Icon source={DragHandleMinor} color="subdued" />
                    </Tooltip>
                  </div>
                  <TextContainer spacing="tight">
                    <TextStyle variation="strong">{questionText}</TextStyle>
                    <Stack spacing="tight">
                      <Badge>
                        {multiple_choice ? 'Multiple choice' : 'Single choice'}
                      </Badge>
                      <Badge>{optional ? 'Optional' : 'Non-optional'}</Badge>
                      <Badge>{filter_products ? 'Filtering' : 'Ranking'}</Badge>
                      {/* <Badge
                        status={
                          answers.length < 2
                            ? answers.length < 1
                              ? 'critical'
                              : 'warning'
                            : 'default'
                        }
                      >
                        {answers.length}{' '}
                        {answers.length === 1 ? 'answer' : 'answers'}
                      </Badge> */}
                    </Stack>
                  </TextContainer>
                </Stack>
              </ResourceItem>
            </div>
          );
        }}
      </Draggable>
      <QuestionDeleteModal
        question={question}
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        campaign={campaign}
      />
    </>
  );
}

export default QuestionsItem;
