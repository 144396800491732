import React from 'react';
import { Layout, Card, FormLayout, TextContainer } from '@shopify/polaris';

import ControlledTextField from '../../common/input/ControlledTextField';
import { imageHelpText } from '../../common/helpText';

function AnswerSection() {
  return (
    <Layout.Section>
      <Card sectioned>
        <FormLayout>
          <ControlledTextField name="text.answer" label="Answer" />
          <ControlledTextField
            name="assets.image"
            label="Image"
            helpText={imageHelpText}
          />
          <ControlledTextField
            name="klaviyo_tag"
            label="Alias"
            maxLength={50}
            helpText={
              <TextContainer>
                <p>
                  The Alias will be used as an identifier in external
                  applications and aggregated statistics.
                </p>
              </TextContainer>
            }
          />
        </FormLayout>
      </Card>
    </Layout.Section>
  );
}

export default AnswerSection;
