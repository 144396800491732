import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Page } from '@shopify/polaris';

import { useQuery } from 'react-query';

import config from '../../../config';

import { AuthContext } from '../../../providers/AuthContext';

import { callback } from '../../../api/oauth';
import ReturnLayout from './ReturnLayout';

function ReturnPage() {
  const history = useHistory();

  const { setAuth } = useContext(AuthContext);

  const params = new URLSearchParams(history.location.search);
  const payload = {
    hmac: params.get('hmac'),
    shop: params.get('shop'),
    timestamp: params.get('timestamp'),
    code: params.get('code'),
    state: params.get('state'),
    token: JSON.parse(localStorage.getItem(config.env + '_auth')).token,
  };

  const { isLoading, data } = useQuery('callback', () => callback(payload));

  useEffect(() => {
    if (!isLoading) {
      setAuth(data);
      history.push('/app');
    }
  }, [history, data, isLoading, setAuth]);

  return (
    <Page>
      <ReturnLayout />
    </Page>
  );
}

export default ReturnPage;
