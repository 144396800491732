import React from 'react';
import {
  Page,
  Layout,
  DisplayText,
  Modal,
  TextContainer,
  List,
  TextStyle,
} from '@shopify/polaris';

function LoginPage() {
  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Modal open>
            <Modal.Section>
              <TextContainer>
                <DisplayText size="large">Please log in to meja.io</DisplayText>
                <List type="number">
                  <List.Item>
                    Go to your stores'{' '}
                    <TextStyle variation="strong">
                      Shopify admin panel
                    </TextStyle>
                  </List.Item>
                  <List.Item>
                    Select <TextStyle variation="strong">Apps</TextStyle> in the
                    navigation
                  </List.Item>
                  <List.Item>
                    Select the <TextStyle variation="strong">meja.io</TextStyle>{' '}
                    app
                  </List.Item>
                </List>
              </TextContainer>
            </Modal.Section>
          </Modal>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default LoginPage;
