import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';

import QuestionPage from './QuestionPage';
import AnswerPage from './AnswerPage';
import QuestionsPage from './QuestionsPage';

export default function QuestionnaireRouter() {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/question/:qid/answer/:aid`}>
        <AnswerPage />
      </Route>
      <Route path={`${url}/question/:id`}>
        <QuestionPage />
      </Route>
      <Route>
        <QuestionsPage />
      </Route>
    </Switch>
  );
}
