import React from 'react';
import { Layout, FooterHelp, Link } from '@shopify/polaris';

import QuestionsSection from './QuestionsSection';

function QuestionsLayout() {
  return (
    <Layout>
      <QuestionsSection />
      <Layout.Section>
        <FooterHelp>
          Need help setting up your questionnaire? Get in touch at{' '}
          <Link external url="mailto:hello@meja.io">
            hello@meja.io
          </Link>
        </FooterHelp>
      </Layout.Section>
    </Layout>
  );
}

export default QuestionsLayout;
