import React, { useContext } from 'react';
import Radium from 'radium';
import { useFormContext } from 'react-hook-form';

import StoreContext from '../../../../providers/StoreContext';

import styles from './EmailPreview.module.css';

function EmailPreview({ campaign }) {
  const { watch } = useFormContext();

  const image = watch('assets.email.image') || campaign.assets.email.image;
  const header = watch('text.email.header') || campaign.text.email.header;
  const subheader =
    watch('text.email.subheader') || campaign.text.email.subheader;

  const askName =
    watch('config.email.ask_name') || campaign.config.email.ask_name;
  // const requireName = watch('config.email.name_required') || campaign.config.email.name_required;
  const namePlaceholder =
    watch('text.email.name_placeholder') ||
    campaign.text.email.name_placeholder;
  // const nameError = watch('text.email.name_error') || campaign.text.email.name_error;

  // const requireEmail = watch('config.email.email_required') || campaign.config.email.email_required;
  const emailPlaceholder =
    watch('text.email.email_placeholder') ||
    campaign.text.email.email_placeholder;
  // const emailError = watch('text.email.email_error') || campaign.text.email.email_error;

  const button =
    watch('text.email.confirm_button_text') ||
    campaign.text.welcome.confirm_button_text;

  const continueWithoutEmail =
    watch('config.email.continue_without_email') ||
    campaign.config.email.continue_without_email;
  const continueWithoutEmailText =
    watch('text.email.continue_without_email_text') ||
    campaign.config.email.continue_without_email_text;

  const { isLoading, assetURL } = useContext(StoreContext);
  const src = isLoading ? '/placeholder_meja.jpg' : assetURL(image);

  return (
    <div className={styles.container}>
      <div className={styles.imagecontainer}>
        <img className={styles.image} src={src} alt="" />
      </div>
      <div className={styles.content}>
        <div
          className={`header ${styles.header}`}
          style={[
            {
              fontFamily: campaign.style.variables['--meja-header-font-family'],
              fontSize: campaign.style.variables['--meja-header-font-size'],
              color: campaign.style.variables['--meja-color-primary'],
              lineHeight: '1.2em',
            },
          ]}
        >
          {header}
        </div>
        <div
          className={`subheader ${styles.subheader}`}
          style={[
            {
              fontFamily:
                campaign.style.variables['--meja-subheader-font-family'],
              fontSize: campaign.style.variables['--meja-subheader-font-size'],
              color: campaign.style.variables['--meja-color-primary'],
            },
          ]}
        >
          {subheader}
        </div>
        {askName && (
          <input
            key="name"
            className={styles.input}
            type="text"
            placeholder={namePlaceholder}
            style={[
              {
                fontFamily:
                  campaign.style.variables['--meja-input-text-font-family'],
                fontSize:
                  campaign.style.variables['--meja-input-text-font-size'],
                padding: campaign.style.variables['--meja-input-text-padding'],
                borderWidth:
                  campaign.style.variables['--meja-input-text-border-width'],
                borderColor: campaign.style.variables['--meja-color-primary'],
                ':focus': {
                  borderWidth:
                    campaign.style.variables[
                      '--meja-input-text-focus-border-width'
                    ],
                  borderColor:
                    campaign.style.variables['-meja-color-primary-accent'],
                  padding:
                    campaign.style.variables['--meja-input-text-padding'] -
                    campaign.style.variables['--meja-input-text-border-width'] +
                    campaign.style.variables[
                      '--meja-input-text-focus-border-width'
                    ],
                },
              },
            ]}
          />
        )}
        <input
          key="email"
          className={styles.input}
          type="text"
          placeholder={emailPlaceholder}
          style={[
            {
              fontFamily:
                campaign.style.variables['--meja-input-text-font-family'],
              fontSize: campaign.style.variables['--meja-input-text-font-size'],
              padding: campaign.style.variables['--meja-input-text-padding'],
              borderWidth:
                campaign.style.variables['--meja-input-text-border-width'],
              borderColor: campaign.style.variables['--meja-color-primary'],
              ':focus': {
                borderWidth:
                  campaign.style.variables[
                    '--meja-input-text-focus-border-width'
                  ],
                borderColor:
                  campaign.style.variables['-meja-color-primary-accent'],
                padding:
                  campaign.style.variables['--meja-input-text-padding'] -
                  campaign.style.variables['--meja-input-text-border-width'] +
                  campaign.style.variables[
                    '--meja-input-text-focus-border-width'
                  ],
              },
            },
          ]}
        />

        <button
          type="button"
          className={`${styles.button}`}
          style={[
            {
              fontFamily: campaign.style.variables['--meja-button-font-family'],
              fontSize: campaign.style.variables['--meja-button-font-size'],
              padding: campaign.style.variables['--meja-button-padding'],
              fontWeight: campaign.style.variables['--meja-button-font-weight'],
              textTransform:
                campaign.style.variables['--meja-button-text-transform'],
              borderWidth:
                campaign.style.variables['--meja-button-border-width'],
              backgroundColor: campaign.style.variables['--meja-color-primary'],
              borderColor: campaign.style.variables['--meja-color-primary'],
              color: campaign.style.variables['--meja-color-secondary'],
              ':hover': {
                backgroundColor:
                  campaign.style.variables['--meja-color-secondary'],
                borderColor: campaign.style.variables['--meja-color-primary'],
                color: campaign.style.variables['--meja-color-primary'],
                cursor: 'pointer',
              },
            },
          ]}
        >
          {button}
        </button>
        {continueWithoutEmail && (
          <span
            key="continue"
            style={[
              {
                color: campaign.style.variables['--meja-color-primary-subdued'],
                ':hover': {
                  color:
                    campaign.style.variables['--meja-color-primary-accent'],
                  cursor: 'pointer',
                },
              },
            ]}
          >
            {continueWithoutEmailText}
          </span>
        )}
      </div>
    </div>
  );
}

export default Radium(EmailPreview);
