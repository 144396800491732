import React, { useContext } from 'react';
import { Layout, TextContainer, Card, FormLayout } from '@shopify/polaris';

import { useQuery } from 'react-query';

import { Select } from '../../../common/input';
import APIContext from '../../../../providers/APIContext';

function GeneralLayout({ store }) {
  const { fetchCampaigns } = useContext(APIContext);

  const { isLoading, data: campaigns } = useQuery(
    'fetchCampaignsForList',
    fetchCampaigns,
  );

  const campaignsSelection = isLoading
    ? []
    : campaigns.map((campaign) => ({
        label: campaign.name,
        value: campaign.id,
      }));

  const selectPlaceholder = isLoading
    ? 'Loading campaigns'
    : 'Select a default campaign';

  return (
    <Layout>
      <Layout.AnnotatedSection
        title="General"
        description={<TextContainer>General store settings.</TextContainer>}
      >
        <Card sectioned>
          <FormLayout>
            <Select
              name="default_questionnaire_id"
              label="Default campaign"
              placeholder={selectPlaceholder}
              disabled={isLoading}
              options={campaignsSelection}
              defaultValue={store.default_questionnaire_id}
              helpText={
                <TextContainer>
                  <p>
                    The selected default project will appear if you don’t
                    determine a specific project slug when sharing your meja
                    questionnarie. e.g. your-store.myshopify.com/apps/meja
                  </p>
                </TextContainer>
              }
            />
          </FormLayout>
        </Card>
      </Layout.AnnotatedSection>
    </Layout>
  );
}

export default GeneralLayout;
