import React from 'react';
import { Layout } from '@shopify/polaris';

import InputPreview from './InputPreview';
import InputStyling from './InputStyling';

function InputLayout() {
  return (
    <Layout>
      <Layout.AnnotatedSection
        title="Input"
        description={
          <>
            <div style={{ marginBottom: '16px' }}>
              You'll find Input fields on the e-mail page.
              <br />
              <br />
              Inputs use your primary, primary accent and error color.
            </div>
            <InputPreview />
          </>
        }
      >
        <InputStyling />
      </Layout.AnnotatedSection>
    </Layout>
  );
}

export default InputLayout;
