import { Toast } from '@shopify/polaris';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Prompt, useHistory } from 'react-router-dom';
import APIContext from '../providers/APIContext';
import CampaignContext from '../providers/CampaignContext';

export default function useCampaign() {
  const { campaignId: id, setCampaignId: changeCampaign } =
    useContext(CampaignContext);

  const { fetchCampaign, patchCampaign } = useContext(APIContext);

  const queryClient = useQueryClient();

  const history = useHistory();

  const [toastActive, setToastActive] = useState(true);

  const dismissToast = () => setToastActive(false);

  const activateToast = () => setToastActive(true);

  const {
    isLoading,
    error,
    data: campaign,
  } = useQuery(['fetchCampaign', id], () => fetchCampaign(id), {
    onSuccess: (campaign) => form.reset(campaign),
  });

  const form = useForm({ defaultValues: campaign });

  const mutation = useMutation(
    (campaignMutation) => patchCampaign(campaign.id, campaignMutation),
    {
      mutationKey: 'patchCampaign',
      onSuccess: (patchedCampaign) => {
        queryClient.setQueryData(['fetchCampaigns'], (oldCampaigns) => [
          ...oldCampaigns.filter((c) => c.id !== patchedCampaign.id),
          patchedCampaign,
        ]);
        queryClient.setQueryData(
          ['fetchCampaign', campaign.id],
          patchedCampaign,
        );
        form.reset(patchedCampaign);
        if (campaign.slug !== patchedCampaign.slug)
          history.push(`/app/projects/${patchedCampaign.slug}`);
        activateToast();
      },
    },
  );

  const patch = (campaign) => mutation.mutate(campaign);

  const isSaving = mutation.isLoading;

  const isDirty = form.formState.isDirty;

  const editMarkup = (
    <>
      {mutation.isSuccess && toastActive && (
        <Toast content="Project saved successfully" onDismiss={dismissToast} />
      )}
      <Prompt
        message="You have some unsaved changes. Are you sure you want to leave? Your changes will be lost."
        when={isDirty}
      />
    </>
  );

  const saveAction = {
    content: 'Save and Publish',
    loading: mutation.isLoading,
    disabled: !isDirty || mutation.isLoading,
    onAction: form.handleSubmit(patch),
  };

  return {
    campaign,
    isLoading,
    isSaving,
    error,
    patch,
    form,
    editMarkup,
    changeCampaign,
    saveAction,
  };
}
