import React, { useContext } from 'react';
import { Modal, Stack } from '@shopify/polaris';

import { useForm, FormProvider } from 'react-hook-form';
import { useQuery, useMutation } from 'react-query';

import { Select, DatePicker } from '../../common/input';

import { DateTime } from 'luxon';
import APIContext from '../../../providers/APIContext';

function ExportModal(props) {
  const form = useForm();

  const now = DateTime.now();
  const { year, month } = now;

  const defaultDateRage = {
    start: now.minus({ months: 1 }).toJSDate(),
    end: now.toJSDate(),
  };

  const { fetchCampaigns, exportRecommendations } = useContext(APIContext);

  const { isLoading: isLoadingCampaings, data: campaigns = [] } = useQuery(
    'fetchCampaigns',
    fetchCampaigns,
  );

  let campaignsOptions = [
    {
      label: 'All campaigns',
      value: -1,
    },
    ...campaigns.map((campaign) => ({
      label: campaign.name,
      value: campaign.id,
    })),
  ];

  // From: https://stackoverflow.com/questions/16376161/javascript-set-filename-to-be-downloaded/16377813#16377813
  // TODO: Surely there is a better way
  var download = function (content, fileName, mimeType) {
    var a = document.createElement('a');
    mimeType = mimeType || 'application/octet-stream';

    if (navigator.msSaveBlob) {
      // IE10
      navigator.msSaveBlob(
        new Blob([content], {
          type: mimeType,
        }),
        fileName,
      );
    } else if (URL && 'download' in a) {
      //html5 A[download]
      a.href = URL.createObjectURL(
        new Blob([content], {
          type: mimeType,
        }),
      );
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      window.location.href =
        'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
    props.onClose();
  };

  const postExport = useMutation((params) => exportRecommendations(params), {
    onSuccess: (data) => {
      download(data, 'meja_export.csv', 'text/csv;encoding:utf-8');
    },
  });

  const downloadExport = (exportParams) => {
    let params = {};
    params.questionnaire_id =
      Number.isInteger(exportParams.campaign) &&
      parseInt(exportParams.campaign) >= 0
        ? parseInt(exportParams.campaign)
        : null;
    params.date_to = DateTime.fromJSDate(exportParams.dateRange.end)
      .endOf('day')
      .toISO();
    params.date_from = DateTime.fromJSDate(exportParams.dateRange.start)
      .startOf('day')
      .toISO();
    postExport.mutate(params);
  };

  return (
    <Modal
      {...props}
      title="Export CSV"
      primaryAction={{
        content: 'Download',
        onAction: form.handleSubmit(downloadExport),
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: props.onClose,
        },
      ]}
    >
      <FormProvider {...form}>
        <Modal.Section>
          <Stack vertical>
            <Stack.Item>
              <Select
                name="campaign"
                label="Project"
                disabled={isLoadingCampaings}
                options={campaignsOptions}
                defaultValue={-1}
                placeholder={isLoadingCampaings ? 'Loading projects' : null}
              />
            </Stack.Item>
            <Stack.Item>
              <DatePicker
                name="dateRange"
                label="Date range"
                multiMonth
                allowRange
                year={year}
                month={(month - 2) % 12}
                defaultValue={defaultDateRage}
              />
            </Stack.Item>
          </Stack>
        </Modal.Section>
      </FormProvider>
    </Modal>
  );
}

export default ExportModal;
