import React from 'react';
import { FormLayout, Card } from '@shopify/polaris';

import ControlledTextField from '../../../common/input/ControlledTextField';
import { fontFamilyHelpText } from '../../../common/helpText';

function TextStyling() {
  return (
    <Card sectioned>
      <FormLayout>
        <FormLayout.Group condensed>
          <ControlledTextField
            name="style.variables.--meja-header-font-family"
            label="Header Font"
            helpText={fontFamilyHelpText}
          />
          <ControlledTextField
            name="style.variables.--meja-header-font-size"
            label="Header Font Size"
          />
          <ControlledTextField
            name="style.variables.--meja-header-font-size-mobile"
            label="Font Size (Mobile)"
          />
        </FormLayout.Group>
        <FormLayout.Group condensed>
          <ControlledTextField
            name="style.variables.--meja-subheader-font-family"
            label="Sub-Header Font"
            helpText={fontFamilyHelpText}
          />
          <ControlledTextField
            name="style.variables.--meja-subheader-font-size"
            label="Sub-Header Font Size"
          />
          <ControlledTextField
            name="style.variables.--meja-subheader-font-size-mobile"
            label="Font Size (Mobile)"
          />
        </FormLayout.Group>
        <FormLayout.Group condensed>
          <ControlledTextField
            name="style.variables.--meja-text-font-family"
            label="Text Font"
            helpText={fontFamilyHelpText}
          />
          <ControlledTextField
            name="style.variables.--meja-text-font-size"
            label="Text Font Size"
          />
        </FormLayout.Group>
      </FormLayout>
    </Card>
  );
}

export default TextStyling;
