import { get, post } from './common';

export async function fetchRecommendations(
  token,
  questionnaire = null,
  offset = null,
  limit = null,
) {
  const { data } = await get('api/v2/recommendations/', token, {
    questionnaire,
    offset,
    limit,
  });
  return data;
}

export async function exportRecommendations(params, token) {
  const { data } = await post(
    'api/v2/recommendations/export-csv',
    params,
    token,
  );
  return data;
}

export async function fetchRecommendationsCounts(token) {
  const { data } = await get('api/v2/recommendations/counts/', token);
  return data;
}
