import React from 'react';
import {
  ResourceItem,
  Avatar,
  TextStyle,
  Stack,
  Badge,
} from '@shopify/polaris';

import { DateTime } from 'luxon';

import md5 from 'md5';

function Recommendation({ recommendation, ...rest }) {
  const { created_at, email, email_sent, name, slug, campaign } =
    recommendation;

  const avatar = `https://www.gravatar.com/avatar/${md5(
    email.trim().toLowerCase(),
  )}?d=404`;

  const issued = DateTime.fromISO(created_at).toRelative();
  const campaignBadge = campaign ? (
    <Badge status="info">{campaign}</Badge>
  ) : (
    <></>
  );
  const emailSentBadge = email_sent ? (
    <Badge status="success">E-mail sent</Badge>
  ) : (
    <Badge status="warning">No e-mail sent</Badge>
  );
  const nameDisplay = name ? (
    <TextStyle variation="strong">{name}</TextStyle>
  ) : (
    <Badge status="warning">No name</Badge>
  );
  const emailDisplay = email ? (
    email
  ) : (
    <Badge status="warning">No e-mail</Badge>
  );

  return (
    <ResourceItem
      id={slug}
      media={<Avatar source={avatar} customer size="medium" name={name} />}
      {...rest}
    >
      <Stack>
        <Stack.Item fill>
          {nameDisplay}
          <Stack>
            <Stack.Item>{emailDisplay}</Stack.Item>
            <Stack.Item>{emailSentBadge}</Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          {issued}
          <br />
          {campaignBadge}
        </Stack.Item>
      </Stack>
    </ResourceItem>
  );
}

export default Recommendation;
