import axios from 'axios';

import config from '../config';

export async function get(endpoint, token, params = null) {
  console.log('GET from ', endpoint, 'with Bearer token', token);

  const result = await axios.get(config.backend + endpoint, {
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}` },
    params,
  });

  console.log('GET result is', result);
  return result;
}

export async function post(endpoint, data, token) {
  console.log('POSTing to', endpoint, 'with data', data, 'and token', token);

  const result = await axios.post(config.backend + endpoint, data, {
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}` },
  });

  console.log('POST result is', result);
  return result;
}

export async function postAuth(endpoint, data) {
  console.log('POSTing to', endpoint, 'with data', data);

  const result = await axios.post(config.backend + endpoint, data, {
    withCredentials: true,
  });

  console.log('POST result is', result);
  return result;
}

export async function put(endpoint, data, token = null) {
  const message = `PUTing to ${endpoint} with data ${data}${
    token ? 'and token ' + token : ''
  }`;
  console.log(message);

  const result = await axios.put(config.backend + endpoint, data, {
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}` },
  });

  console.log('PUT result is', result);
  return result;
}

export async function patch(endpoint, data, token) {
  console.log('PATCHing to', endpoint, 'with data', data, 'and token', token);

  const result = await axios.patch(config.backend + endpoint, data, {
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}` },
  });

  console.log('PATCH result is', result);
  return result;
}

export async function del(endpoint, token) {
  console.log('DELETEing to', endpoint, 'with token', token);

  const result = await axios.delete(config.backend + endpoint, {
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}` },
  });

  console.log('DELETE result is', result);
  return result;
}
