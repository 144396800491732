import React from 'react';
import { Layout, TextContainer } from '@shopify/polaris';
import QuestionnaireEditing from './QuestionnaireEditing';

export default function QuestionnaireLayout({ campaign }) {
  return (
    <Layout>
      <Layout.AnnotatedSection
        title="Questionnaire Pages"
        description={
          <>
            <TextContainer>
              <p>
                This is where you can change the wording of the questionnaire
                navigation.
              </p>
            </TextContainer>
          </>
        }
      >
        <QuestionnaireEditing campaign={campaign} />
      </Layout.AnnotatedSection>
    </Layout>
  );
}
