import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Navigation } from '@shopify/polaris';
import {
  HomeMajor,
  ColumnWithTextMajor,
  CustomersMajor,
  SettingsMajor,
  QuestionMarkInverseMajor,
} from '@shopify/polaris-icons';

import CampaignContext from '../../providers/CampaignContext';
import CampaignNavigation from './CampaignNavigation';

function AppNavigation() {
  const location = useLocation();

  const { campaignId } = useContext(CampaignContext);

  const campaignSection = campaignId ? <CampaignNavigation /> : <></>;

  const home = {
    url: '/app',
    matchPaths: ['/app/home'],
    exactMatch: true,
    label: 'Home',
    icon: HomeMajor,
  };

  const campaigns = {
    url: '/app/projects',
    label: 'Projects',
    icon: ColumnWithTextMajor,
    exactMatch: true,
  };

  const customers = {
    url: '/app/customers',
    label: 'Customers',
    icon: CustomersMajor,
  };

  const settings = {
    url: '/app/settings',
    label: 'Settings',
    icon: SettingsMajor,
  };

  const faq = {
    url: '/app/faq',
    label: 'FAQ',
    icon: QuestionMarkInverseMajor,
  };

  const primarySection = campaignId ? (
    <Navigation.Section items={[home, campaigns]} />
  ) : (
    <Navigation.Section items={[home, campaigns, customers]} />
  );
  const secondarySection = campaignId ? (
    <Navigation.Section items={[customers]} />
  ) : (
    <></>
  );
  const tertiarySection = <Navigation.Section items={[settings, faq]} />;

  return (
    <Navigation location={location.pathname}>
      {primarySection}
      {campaignSection}
      {secondarySection}
      {tertiarySection}
    </Navigation>
  );
}

export default AppNavigation;
