import React from 'react';
import { Layout, Stack, DisplayText, Spinner, Modal } from '@shopify/polaris';

function AccessLayout() {
  return (
    <Layout>
      <Layout.Section>
        <Modal open>
          <Modal.Section>
            <Stack vertical alignment="center" style={{ marginTop: '10vh' }}>
              <Spinner
                accessibilityLabel="Logging you in to meja"
                size="large"
                color="teal"
              />
              <DisplayText size="large">logging in to meja.io</DisplayText>
            </Stack>
          </Modal.Section>
        </Modal>
      </Layout.Section>
    </Layout>
  );
}

export default AccessLayout;
