import { Toast } from '@shopify/polaris';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Prompt } from 'react-router-dom';
import APIContext from '../providers/APIContext';
import CampaignContext from '../providers/CampaignContext';

export default function useQuestion(id) {
  const { fetchQuestion, patchQuestion } = useContext(APIContext);

  const { campaignId } = useContext(CampaignContext);

  const queryClient = useQueryClient();

  const { data: question, isLoading } = useQuery(
    ['fetchQuestion', id],
    () => fetchQuestion(id),
    {
      onSuccess: (question) => form.reset(question),
    },
  );

  const {
    mutate: save,
    isLoading: isSaving,
    isSuccess: isSaved,
  } = useMutation((question) => patchQuestion(id, question), {
    mutationKey: ['patchQuestion', id],
    onSuccess: (newQuestion) => {
      queryClient.setQueryData(['fetchQuestion', id], newQuestion);
      queryClient.setQueryData(['fetchQuestions', campaignId], (questions) =>
        questions.map((question) =>
          question.id === id ? newQuestion : question,
        ),
      );
      form.reset(newQuestion);
      activateToast();
    },
  });

  const form = useForm({ defaultValues: question });

  const {
    formState: { isDirty },
  } = form;

  const [toastActive, setToastActive] = useState(true);

  const dismissToast = () => setToastActive(false);

  const activateToast = () => setToastActive(true);

  const editMarkup = (
    <>
      {isSaved && toastActive && (
        <Toast content="Question saved successfully" onDismiss={dismissToast} />
      )}
      <Prompt
        message="You have some unsaved changes. Are you sure you want to leave? Your changes will be lost."
        when={isDirty}
      />
    </>
  );

  const saveAction = {
    content: 'Save and Publish',
    loading: isLoading,
    disabled: !isDirty || isLoading,
    onAction: form.handleSubmit(save),
  };

  return {
    question,
    isLoading,
    save,
    isSaving,
    isSaved,
    form,
    saveAction,
    editMarkup,
  };
}
