import React, { createContext, useState, useCallback } from 'react';

import { DateTime } from 'luxon';

import config from '../config';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const local_auth_str = localStorage.getItem(config.env + '_auth');
  const local_auth = JSON.parse(local_auth_str);
  const expiresSoon =
    DateTime.fromISO(local_auth?.token_expiration).minus({ hours: 1 }) <
    DateTime.now();
  const [auth, setAuthState] = useState(
    local_auth && !expiresSoon ? local_auth : null,
  );

  const setAuth = useCallback((auth) => {
    setAuthState(auth);
    if (auth) localStorage.setItem(config.env + '_auth', JSON.stringify(auth));
    else localStorage.removeItem(config.env + '_auth');
  }, []);

  const logout = useCallback(() => {
    setAuth(null);
  }, [setAuth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
