import React, { useState, useCallback, useContext } from 'react';
import { TopBar } from '@shopify/polaris';
import { ArrowLeftMinor, LogOutMinor } from '@shopify/polaris-icons';
import AuthContext from '../../providers/AuthContext';
import AdminTopBar from '../admin/AdminTopBar';

function AppTopBar(props) {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    [],
  );

  const { auth, logout } = useContext(AuthContext);
  const { token_is_admin: isAdmin } = auth || {};

  const returnToShopify = useCallback(
    () =>
      (window.location.href = `https://${auth?.store.myshopify_domain}/admin/apps`),
    [auth],
  );

  const userMenu =
    auth && !isAdmin ? (
      <TopBar.UserMenu
        actions={[
          {
            items: [
              {
                content: 'Back to Shopify',
                icon: ArrowLeftMinor,
                onAction: returnToShopify,
              },
            ],
          },
          {
            items: [
              {
                content: 'Logout',
                icon: LogOutMinor,
                onAction: logout,
              },
            ],
          },
        ]}
        name={auth.store.name}
        detail={auth.store.shop_owner}
        open={isUserMenuOpen}
        onToggle={toggleIsUserMenuOpen}
      />
    ) : (
      <></>
    );

  return isAdmin ? (
    <AdminTopBar />
  ) : (
    <TopBar
      showNavigationToggle
      userMenu={userMenu}
      onNavigationToggle={props.onNavigationToggle}
    />
  );
}

export default AppTopBar;
