import React from 'react';
import { TextContainer, TextStyle } from '@shopify/polaris';

function EmailAnnotation() {
  return (
    <TextContainer>
      <p>Following variables can be used within your custom e-mail template:</p>
      <p>
        Customers name:
        <br />
        <TextStyle variation="code"> {'{{ recommendation.name }}'} </TextStyle>
        <br />
        Your stores URL:
        <br />
        <TextStyle variation="code">
          {' '}
          {'{{ store.shopify_store.domain }}'}{' '}
        </TextStyle>
        <br />
      </p>
      <p>
        Product handle:
        <br />
        <TextStyle variation="code">
          {'{{ recommendation_data.results.0.handle }}'}
        </TextStyle>
        <br />
        Product image source:
        <br />
        <TextStyle variation="code">
          {'{{ recommendation_data.results.0.image.src }}'}
        </TextStyle>
        <br />
        Product image alt text:
        <br />
        <TextStyle variation="code">
          {'{{ recommendation_data.results.0.image.alt }}'}
        </TextStyle>
        <br />
        Product name:
        <br />
        <TextStyle variation="code">
          {'{{ recommendation_data.results.0.title }}'}
        </TextStyle>
        <br />
        Product price:
        <br />
        <TextStyle variation="code">
          {'{{ recommendation_data.results.0.price }}'}
        </TextStyle>
        <br />
        <br />
        Icrease the index (0 in example) to access the other recommendations.
      </p>
      <p>
        A link to a product in your store could be composed in the following
        way:
        <br />
        <TextStyle variation="code">
          {
            'https://{{ shop }}/products/{{ recommendation_data.results.0.handle }}'
          }
        </TextStyle>
      </p>
      <p>
        A link back to the campaign could be composed in the following way:
        <br />
        <TextStyle variation="code">
          {
            'https://{{ store.shopify_store.domain }}{{ path_prefix }}/{{ slug }}'
          }
        </TextStyle>
      </p>
      <p>
        You can express conditionals, for example to check if a result is
        existing in the following way:
        <br />
        <TextStyle variation="code">
          {
            '{% if recommendation_data.results.0 %} <!-- Appears if true --> {% endif %}'
          }
        </TextStyle>
      </p>
    </TextContainer>
  );
}

export default EmailAnnotation;
