import React, { useContext } from 'react';
import {
  Form,
  FormLayout,
  Modal,
  TextContainer,
  TextStyle,
  List,
} from '@shopify/polaris';
import { DeleteMinor } from '@shopify/polaris-icons';

import { useMutation, useQueryClient } from 'react-query';
import { FormProvider, useForm } from 'react-hook-form';
import ControlledTextField from '../../common/input/ControlledTextField';
import APIContext from '../../../providers/APIContext';

function CampaignDeleteModal({ campaign, ...rest }) {
  const form = useForm();

  const { deleteCampaign } = useContext(APIContext);

  const queryClient = useQueryClient();

  const deletion = useMutation(() => deleteCampaign(campaign.id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['fetchCampaigns']);
      rest.onClose();
    },
  });

  return (
    <Modal
      {...rest}
      title={`Delete project "${campaign.name}" permanently`}
      primaryAction={{
        icon: DeleteMinor,
        content: 'Delete',
        destructive: true,
        onAction: () => deletion.mutate(),
        url: 'projects',
        disabled: !(form.watch('slug') === campaign.slug),
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: rest.onClose,
        },
      ]}
    >
      <FormProvider {...form}>
        <Modal.Section>
          <TextContainer>
            <p>
              Please be aware that by deleting a project you will also delete
              all assosciated elements, such as:
            </p>
            <List type="bullet">
              <List.Item>
                Recommendations (they will also become unaccesible to you
                customers)
              </List.Item>
              <List.Item>Questions & Answers</List.Item>
              <List.Item>
                All text, assets, branding and configurations used in your
                campaign
              </List.Item>
            </List>
            <p>
              <TextStyle variation="negative">
                The deletion is permanent and irreversible.
              </TextStyle>
            </p>
          </TextContainer>
        </Modal.Section>
        <Modal.Section>
          <TextContainer>
            Please confirm the deletion by typing the project slug{' '}
            <TextStyle variation="code">{campaign.slug}</TextStyle> into the
            text field below.
          </TextContainer>
          <Form>
            <FormLayout>
              <ControlledTextField name="slug" label="Project slug" />
            </FormLayout>
          </Form>
        </Modal.Section>
      </FormProvider>
    </Modal>
  );
}

export default CampaignDeleteModal;
