import React from 'react';
import { FormLayout, Card, TextContainer } from '@shopify/polaris';

import ControlledTextField from '../../../common/input/ControlledTextField';
import { imageHelpText } from '../../../common/helpText';

function LoadingEditing({ campaign }) {
  return (
    <Card sectioned>
      <FormLayout>
        <ControlledTextField
          name="text.calculation.header"
          label="Header"
          defaultValue={campaign.text.calculation.header}
        />
        <ControlledTextField
          name="text.calculation.subheader"
          label="Subheader"
          defaultValue={campaign.text.calculation.subheader}
        />
        <ControlledTextField
          name="assets.calculation.spinner"
          label="Spinner"
          defaultValue={campaign.assets.calculation.spinner}
          helpText={
            <>
              <TextContainer>
                Ideally in a 1:1 ratio. 200 x 200 pixels is sufficient for most
                cases.
              </TextContainer>
              {imageHelpText}
            </>
          }
        />
        <ControlledTextField
          name="config.calculation.min_waiting_time_ms"
          label="Minimal waiting time"
          defaultValue={campaign.config.calculation.min_waiting_time_ms}
          type="number"
          suffix="ms"
        />
      </FormLayout>
    </Card>
  );
}

export default LoadingEditing;
