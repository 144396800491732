import React, { useContext, useState } from 'react';
import { Route } from 'react-router';

import { AuthContext } from '../providers/AuthContext';

import { useForm, FormProvider } from 'react-hook-form';

import { useMutation } from 'react-query';
import { login } from '../api/auth';

import {
  Form,
  FormLayout,
  Modal,
  Button,
  InlineError,
  Stack,
} from '@shopify/polaris';
import { TextField } from './common/input';

function AdminRoute({ children, component, ...rest }) {
  const [invalidLogin, setInvalidLogin] = useState(false);
  const { auth, setAuth } = useContext(AuthContext);
  const { token_is_admin: isAdmin } = auth || {};

  const mutation = useMutation(
    ({ username, password }) => login(username, password),
    {
      onSuccess: (res) => setAuth(res.data),
      onError: () => setInvalidLogin(true),
    },
  );

  const form = useForm();
  const onSubmit = (data) => mutation.mutate(data);

  if (isAdmin) return <Route {...rest}>{children}</Route>;
  return (
    <Modal open title="Admin login">
      <Modal.Section>
        <FormProvider {...form}>
          <Form onSubmit={form.handleSubmit(onSubmit)}>
            <FormLayout>
              <TextField name="username" label="Username" />
              <TextField name="password" label="Password" type="password" />
              <Stack>
                <Stack.Item fill>
                  {invalidLogin && (
                    <InlineError
                      message="Invalid credentials"
                      fieldID="password"
                    />
                  )}
                </Stack.Item>
                <Stack.Item>
                  <Button primary submit loading={mutation.isLoading}>
                    Login
                  </Button>
                </Stack.Item>
              </Stack>
            </FormLayout>
          </Form>
        </FormProvider>
      </Modal.Section>
    </Modal>
  );
}

export default AdminRoute;
