import React, { useState, useCallback } from 'react';
import { Button, Layout, TextContainer, Modal } from '@shopify/polaris';
import ResultsPreview from './ResultsPreview';
import ResultsEditing from './ResultsEditing';

function ResultsLayout({ campaign }) {
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const activator = (
    <Button fullWidth onClick={toggleActive}>
      Preview Results Page
    </Button>
  );

  return (
    <Layout>
      <Layout.AnnotatedSection
        title="Results Page"
        description={
          <>
            <TextContainer>
              <p>
                The results page is where your customer is recommend her or his
                products based on the answers to the questionnaire.
              </p>
              <Modal
                large
                activator={activator}
                open={active}
                onClose={toggleActive}
                title="Results Page preview"
              >
                <Modal.Section>
                  <ResultsPreview campaign={campaign} />
                </Modal.Section>
              </Modal>
            </TextContainer>
          </>
        }
      >
        <ResultsEditing campaign={campaign} />
      </Layout.AnnotatedSection>
    </Layout>
  );
}

export default ResultsLayout;
