import React, { useCallback } from 'react';
import { Loading, Page, Tabs } from '@shopify/polaris';

import { FormProvider } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';

import KlaviyoLayout from './klaviyo/KlaviyoLayout';
import useCampaign from '../../../hooks/useCampaign';

function IntegrationsPage() {
  const history = useHistory();
  const { campaign, isLoading, form, editMarkup, saveAction } = useCampaign();

  const tabs = [
    {
      id: 'klaviyo',
      content: 'Klaviyo',
      layout: <KlaviyoLayout campaign={campaign} />,
    },
  ];

  const handleTabChange = useCallback(
    (id) =>
      history.push(
        `/app/projects/${campaign?.slug}/integrations/${tabs[id].id}`,
      ),
    [campaign, history, tabs],
  );
  const { tab = 'klaviyo' } = useParams();
  const tabID = tabs.findIndex((t) => t.id === tab);

  if (isLoading) return <Loading />;

  return (
    <Page title="Integrations" primaryAction={saveAction}>
      <FormProvider {...form}>
        <Tabs tabs={tabs} selected={tabID} onSelect={handleTabChange}>
          {tabs[tabID].layout}
        </Tabs>
      </FormProvider>
      {editMarkup}
    </Page>
  );
}

export default IntegrationsPage;
