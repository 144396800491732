import React from 'react';
import { Layout, Card } from '@shopify/polaris';

import QuestionsList from './QuestionsList';

function QuestionsSection() {
  return (
    <Layout.Section>
      <Card>
        <QuestionsList />
      </Card>
    </Layout.Section>
  );
}

export default QuestionsSection;
