import { get } from './common';

export async function fetchProducts(token) {
  const { data } = await get('api/v2/shopify/products/', token);
  return data;
}

export async function fetchCollections(token) {
  const { data } = await get('api/v2/shopify/collections/', token);
  return data;
}

export async function fetchTags(token) {
  const { data } = await get('api/v2/shopify/tags/', token);
  return data;
}
